import * as React from 'react';
import '../customScroll.css';
import { IDetailsList, DetailsList, IDetailsListProps, ColumnActionsMode, IDetailsRowStyles, DetailsRow, IDetailsListStyles, DetailsListLayoutMode, Selection, SelectionMode, IColumn } from '@fluentui/react/lib/DetailsList';
import { IListProps, IList, Spinner, IconButton, SpinnerSize, Stack, PrimaryButton, TextField, ShimmeredDetailsList, IDetailsListCheckboxProps, Checkbox, concatStyleSetsWithProps, themeRulesStandardCreator, TagItemSuggestion } from '@fluentui/react';
import { Dropdown, DropdownMenuItemType, IDropdownOption, IDropdownStyles } from '@fluentui/react/lib/Dropdown';
import { CommandBarButton, DatePicker, DefaultButton, Dialog, DialogFooter, DialogType, FontIcon, Icon, IObjectWithKey, IStackTokens, List, MarqueeSelection } from 'office-ui-fabric-react';
import {
  IContextualMenuProps,
  IContextualMenuItem,
  DirectionalHint,
  ContextualMenu,
} from '@fluentui/react/lib/ContextualMenu';
import axios from 'axios';
import DocumentHistoryModal from './/DocumentHistoryModal';
import { IconTooltip } from '../../utilities/helpers';
import DocumentHistoryResubmitModal, { resubmitDocumentDetails } from './DocumentHistoryResubmitModal';
import DocumnetHistoryMetadataModal from './DocumentHistoryMetadataModal';
import DocumentHistoryInfoModal from './DocumentHistoryInfoModal';
import { DocumentQuery, queryCondition, ascendingDescending, QueryFilter, SortFilter } from '../../interfaces/documentQuery';
import * as DocumentHistoryState from '../../store/DocumentHistory'
import { useSelector, useDispatch, connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { RouteComponentProps } from 'react-router-dom';
import * as DocumentHistoryStore from '../../store/DocumentHistory';
import _, { filter, lte } from "lodash";
import { toast } from 'react-toastify';
import { setDocumentToResolved } from '../../utilities/axiosUtility';
import { StoreStatus } from '../../interfaces/storeStatistics';


export interface IDocumentStoreItem {
  key: string;
  name: string;
  status: string
  submittedDate: string;
  storeId: string;
  storeIdn: string;
}

export interface IDocumentItem {
  id: number;
  name: string;
  storeId: number;
  status: StoreStatus;
  submittedDate: string;
}

interface scrollData {
  scrollHeight: number,
  scrollTop: number,
  clientHeight: number
}


interface columnActive {
  name: queryColumns,
  status: queryColumns,
  startDate: queryColumns,
  endDate: queryColumns,
  storeName: queryColumns,
}

interface queryColumns {
  isActive: boolean,
  queryCondition: queryCondition
}

const detailListStyle: IDetailsListStyles = {
  root: {
    width: '94vw'
  },
  contentWrapper: {
    width: '94vw'

  },
  focusZone: {},
  headerWrapper: {
    width: '94vw'
  }
}

const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 150 } };

interface resolveDetails {
  customerId: number;
  documentId: number;
}

interface DocumentHistoryState {
  documentItems: IDocumentItem[];
  columns: IColumn[];
  contextualMenuProps: IContextualMenuProps | undefined;
  columnsSearch: columnActive;
  selectedStore: IDropdownOption | undefined;
  docStoreDropdownList: IDropdownOption[];
  selectedStatus: IDropdownOption | undefined;
  nameFieldValue: string;
  nameFieldCondition: queryCondition;
  documentSubmitModalOpen: boolean;
  documentSubmitDetails: resubmitDocumentDetails[];
  documentHistoryModalOpen: boolean;
  documentHistoryMetadataModalOpen: boolean;
  documentHistoryInfoModalOpen: boolean;
  selectedDocumentId: number;
  selectedStartDate: Date | undefined;
  selectedEndDate: Date | undefined;
  documentsSelected: boolean;
  downloadDialogHidden: boolean;
  resolveConfirmationDialogHidden: boolean;
  resolveDetails: resolveDetails;
  selectedDocumentDetails: SelectedDocumentDetails;
}

export interface SelectedDocumentDetails {
  storeId: number,
  documentId: number,
  documentName: string,
}


const statusOptions: IDropdownOption[] = [
  { key: '0', text: 'Queued' },
  { key: '1', text: 'Content Missing' },
  { key: '2', text: 'In Progress' },
  { key: '3', text: 'Processed' },
  { key: '4', text: 'Deleted' },
  { key: '5', text: 'Processing Error' },
  { key: '6', text: 'Moved' },
  { key: '7', text: 'Timed Out' },
  { key: '8', text: 'Missing Required Fields' },
  { key: '9', text: 'Not Moved' },
  { key: '10', text: 'Awaiting Response' },
  { key: '11', text: 'Resolved' }
];

const defaultColumnsActive: columnActive = {
  name: { isActive: false, queryCondition: queryCondition.none },
  status: { isActive: false, queryCondition: queryCondition.none },
  startDate: { isActive: false, queryCondition: queryCondition.none },
  endDate: { isActive: false, queryCondition: queryCondition.none },
  storeName: { isActive: false, queryCondition: queryCondition.none }
}

type DocumentHistoryProps =
  ApplicationState & typeof DocumentHistoryStore.actionCreatorsHistory &
  RouteComponentProps<{}>;


class DocumentHistoryTable extends React.Component<DocumentHistoryProps, DocumentHistoryState> {

  private _selection: Selection;
  private _defaultColumns: IColumn[];
  private refreshTimer: any;
  //Default column set-up
  constructor(props: DocumentHistoryProps) {
    super(props)

    const defaultColumns: IColumn[] = [
      {
        key: 'column1',
        name: 'Name',
        fieldName: 'name',
        minWidth: 400,
        maxWidth: 595,
        isRowHeader: true,
        isResizable: true,
        isSorted: false,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: (ev, column) => this.onColumnClick(ev, column),
        onRenderHeader: () => this.state.columnsSearch.name.isActive ? this.renderSearchTextField('name') : <div>Name</div>,
        data: 'string',
        isPadded: true,
      },
      {
        key: 'column2',
        name: 'Status',
        fieldName: 'status',
        minWidth: 200,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        isSorted: false,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onRender(item, index, column) {return (StoreStatus[item.status])},
        onColumnClick: (ev, column) => this.onColumnClick(ev, column),
        onRenderHeader: () => this.state.columnsSearch.status.isActive ? this.renderDropdowns(false, 'status') : <div>Status</div>,
        data: 'number',
        isPadded: true,
      },
      {
        key: 'column3',
        name: 'Created Date',
        fieldName: 'submittedDate',
        minWidth: 250,
        maxWidth: 250,
        isRowHeader: true,
        isResizable: true,
        isSorted: true,
        isSortedDescending: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: (ev, column) => this.onColumnClick(ev, column),
        onRenderHeader: () => this.state.columnsSearch.startDate.isActive ? this.renderDatePickers('submittedDate') : <div>Created Date</div>,
        data: 'string',
        isPadded: true,
      },
      {
        key: 'column4',
        name: 'Store Name',
        fieldName: 'storeId',
        minWidth: 350,
        maxWidth: 350,
        isRowHeader: true,
        isResizable: true,
        isSorted: false,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: (ev, column) => this.onColumnClick(ev, column),
        onRender: (item: IDocumentItem) => { return <div>{this.getStoreName(item.storeId)}</div> },
        onRenderHeader: () => this.state.columnsSearch.storeName.isActive ? this.renderDropdowns(true, 'storeId') : <div>Store Name</div>,
        data: 'string',
        isPadded: true,
      },
      {
        key: 'column5',
        name: 'Action',
        fieldName: 'action',
        minWidth: 150,
        maxWidth: 150,
        isRowHeader: true,
        isResizable: true,
        onRender: (item) => this.renderActionButtons(item),
      }
    ]
    this._defaultColumns = defaultColumns;
    this._selection = new Selection({
      onSelectionChanged: () => {
        const currentlySelected = this._selection.getSelection() as IDocumentItem[];
        let nResubmitDocumentDetails: resubmitDocumentDetails[] = [];

        for (let selectedDoc of currentlySelected) {
          if (this.props.documentHistory?.documentHistoryItems) {
            if (selectedDoc.status === 7 || selectedDoc.status === 5) {
              const nDocumentDetails: resubmitDocumentDetails = {
                documentId: selectedDoc.id,
                storeId: selectedDoc.storeId,
                documentName: selectedDoc.name
              };

              nResubmitDocumentDetails.push(nDocumentDetails);
            }
          }
        }

        if (nResubmitDocumentDetails.length > 0) {
          this.setState({
            documentsSelected: true,
            documentSubmitDetails: nResubmitDocumentDetails
          });
        }
        else {
          this.setState({
            documentsSelected: false,
            documentSubmitDetails: []
          });
        }
      },
    });

    this.state = {
      documentItems: [],
      columns: defaultColumns,
      contextualMenuProps: undefined,
      columnsSearch: defaultColumnsActive,
      selectedStore: undefined,
      docStoreDropdownList: [],
      selectedStatus: undefined,
      nameFieldValue: "",
      nameFieldCondition: queryCondition.none,
      documentSubmitModalOpen: false,
      documentSubmitDetails: [],
      documentHistoryModalOpen: false,
      selectedDocumentId: 0,
      documentHistoryMetadataModalOpen: false,
      documentHistoryInfoModalOpen: false,
      selectedStartDate: undefined,
      selectedEndDate: undefined,
      documentsSelected: false,
      downloadDialogHidden: true,
      resolveConfirmationDialogHidden: true,
      resolveDetails: {
        customerId: 0,
        documentId: 0
      },
      selectedDocumentDetails: {
        storeId: 0,
        documentId: 0,
        documentName: ""
      }
    }
  }

  public componentDidMount() {
    this.resetColumnAndFilters();
    this.refreshTimer = setInterval(() => {
      this._refreshTable();
    }, 60000)
  }

  public componentWillUnmount() {
    clearInterval(this.refreshTimer)
  }

  public componentDidUpdate(prevProps: DocumentHistoryProps) {
    if (this.props.auth?.SelectedCustomer?.id !== prevProps.auth?.SelectedCustomer?.id) {
      this.resetColumnAndFilters();
    }
    if (this.props.documentHistory) {
      if (this.props.documentHistory.isRedirect == true && this.props.documentHistory.documentsQuery !== prevProps.documentHistory?.documentsQuery) {
        this.mapColumnsToQuery(this.props.documentHistory.documentsQuery, this.state.columns)
        this.props.setIsRedirect(false);
      }
    }
  }


  private resetColumnAndFilters() {
    const newdefaultColumnsActive: columnActive = {
      name: { isActive: false, queryCondition: queryCondition.none },
      status: { isActive: false, queryCondition: queryCondition.none },
      startDate: { isActive: false, queryCondition: queryCondition.none },
      endDate: { isActive: false, queryCondition: queryCondition.none },
      storeName: { isActive: false, queryCondition: queryCondition.none }
    }
    this.setState({ columns: this._defaultColumns, columnsSearch: newdefaultColumnsActive, selectedStore: undefined, selectedEndDate: undefined, selectedStatus: undefined, selectedStartDate: undefined, nameFieldValue: "" })
  }

  //filters documentStores to match documentItem to return store name.
  private getStoreName = (id: number) => {
    if (this.props.documentStore?.documentStores) {
      const filteredStoreName = this.props.documentStore.documentStores.filter(documentStore => documentStore.id == id);
      if (filteredStoreName.length > 0) { return filteredStoreName[0].name }
      else { return "" }
    }
    else { return "" }
  }

  private resolveDocument = async (customerId: number, documentId: number) => {
    var resolvedReponse = await setDocumentToResolved(customerId, documentId);
    if (resolvedReponse.status === 200) {
      toast.success('Document Resolved', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      this._refreshTable()
      this.toggleResolvedDialog()
    }
    else {
      toast.error('Error Resolving Document', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      this.toggleResolvedDialog()
    }
  }

  private isExternalStore = (storeId: number) => {
    let isExternal = false
    if(this.props.documentStore)
    for(let store of this.props.documentStore.documentStores)
    {
      if(store.id === storeId && store.typeId === "00000000-0000-0000-0000-00000000000a" )
      {
        isExternal = true;
      }
    }

    return isExternal;
  }

  //Renders rows action buttons
  private renderActionButtons = (item: IDocumentItem) => {
    if (item.status === 5|| item.status === 7) {
      return (
        <Stack styles={{ root: { cursor: 'pointer' } }} data-selection-disabled={true} horizontal horizontalAlign="space-between">
          <IconTooltip size={20} onClick={() => this.setSelectedDocument(item.id)} iconName="EntryView" tooltipContent="View History" />
          {this.isExternalStore(item.storeId) && (<IconTooltip size={20} onClick={() => this.setDownloadedDocumentDetails(item.storeId, item.id, item.name)} iconName="DownloadDocument" tooltipContent="Download Document" />)}
          <IconTooltip size={20} onClick={() => this.resubmitDocuments(item)} iconName="Subscribe" tooltipContent="Re-submit Document" />
          <IconTooltip size={20} onClick={() => this.setMetaDataDocumentDetails(item.storeId, item.id, item.name)} iconName="ProcessMetaTask" tooltipContent="Edit Metadata" />
          <IconTooltip size={20} onClick={() => this.setResolveDialog(this.props.auth?.SelectedCustomer ? this.props.auth.SelectedCustomer.id : 0, item.id)} iconName="Completed" tooltipContent="Resolve document" />
        </Stack>
      )
    }
    else {
      return (
        <Stack styles={{ root: { cursor: 'pointer' } }} data-selection-disabled={true} horizontal horizontalAlign="space-between">
          <IconTooltip size={20} onClick={() => this.setSelectedDocument(item.id)} iconName="EntryView" tooltipContent="View History" />

        </Stack>
      )
    }
  }

  private downloadDocument = async () => {
    const customerId = this.props.auth?.SelectedCustomer ? this.props.auth.SelectedCustomer.id : 0
    const { selectedDocumentDetails } = this.state;
    this.setState({ downloadDialogHidden: true })
    try {
      toast.info(`Retrieving ${selectedDocumentDetails.documentName}`,
        {
          toastId: selectedDocumentDetails.documentId,
          position: "top-right",
          hideProgressBar: true,
          closeOnClick: false,
        })
      const download = await axios.get(`_api/portal/customer/${customerId}/stores/${selectedDocumentDetails.storeId}/documents/${selectedDocumentDetails.documentId}/download`, { responseType: 'blob' })
      const url = window.URL.createObjectURL(download.data);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.setAttribute("style", "display: none");
      a.href = url;
      a.download = selectedDocumentDetails.documentName; // assign the file name
      a.click(); // click the element to download the document
      window.URL.revokeObjectURL(url);
      a.remove(); // remove the element
      toast.dismiss(selectedDocumentDetails.documentId);
    }
    catch
    {
      toast.error('Error downloading document', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  private setSelectedDocument(documentId: number) {
    this.setState({ selectedDocumentId: documentId, documentHistoryModalOpen: !this.state.documentHistoryModalOpen })
  }

  private setSelectedDocumentMetadata(documentId: number) {
    this.setState({ selectedDocumentId: documentId, documentHistoryMetadataModalOpen: !this.state.documentHistoryMetadataModalOpen })
  }

  private resubmitDocuments = (item: IDocumentItem | null) => {
    if (item !== null) {
      let currentlySelected = this._selection.getSelection() as IDocumentItem[];
      const alreadySelected = currentlySelected.filter(selected => selected.id === item.id)
      let nResubmitDocumentDetails: resubmitDocumentDetails[] = []
      for (let selectedDoc of currentlySelected) {
        if (this.props.documentHistory?.documentHistoryItems) {
          if (selectedDoc.status === 7 || selectedDoc.status === 5) {
            const nDocumentDetails: resubmitDocumentDetails = {
              documentId: selectedDoc.id,
              storeId: selectedDoc.storeId,
              documentName: selectedDoc.name
            }

            nResubmitDocumentDetails.push(nDocumentDetails)
          }
        }
      }

      if (alreadySelected.length === 0) {
        const nDocumentDetails: resubmitDocumentDetails = {
          documentId: item.id,
          storeId: item.storeId,
          documentName: item.name
        }
        nResubmitDocumentDetails.push(nDocumentDetails);
        // currentlySelected.push(item)
        // this._selection.setItems([])
      }
      this.setState({ documentSubmitDetails: nResubmitDocumentDetails, documentSubmitModalOpen: true })
    }
    else {

      this.setState({ documentSubmitModalOpen: true })
    }
  }

  //Sets context menu.
  private onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    if (column.columnActionsMode !== ColumnActionsMode.disabled && !this.getColoumnSearchEnabled(column.fieldName ? column.fieldName : "")) {
      this.setState({ contextualMenuProps: this.getContextualMenuProps(ev, column) })
    }
  };

  //Checks if column search is enabled
  private getColoumnSearchEnabled = (columnName: string) => {
    const { columnsSearch } = this.state;

    switch (columnName) {
      case "name": return columnsSearch.name.isActive
      case "submittedDate": return columnsSearch.startDate.isActive
      case "status": return columnsSearch.status.isActive
      case "storeId": return columnsSearch.storeName.isActive
    }
  }

  //Builds and returns context menu props.
  private getContextualMenuProps = (ev: React.MouseEvent<HTMLElement>, column: IColumn): IContextualMenuProps => {
    const items = [
      {
        key: 'aToZ',
        name: 'A to Z',
        iconProps: { iconName: 'SortUp' },
        canCheck: true,
        checked: column.isSorted && !column.isSortedDescending,
        onClick: () => this.onSortColumn(column.key, false),
      },
      {
        key: 'zToA',
        name: 'Z to A',
        iconProps: { iconName: 'SortDown' },
        canCheck: true,
        checked: column.isSorted && column.isSortedDescending,
        onClick: () => this.onSortColumn(column.key, true),
      },
      {
        key: 'filter',
        name: 'Filter',
        subMenuProps: {
          items: [
            { key: 'startsWith', text: 'Starts With', title: 'Filters Items that start with text', onClick: () => { this.searchColumns(column.fieldName ? column.fieldName : "", true, queryCondition.startsWith) } },
            { key: 'equalTo', text: 'Is Equal To', title: 'Filters Items that equal to', onClick: () => { this.searchColumns(column.fieldName ? column.fieldName : "", true, queryCondition.isEqual) } },
          ],
        },
      }
    ];

    if (column.fieldName === "submittedDate") {
      if (items[2].subMenuProps)
        items[2].subMenuProps.items = [{ key: 'Between', text: 'Between', title: 'Filters Items between two dates', onClick: () => { this.searchColumns(column.fieldName ? column.fieldName : "", true, queryCondition.greaterThan) } }]
    }

    if (column.fieldName === "storeId" || column.fieldName === "status") {
      if (items[2].subMenuProps)
        items[2].subMenuProps?.items.splice(0, 1);
    }

    return {
      items: items,
      target: ev.currentTarget as HTMLElement,
      directionalHint: DirectionalHint.bottomLeftEdge,
      gapSpace: 10,
      isBeakVisible: true,
      onDismiss: this.onContextualMenuDismissed,
    };
  }

  //Dismiss context menu
  private onContextualMenuDismissed = (): void => {
    this.setState({ contextualMenuProps: undefined });
  }

  private onDropDownChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined, isStore: boolean): void => {
    if (isStore) {
      let currentDocumentQuery = this.props.documentHistory?.documentsQuery ? _.cloneDeep(this.props.documentHistory.documentsQuery) : null
      if (currentDocumentQuery !== null) {
        let newQueryFilter: QueryFilter = {
          field: "storeId",
          condition: queryCondition.isEqual,
          value: item ? item.key.toString() : "",
        }
        var queryExists = currentDocumentQuery.filters.filter(query => query.field === "storeId")
        if (queryExists.length === 0) {
          if (currentDocumentQuery.filters.length > 0) {
            currentDocumentQuery.filters = currentDocumentQuery.filters.concat(newQueryFilter);
          }
          else {
            currentDocumentQuery.filters = [newQueryFilter];
          }
        }
        else {
          let filters = currentDocumentQuery.filters;

          currentDocumentQuery.filters.forEach((filter, index, array) => {
            if (filter.field === "storeId") {
              filters[index] = newQueryFilter;
            }
          })

          currentDocumentQuery.filters = filters;
        }
        currentDocumentQuery.pageSize = currentDocumentQuery.pageSize * currentDocumentQuery.pageNumber;
        currentDocumentQuery.pageNumber = 1;
        this.props.getData(this.props.auth?.SelectedCustomer?.id ? this.props.auth.SelectedCustomer.id : 0, currentDocumentQuery);
        this.setState({ selectedStore: item });
      }
    }
    else {

      let currentDocumentQuery = this.props.documentHistory?.documentsQuery ? _.cloneDeep(this.props.documentHistory.documentsQuery) : null

      if (currentDocumentQuery !== null) {
        let newQueryFilter: QueryFilter = {
          field: "status",
          condition: queryCondition.isEqual,
          value: item ? item.key.toString() : ""
        }
        var queryExists = currentDocumentQuery.filters.filter(query => query.field === "status")
        if (queryExists.length === 0) {
          if (currentDocumentQuery.filters.length > 0) {
            currentDocumentQuery.filters = currentDocumentQuery.filters.concat(newQueryFilter);
          }
          else {
            currentDocumentQuery.filters = [newQueryFilter];
          }
        }
        else {
          let filters = currentDocumentQuery.filters;
          currentDocumentQuery.filters.forEach((filter, index, array) => {
            if (filter.field === "status") {
              filters[index] = newQueryFilter;
            }
          })

          currentDocumentQuery.filters = filters;
        }

        currentDocumentQuery.pageSize = currentDocumentQuery.pageSize * currentDocumentQuery.pageNumber;
        currentDocumentQuery.pageNumber = 1;
        this.props.getData(this.props.auth?.SelectedCustomer?.id ? this.props.auth.SelectedCustomer.id : 0, currentDocumentQuery);
        if(item){
        item.text = StoreStatus[parseInt(item.key.toString())]
        }
        this.setState({ selectedStatus: item });
      }
    }
  }

  private renderDropdowns = (isStore: boolean, colName: string) => {
    const { docStoreDropdownList } = this.state;
    const dropDownOptions = isStore ? this.props.documentHistory ? this.props.documentHistory.documentStoreDropdownList : [] : statusOptions;
    const currentSelectedKey = isStore ? this.state.selectedStore ? this.state.selectedStore.key : undefined : this.state.selectedStatus ? this.state.selectedStatus.key.toString() : undefined;
    return (
      <div style={{ display: 'flex', margin: 5 }}>
        <Dropdown
          styles={dropdownStyles}
          label=""
          onChange={(e, i) => this.onDropDownChange(e, i, isStore)}
          selectedKey={currentSelectedKey}
          options={dropDownOptions}
        />
        <IconButton
          iconProps={{ iconName: 'Cancel' }}
          ariaLabel="Close popup modal"
          onClick={(ev) => { ev.stopPropagation(); this.searchColumns(colName, false, queryCondition.none) }}
        />
      </div>
    )
  }

  private renderSearchTextField(colName: string) {
    return (
      <div style={{ display: 'flex', margin: 5 }}>
        <TextField onChange={(e, v) => this.onNameSearchTextChange(e, v)} value={this.state.nameFieldValue} />
        <IconButton
          iconProps={{ iconName: 'Cancel' }}
          ariaLabel="Close popup modal"
          onClick={(ev) => { ev.stopPropagation(); this.searchColumns(colName, false, queryCondition.none) }}
        />
      </div>
    )

  }

  private convertDate = (date: Date) => {
    let formatted_date = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    return formatted_date
  }

  private setStartDate = (date?: Date | null) => {
    if (date) {
      let currentDocumentQuery = this.props.documentHistory?.documentsQuery ? _.cloneDeep(this.props.documentHistory.documentsQuery) : null
      if (currentDocumentQuery !== null) {
        let newQueryFilter: QueryFilter = {
          field: "submittedDate",
          condition: queryCondition.greaterThan,
          value: this.convertDate(date)
        }
        var queryExists = currentDocumentQuery.filters.filter(query => query.field === "submittedDate")
        if (queryExists.length === 0) {
          currentDocumentQuery.filters.push(newQueryFilter);
        }
        else {
          if (queryExists.length === 1 && queryExists[0].condition == queryCondition.lessThan) {
            let newFilters: QueryFilter[] = currentDocumentQuery.filters;
            let filterId = 0
            for (let filter of currentDocumentQuery.filters) {
              if (filter.condition === queryCondition.lessThan) {
                newFilters.splice(filterId, 0, newQueryFilter);
              }
              filterId++
            }
            currentDocumentQuery.filters = newFilters;
          }
          else {
            let filters = currentDocumentQuery.filters;
            for (let filter of currentDocumentQuery.filters) {
              if (filter.field === "submittedDate" && filter.condition === queryCondition.greaterThan) {
                filter = newQueryFilter;
              }
            }
            currentDocumentQuery.filters = filters;
          }
        }

        currentDocumentQuery.pageSize = currentDocumentQuery.pageSize * currentDocumentQuery.pageNumber;
        currentDocumentQuery.pageNumber = 1
        this.props.getData(this.props.auth?.SelectedCustomer?.id ? this.props.auth.SelectedCustomer.id : 0, currentDocumentQuery);
        this.setState({ selectedStartDate: date })
      }
    }
  }

  private setEndDate = (date?: Date | null) => {
    if (date) {
      let currentDocumentQuery = this.props.documentHistory?.documentsQuery ? _.cloneDeep(this.props.documentHistory.documentsQuery) : null
      if (currentDocumentQuery !== null) {
        let newQueryFilter: QueryFilter = {
          field: "submittedDate",
          condition: queryCondition.lessThan,
          value: this.convertDate(date)
        }
        var queryExists = currentDocumentQuery.filters.filter(query => query.condition === queryCondition.lessThan)
        if (queryExists.length === 0) {
          currentDocumentQuery.filters.push(newQueryFilter)
        }
        else {
          let filters = currentDocumentQuery.filters;
          for (let filter of currentDocumentQuery.filters) {
            if (filter.field === "submittedDate" && filter.condition === queryCondition.lessThan) {
              filter = newQueryFilter;
            }
          }
          currentDocumentQuery.filters = filters;

        }
        currentDocumentQuery.pageSize = currentDocumentQuery.pageSize * currentDocumentQuery.pageNumber;
        currentDocumentQuery.pageNumber = 1;
        this.props.getData(this.props.auth?.SelectedCustomer?.id ? this.props.auth.SelectedCustomer.id : 0, currentDocumentQuery);
        this.setState({ selectedEndDate: date })
      }
    }
  }

  private onFormatDate = (date?: Date): string => {
    return !date ? '' : date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
  }

  private renderDatePickers(colName: string) {
    const containerStackTokens: IStackTokens = { childrenGap: 5, padding: 5 };
    const { selectedStartDate, selectedEndDate } = this.state;
    return (
      <Stack tokens={containerStackTokens} horizontal >
        <DatePicker
          value={selectedStartDate}
          showWeekNumbers={true}
          firstWeekOfYear={1}
          showMonthPickerAsOverlay={true}
          onSelectDate={this.setStartDate}
          formatDate={this.onFormatDate}
          placeholder="Start Date"
          ariaLabel="Select a date"
        />
        <DatePicker
          value={selectedEndDate}
          showWeekNumbers={true}
          firstWeekOfYear={1}
          showMonthPickerAsOverlay={true}
          onSelectDate={this.setEndDate}
          formatDate={this.onFormatDate}
          placeholder="End Date"
          ariaLabel="Select a date"
        />
        <IconButton
          iconProps={{ iconName: 'Cancel' }}
          ariaLabel="Close popup modal"
          onClick={(ev) => { ev.stopPropagation(); this.searchColumns(colName, false, queryCondition.none) }}
        />
      </Stack>
    )
  }

  private onNameSearchTextChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    if (newValue !== undefined) {
      let currentDocumentQuery = this.props.documentHistory?.documentsQuery ? _.cloneDeep(this.props.documentHistory.documentsQuery) : null;
      if (currentDocumentQuery !== null) {
        let newQueryFilter: QueryFilter = {
          field: "name",
          condition: this.state.columnsSearch.name.queryCondition,
          value: newValue ? newValue : ""
        }
        var queryExists = currentDocumentQuery.filters.filter(query => query.field === "name")
        if (queryExists.length === 0) {
          if (currentDocumentQuery.filters.length > 0)
          {
            currentDocumentQuery.filters = currentDocumentQuery.filters.concat(newQueryFilter);
          }
          else
          {
            currentDocumentQuery.filters.push(newQueryFilter);
          }
        }
        else {
          let filters = currentDocumentQuery.filters;
          currentDocumentQuery.filters.forEach((filter, index, array) => {
            if (filter.field === "name") {
              filters[index] = newQueryFilter;
            }
          })

          currentDocumentQuery.filters = filters;

        }
        currentDocumentQuery.pageSize = currentDocumentQuery.pageSize * currentDocumentQuery.pageNumber;
        currentDocumentQuery.pageNumber = 1
        this.props.getData(this.props.auth?.SelectedCustomer?.id ? this.props.auth.SelectedCustomer.id : 0, currentDocumentQuery);
        this.setState({ nameFieldValue: newValue });
      }
    }
  }

  //Sorts selected column
  private onSortColumn = (columnKey: string, isSortedDescending: boolean): void => {
    const { columns } = this.state;
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(currCol => columnKey === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = isSortedDescending;
        currColumn.isSorted = true;
      }
      else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    })
    this.sortHistoryList(isSortedDescending, newColumns, currColumn.fieldName)
  }

  //Sends query to retrieve new items.
  private sortHistoryList = (isSortedDescending: boolean, newColumns: IColumn[], fieldName?: string) => {
    if (this.props.documentStore && this.props.auth?.SelectedCustomer) {
      let newSort: SortFilter = { field: fieldName ? fieldName : "", ascDesc: isSortedDescending ? ascendingDescending.descending : ascendingDescending.ascending };
      let currentDocumentQuery = this.props.documentHistory?.documentsQuery ? _.cloneDeep(this.props.documentHistory.documentsQuery) : null
      if (currentDocumentQuery) {
        currentDocumentQuery.sort = newSort;
        currentDocumentQuery.pageSize = currentDocumentQuery.pageSize * currentDocumentQuery.pageNumber;
        currentDocumentQuery.pageNumber = 1;
        this.props.getData(this.props.auth.SelectedCustomer.id, currentDocumentQuery)
        this.setState({ columns: newColumns });
      }
    }
  }

  private removeSelectedQuery = (queryType: string) => {
    let currentDocumentQuery = this.props.documentHistory?.documentsQuery ? _.cloneDeep(this.props.documentHistory.documentsQuery) : null
    if (currentDocumentQuery !== null) {
      if (currentDocumentQuery.filters.length > 0) {
        let currentQueryFilters: QueryFilter[] = [];
        for (let filters of currentDocumentQuery.filters) {
          if (filters.field !== queryType) {
            currentQueryFilters.push(filters)
          }
        }
        currentDocumentQuery.filters = currentQueryFilters;
        currentDocumentQuery.pageSize = currentDocumentQuery.pageSize * currentDocumentQuery.pageNumber;
        currentDocumentQuery.pageNumber = 1;
        this.props.getData(this.props.auth?.SelectedCustomer ? this.props.auth.SelectedCustomer.id : 0, currentDocumentQuery);
      }
    }
  }

  private searchColumns = (columnName: string, visible: boolean, condition: queryCondition) => {
    const { columnsSearch } = this.state;
    switch (columnName) {
      case "name": { columnsSearch.name.isActive = visible; columnsSearch.name.queryCondition = condition; if (visible == false) { this.removeSelectedQuery(columnName); this.setState({ nameFieldValue: '' }) } };
        break;
      case "status": { columnsSearch.status.isActive = visible; columnsSearch.status.queryCondition = condition; if (visible == false) { this.removeSelectedQuery(columnName); this.setState({ selectedStatus: undefined }) } };
        break;
      case "submittedDate": {
        columnsSearch.startDate.isActive = visible;
        columnsSearch.endDate.isActive = visible;
        columnsSearch.startDate.queryCondition = queryCondition.greaterThan;
        columnsSearch.endDate.queryCondition = queryCondition.lessThan;
        if (visible == false) { this.removeSelectedQuery("submittedDate"); this.setState({ selectedStartDate: undefined, selectedEndDate: undefined }) }
      };
        break;
      case "storeId": { columnsSearch.storeName.isActive = visible; columnsSearch.storeName.queryCondition = condition; if (visible == false) { this.removeSelectedQuery(columnName); this.setState({ selectedStore: undefined }) } };
        break
    }
    this.setState({ columnsSearch: columnsSearch })
    if (columnName == "status" || columnName == "storeId") {
      if (visible === false) {
        // this.BuildDocumentHistoryItemList(this.props.customer);
      }
    }
  }

  private mapColumnsToQuery = (query: DocumentQuery, columns: IColumn[]) => {
    let nColumnSearch = _.clone(this.state.columnsSearch)

    if (query.filters.length === 0) {
      nColumnSearch.status.isActive = false;
      nColumnSearch.storeName.isActive = false;
      this.setState({ columns: columns, columnsSearch: nColumnSearch, selectedStore: undefined, selectedStatus: undefined })
    }
    else {
      for (let filter of query.filters) {
        switch (filter.field) {
          // case "name": {nColumnSearch.name.isActive = true; nColumnSearch.name.queryCondition = filter.condition}
          case "status": { nColumnSearch.status.isActive = true; nColumnSearch.status.queryCondition = filter.condition; this.setState({ selectedStatus: this.assignNewStatusStore(filter.value, true) }) } //needs to assign status to relavent dropdown item.
            break;
          // case "submittedDate": {nColumnSearch.startDate.isActive = true; nColumnSearch.startDate.queryCondition = filter.condition}
          case "storeId": { nColumnSearch.storeName.isActive = true; nColumnSearch.storeName.queryCondition = filter.condition; this.setState({ selectedStore: this.assignNewStatusStore(filter.value, false) }) } //needs to assign storeId to relavent dropdown item.
            break;
        }
      }
      this.setState({ columns: columns, columnsSearch: nColumnSearch })
    }
  }

  private setResolveDialog = (customerId: number, documentId: number) => {
    let resolveDetail: resolveDetails = {
      customerId: customerId,
      documentId: documentId
    }

    this.setState({ resolveDetails: resolveDetail, resolveConfirmationDialogHidden: !this.state.resolveConfirmationDialogHidden });
  }

  private assignNewStatusStore = (value: string, isStatus: boolean) => {
    const dropDownOptions = !isStatus ? this.props.documentHistory ? this.props.documentHistory.documentStoreDropdownList : [] : statusOptions;
    if (isStatus == true) {
      for (let dropOption of dropDownOptions) {
        if (value.toString() === dropOption.key) {
          return dropOption
        }
      }
    }
    else {
      for (let dropOption of dropDownOptions) {
        if (parseInt(value) === dropOption.key) {
          return dropOption
        }
      }
    }
  }

  private _handleScroll(event: React.UIEvent<HTMLDivElement | List<any>>) {
    event.stopPropagation();
    const { scrollHeight, scrollTop, clientHeight } = event.target as typeof event.target & scrollData;
    if (clientHeight + scrollTop + 0.5 >= scrollHeight) {
      if (this.props.auth?.SelectedCustomer) {
        let currentDocumentQuery = this.props.documentHistory?.documentsQuery ? _.clone(this.props.documentHistory.documentsQuery) : null
        if (currentDocumentQuery !== null) {
          currentDocumentQuery.pageNumber += 1;
          this.props.getData(this.props.auth?.SelectedCustomer ? this.props.auth.SelectedCustomer.id : 0, currentDocumentQuery);
        }
      }
    }
  }

  private _refreshTable = () => {
    const currentQuery = this.props.documentHistory?.documentsQuery ? _.cloneDeep(this.props.documentHistory.documentsQuery) : null
    if (currentQuery) {
      currentQuery.pageSize = currentQuery.pageSize * currentQuery.pageNumber;
      currentQuery.pageNumber = 1;
      this.props.getData(this.props.auth?.SelectedCustomer ? this.props.auth.SelectedCustomer.id : 0, currentQuery)
    }
  }

  private dismissDocumentResubmitModal = () => {
    this.setState({ documentSubmitModalOpen: !this.state.documentSubmitModalOpen });
  }

  private dismissDocumentHistoryModal = () => {
    this.setState({ documentHistoryModalOpen: !this.state.documentHistoryModalOpen });
  }

  private dismissDocumentMetadataModal = () => {
    this.setState({ documentHistoryMetadataModalOpen: !this.state.documentHistoryMetadataModalOpen });
  }

  private toggleDownloadDialog = () => {
    this.setState({ downloadDialogHidden: !this.state.downloadDialogHidden })
  }

  private toggleResolvedDialog = () => {
    this.setState({ resolveConfirmationDialogHidden: !this.state.resolveConfirmationDialogHidden })
  }

  private toggleHistoryInfoModal = () => {
    this.setState({ documentHistoryInfoModalOpen: !this.state.documentHistoryInfoModalOpen })
  }

  private setDownloadedDocumentDetails = (selectedStoreId: number, selectedDocumentId: number, documentName: string) => {
    let currentDocumentDetails = _.clone(this.state.selectedDocumentDetails);
    currentDocumentDetails.documentId = selectedDocumentId;
    currentDocumentDetails.storeId = selectedStoreId;
    currentDocumentDetails.documentName = documentName;
    this.setState({ downloadDialogHidden: false, selectedDocumentDetails: currentDocumentDetails });
  }

  private setMetaDataDocumentDetails = (selectedStoreId: number, selectedDocumentId: number, documentName: string) => {
    let currentDocumentDetails = _.clone(this.state.selectedDocumentDetails);
    currentDocumentDetails.documentId = selectedDocumentId;
    currentDocumentDetails.storeId = selectedStoreId;
    currentDocumentDetails.documentName = documentName;
    this.setState({ documentHistoryMetadataModalOpen: true, selectedDocumentDetails: currentDocumentDetails });
  }

  private _onRenderRow: IDetailsListProps['onRenderRow'] = props => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      switch (props.item.status) {
        case "Processed": customStyles.root = { backgroundColor: "#c9e4de" };
          break;
        case "ProcessingError": customStyles.root = { backgroundColor: "#fde2e4" };
          break;
        case "Moved": customStyles.root = { backgroundColor: "#c6def1" };
          break;
      }
      return <DetailsRow {...props} styles={customStyles} />;
    }
    return null;
  };

  public render() {
    const { columns, contextualMenuProps, documentSubmitModalOpen, documentHistoryModalOpen, selectedDocumentId, documentHistoryMetadataModalOpen, documentsSelected, downloadDialogHidden, documentHistoryInfoModalOpen, documentSubmitDetails, selectedDocumentDetails, resolveConfirmationDialogHidden, resolveDetails } = this.state;
    const detailListProps: IListProps = {
      className: "test",
      onScroll: (e) => this._handleScroll(e),
      style: {
        height: "75vh",
        overflowY: 'auto'
      }
    }

    const title = `Resubmit ${documentSubmitDetails.length} documents`

    return (
      <div style={{ fontSize: 30 }}>
        <Stack horizontal styles={{ root: { height: 40 } }}>
          <CommandBarButton iconProps={{ iconName: "Subscribe" }} text={title} disabled={!documentsSelected} onClick={() => this.resubmitDocuments(null)} />
          <CommandBarButton iconProps={{ iconName: "Refresh" }} onClick={() => this._refreshTable()} text="Refresh" />
          <CommandBarButton iconProps={{ iconName: "Info" }} onClick={() => this.toggleHistoryInfoModal()} text="Info" />
        </Stack>
        <MarqueeSelection selection={this._selection}>
          <DetailsList
            className="DocumentHistoryList"
            columns={columns}
            items={this.props.documentHistory ? this.props.documentHistory.documentHistoryItems : []}
            selectionMode={SelectionMode.multiple}
            selection={this._selection}
            selectionPreservedOnEmptyClick={true}
            styles={detailListStyle}
            layoutMode={DetailsListLayoutMode.fixedColumns}
            listProps={detailListProps}
            onRenderRow={this._onRenderRow}
          />
        </MarqueeSelection>
        {contextualMenuProps && <ContextualMenu {...contextualMenuProps} />}
        <Dialog
          hidden={downloadDialogHidden}
          onDismiss={this.toggleDownloadDialog}
          dialogContentProps={dialogContentProps}
        >
          <DialogFooter>
            <PrimaryButton onClick={this.downloadDocument} text="Download" />
            <DefaultButton onClick={this.toggleDownloadDialog} text="Cancel" />
          </DialogFooter>
        </Dialog>
        <Dialog
          hidden={resolveConfirmationDialogHidden}
          onDismiss={this.toggleResolvedDialog}
          dialogContentProps={resolvedDialogContentProps}
        >
          <DialogFooter>
            <PrimaryButton onClick={() => this.resolveDocument(resolveDetails.customerId, resolveDetails.documentId)} text="Ok" />
            <DefaultButton onClick={this.toggleResolvedDialog} text="Cancel" />
          </DialogFooter>
        </Dialog>

        <DocumentHistoryInfoModal isOpen={documentHistoryInfoModalOpen} onDismiss={this.toggleHistoryInfoModal} />
        <DocumentHistoryResubmitModal isOpen={documentSubmitModalOpen} customer={this.props.auth?.SelectedCustomer ? this.props.auth.SelectedCustomer : null} docDetails={documentSubmitDetails} documentResubmitted={() => this._refreshTable()} dismissModal={this.dismissDocumentResubmitModal} />
        <DocumentHistoryModal customerId={this.props.auth?.SelectedCustomer ? this.props.auth.SelectedCustomer.id : 0} documentId={selectedDocumentId} isOpen={documentHistoryModalOpen} dismissModal={this.dismissDocumentHistoryModal} />
        <DocumnetHistoryMetadataModal isOpen={documentHistoryMetadataModalOpen} customer={this.props.auth?.SelectedCustomer ? this.props.auth.SelectedCustomer : null} documentDetails={selectedDocumentDetails} documentResubmitted={() => this._refreshTable()} dismissModal={this.dismissDocumentMetadataModal} />
      </div>
    )
  }
}


export default connect(
  (state: ApplicationState) => state,
  DocumentHistoryStore.actionCreatorsHistory,
)(DocumentHistoryTable as any);


const dialogContentProps = {
  type: DialogType.normal,
  title: 'Download Document',
  closeButtonAriaLabel: 'Close',
  subText: 'Would you like to download this document?',
};



const resolvedDialogContentProps = {
  type: DialogType.normal,
  title: 'Resolve document',
  closeButtonAriaLabel: 'Close',
  subText: 'Are you sure you wish to resolve this document',
};