import axios from 'axios';
import { getAccessToken, getTokenExpiry, loginRequest, msalInstance, } from '../authConfig';
import {CreateCustomerAdministrator } from '../interfaces/customerAdministrator';



axios.interceptors.request.use(
    async (config: any) => {
        if (config.headers) {
            config.headers['Content-Type'] = 'application/json';
            config.headers['Authorization'] = 'Bearer ' + getAccessToken();
        }
        
        //Dev Use REACT_APP_PORTAL_REDIRECT_URI for Proxy;
        //Production Use REACT_APP_PORTAL_API_URL
        config.baseURL = process.env.REACT_APP_PORTAL_API_URL;

        if(getTokenExpiry() < new Date())
        {
            const account = msalInstance.getAllAccounts()[0];
            if (account) {
                const accessTokenResponse = await msalInstance.acquireTokenSilent({
                    scopes: loginRequest.scopes,
                    account: account
                });

                if (accessTokenResponse) {
                    const accessToken = accessTokenResponse.accessToken;
                    const tokenExpiry = accessTokenResponse.expiresOn ? accessTokenResponse.expiresOn : new Date();
                    getAccessToken(accessToken);
                    getTokenExpiry(tokenExpiry);
                    if (config.headers && accessToken) {
                        config.headers['Authorization'] = 'Bearer ' + accessToken;
                    }
                }
            }
        }
        return config;
    },
    error => {
        Promise.reject(error)
    });


//Gets current user info
export const getUserContext = async () => {
    var response = await GetAsync(`_api/portal/context`)
    return response
}

//Gets subscription key
export const getVendorSubscriptionKey = async (customerId : number, vendorId : number) => {
    var response = await GetAsync('_api/portal/customers/' + customerId + '/vendors/' + vendorId + '/key')
    return response
}

//Get all vendors by customer id
export const getAllVendorsByCustomerId = async (customerId: number) => {
    var response = await GetAsync('_api/portal/customers/' + customerId + '/vendors')
    return response
}

//Generate API Key
export const generateApiKey = async (customerId: number, storeId: number, postData: any) => {
    var response = await PostAsync('_api/portal/customers/' + customerId + '/stores/' + storeId + '/keys', postData)
    return response
}

// Gets the list of customers which the current user can access
export const getAllCustomers = async () => {
    var response = await GetAsync('_api/portal/customers')
    return response;
}

// Gets customer by id
export const getCustomerById = async (customerId: number) => {
    var response = await GetAsync('_api/portal/customers/' + customerId)
    return response
}

export const getAllSubscriptionsByDocumentStore = async(customerId: number, documentStoreId: number) => {
    var response = await GetAsync('_api/portal/customers/' + customerId + '/stores/' + documentStoreId + '/subscriptions')
    return response
}

export const getSubscriptionById = async(customerId: number, documentStoreId: number, subscriptionId: number) => {
    var response = await GetAsync('_api/portal/customers/' + customerId + '/stores/' + documentStoreId + '/subscriptions/' + subscriptionId)
    return response
}

export const createSubscription = async(customerId: number, documentStoreId: number, postData: object) => {
    var response = await PostAsync('_api/portal/customers/' + customerId + '/stores/' + documentStoreId + '/subscriptions', postData)
    return response
}

export const updateSubscription = async(customerId: number, documentStoreId: number, subscriptionId: number, putData: object) => {
    var response = await PutAsync('_api/portal/customers/' + customerId + '/stores/' + documentStoreId + '/subscriptions/' + subscriptionId, putData)
    return response
}

// Gets document store by id
export const getDocumentStoreById = async (customerId: number, storeId: number) => {
    var response = await GetAsync('_api/portal/customers/' + customerId + '/stores/' + storeId)
    return response
}

// Gets all document stores by customer id
export const getAllDocumentStoresByCustomerId = async (customerId: number) => {
    var response = await GetAsync('_api/portal/customers/' + customerId + '/stores')
    return response
}

export const setDocumentToResolved = async(customerId: number, documentId: number) => {
    var response = await GetAsync('_api/portal/customers/' + customerId + '/documents/' + documentId + '/resolved')
    return response
}

// Gets the published metadata settings for a specified document store
export const getMetadataForDocumentStoreById = async (customerId: number, storeId: number) => {
    var response = await GetAsync('_api/portal/customers/' + customerId + '/stores/' + storeId + '/metadataSettings')
    return response
}

// Updates the published metadata settings for a specified document store
export const updateMetadataForDocumentStore = async (putData: object, customerId: number, storeId: number) => {
    var response = await PutAsync('_api/portal/customers/' + customerId + '/stores/' + storeId + '/metadataSettings', putData)
    return response
}

// Adds the Document store for the specified customer which the current user can access
export const addDocumentStore = async (putData: object, customerId: number) => {
    var response = await PutAsync('_api/portal/customers/' + customerId + '/stores', putData)
    return response
}

// Updates the Document store for the specified customer which the current user can access
export const updateDocumentStore = async (putData: object, customerId: number, storeId: number) => {
    var response = await PutAsync('_api/portal/customers/' + customerId + '/stores/' + storeId, putData)
    return response
}

// Deletes the specified document store
export const deleteDocumentStoreByStoreId = async (customerId: number, storeId: number) => {
    var response = await DeleteAsync('_api/portal/customers/' + customerId + '/stores/' + storeId)
    return response
}

export const getDocumentStoreMetadataExclusions = async (customerId: number, storeId: number) => {
    var response = await GetAsync('_api/portal/customers/' + customerId + '/stores/' + storeId + '/metadataEditExclusions');
    return response;
}

export const updateDocumentMetadata = async (customerId: number, documentId: number, putdata: object) => {
    var response = await PutAsync('_api/portal/customers/' + customerId + '/documents/' + documentId + '/metadata', putdata)
    return response;
}

// Gets the details for a document store type specified by it's typeId. The specified document store type
export const getStoreTypeByTypeId = async (customerId: number, typeId: string) => {
    var response = await GetAsync('_api/portal/customers/' + customerId + '/storetypes/' + typeId)
    return response
}

// Gets the list of document stores types for the specified customer which the current user can access
export const getStoreTypeByCustomerId = async (customerId: number) => {
    var response = await GetAsync('_api/portal/customers/' + customerId + '/storetypes')
    return response
}

// Gets the list of document stores types for the specified customer which the current user can access
export const getStoreTotalStats = async (customerId: number, storeId: number) => {
    var response = await GetAsync('_api/portal/customers/' + customerId + '/stores/' + storeId + '/statustotals')
    return response
}

// Executes the specified query against the specitied document store.
export const executeStoreQuery = async (customerId: number, storeId: number, postData: object) => {
    var response = await PostAsync('_api/portal/customers/' + customerId + '/stores/' + storeId + '/query', postData)
    return response
}

// Executes the specified query against the specitied document store type.
export const executeStoreTypeQuery = async (customerId: number, typeId: string, postData: object) => {
    var response = await PostAsync('_api/portal/customers/' + customerId + '/storetypes/' + typeId + '/query', postData)
    return response
}

// Executes the specified query against the specitied document store type. validates
export const validateStore = async (customerId: number, typeId: number, postData: object) => {
    var response = await PostAsync('_api/portal/customers/' + customerId + '/storetypes/' + typeId + '/validate', postData)
    return response
}

// Gets the storage metrics for the specified customer and document store
export const getStorageMetricsByCustomerIdAndStoreId = async (customerId: number, storeId: number) => {
    var response = await GetAsync('_api/portal/customers/' + customerId + '/stores/' + storeId + '/metrics')
    return response
}

// Gets the documents for the specified customer
export const getDocumentsByCustomerId = async (customerId: number) => {
    var response = await GetAsync('_api/portal/customers/' + customerId + '/documents')
    return response
}

// Gets the details about the specified document for the specified customer
export const getDocumentByCustomerAndDocumentId = async (customerId: number, documentId: number) => {
    var response = await GetAsync('_api/portal/customers/' + customerId + '/documents/' + documentId)
    return response
}

// Gets all document history entries for a document
export const getDocumentHistoryByDocumentId = async (customerId: number, documentId: number) => {
    var response = await GetAsync('_api/portal/customers/' + customerId + '/documents/' + documentId + '/history')
    return response
}

// Gets the list of customers Administrators which the current user has
export const getAllCustomerAdministrators = async (customerId: number) => {
    var response = await GetAsync('_api/portal/customers/' + customerId + '/administrators')
    return response
}

// Updates the Document store for the specified customer which the current user can access
export const createUpdateCustomerAdministrator = async (customerId: number, putData: CreateCustomerAdministrator[]) => {
    var response = await PutAsync('_api/portal/customers/' + customerId + '/administrators', putData)
    return response
}


// Deletes the specified key for the customer and the store
export const deleteApiKeyById = async (customerId: number, storeId: number, keyId: number) => {
    var response = await DeleteAsync('_api/portal/customers/' + customerId + '/stores/' + storeId + '/keys/' + keyId)
    return response
}

// Gets the list of API keys for the specified customer and document store
export const getAllApiKeysByCustomerAndDocumentId = async (customerId: number, storeId: number) => {
    var response = await GetAsync('_api/portal/customers/' + customerId + '/stores/' + storeId + '/keys')
    return response
}

// Gets the current user permission levels
export const getCurrentUserPermissions = async (customerId: number) => {
    var response = await GetAsync('_api/portal/customers/' + customerId + '/currentuserpermissions')
    return response
}

// Resubmit the document by updating status to queued for specified document
export const resubmitDocument = async (customerId: number, storeId: number, documentId: number) => {
    var response = await GetAsync('_api/portal/customer/' + customerId + '/stores/' + storeId + '/documents/' + documentId + '/resubmit')
    return response
}

// Delete the document by updating status to delete for specified document
export const deleteDocument = async (customerId: number, storeId: number, documentId: number) => {
    var response = await DeleteAsync('_api/portal/customer/' + customerId + '/stores/' + storeId + '/documents/' + documentId)
    return response
}

// Download the document for specified document 
export const downloadDocument = async (customerId: number, storeId: number, documentId: number) => {
    var response = await GetAsync('_api/portal/customer/' + customerId + '/stores/' + storeId + '/documents/' + documentId + '/download')
    return response
}

//Documents

// Gets the contents of the specified document from the document store
export const getDocumentContentById = async (documentId: number) => {
    var response = await GetAsync('documents/' + documentId + '/contents')
    return response
}


async function getRequest(url: string, data: string) {
    if (data === "") {
        try {
            const response = await axios.get(url);
            return response
        } catch (error) {
            return error
        }
    }
    else {
        const JsonData = JSON.parse(data);
        try {
            const response = await axios.get(url, JsonData);
            return response
        } catch (error) {
            return error
        }
    }
}

async function postRequest(url: string, data: string) {
    const JsonData = JSON.parse(data);
    try {
        const response = await axios.post(url, JsonData);
        return response
    } catch (error) {
        return error


    }
}

async function patchRequest(url: string, data: string) {
    const JsonData = JSON.parse(data);

    try {
        const response = await axios.patch(url, JsonData);
        return response
    } catch (error) {
        return error


    }
}

async function putRequest(url: string, data: string) {
    const JsonData = JSON.parse(data);

    try {
        const response = await axios.put(url, JsonData);
        return response
    } catch (error) {
        return error


    }
}

async function deleteRequest(url: string, data: string) {

    if (data === "") {
        try {
            const response = await axios.delete(url);
            return response
        } catch (error) {
            return error

        }
    }
    else {
        const JsonData = JSON.parse(data);
        try {
            const response = await axios.delete(url, JsonData);
            return response
        } catch (error) {
            return error

        }
    }
}

export async function axiosRouter(url: string, type: string, data = "") {
    switch (type) {
        case "Get": return getRequest(url, data)
        case "Post": return postRequest(url, data)
        case "Put": return putRequest(url,data)
        case "Patch": return patchRequest(url, data)
        case "Delete": return deleteRequest(url, data)
    }

}


//Get
export const GetAsync = async (url: string) => {
    try {
        var response = await axios.get(`${url}`, {
        headers: { 
            "Access-Control-Allow-Origin": '*'
        }}
        )
        return {
            status: response.status,
            data: response.data
        }
    } catch (e: any) {
        return e.response;
    }
}

//Delete
export const DeleteAsync = async (url: string) => {
    try {
        var response = await axios.delete(`${url}`, {
            headers: { 
                Authorization: `Bearer ${getAccessToken()}`,
                "Access-Control-Allow-Origin": '*'
            }})
        return {
            status: response.status,
            data: response.data
        }
    } catch (e: any) {
        return e.response;
    }
   }


//Post
export const PostAsync = async (url: string, postdata: object) => {
    try {
        var response = await axios.post(`${url}`, postdata, {
            headers: { 
                Authorization: `Bearer ${getAccessToken()}`,
                "Access-Control-Allow-Origin": '*'
            }})
        return {
            status: response.status,
            data: response.data
        }
    } catch (e: any) {
        return e.response;
    }
}

//Patch
export const PatchAsync = async (url: string, postdata: object) => {
    try {
        var response = await axios.patch(`${url}`, postdata,{
            headers: { 
                Authorization: `Bearer ${getAccessToken()}`,
                "Access-Control-Allow-Origin": '*'
            }})
        return {
            status: response.status,
            data: response.data
        }
    } catch (e: any) {
        return e.response;
    }
}

//Put
export const PutAsync = async (url: string, postdata: object) => {
    try {
        var response = await axios.put(`${url}`, postdata,{
            headers: { 
                Authorization: `Bearer ${getAccessToken()}`,
                "Access-Control-Allow-Origin": '*'
            }})
        return {
            status: response.status,
            data: response.data
        }
    } catch (e: any) {
        return e.response;
    }
}



//For later
// if (response.status >= 200 && response.status <= 240) {
//     return {
//         status: response.status,
//         data: response.data
//     }
//     }
//     else{
//     window.location.reload()
//     }

