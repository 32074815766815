import { CommandBarButton, Icon, IconButton, Modal, Separator, Stack } from '@fluentui/react';
import * as React from 'react';


interface infoItem {
    icon: string;
    title: string;
    text: string;
}

const DocumentHistoryInfo: infoItem[] = [
    {
        icon: "EntryView",
        title: "View History",
        text: "The view history action allows users to view a timeline of a document, with the options to select specific tags."
    },
    {
        icon: "DownloadDocument",
        title: "Download Document",
        text: "The download document action allows users to download the document to their local machine."
    },
    {
        icon: "Subscribe",
        title: "Re-upload Document",
        text: "The re-upload document action allows users to resubmit failed document(s)."
    },
    {
        icon: "ProcessMetaTask",
        title: "Edit Metadata",
        text: "The edit metadata action allows users to edit the metadata fields of the selected document."
    },
    {
        icon: "Completed",
        title: "Resolve Document",
        text: "The resolve document action allows user to set the selected documents status to resolved."
    }
]

//Returns a modal with styled list of history list record commands.
const DocumentHistoryInfoModal = (props: { isOpen: boolean, onDismiss: any }) => {

    const [isOpen, setIsOpen] = React.useState(false)
    const [infoList, setInfoList] = React.useState<infoItem[]>(DocumentHistoryInfo)
    const [areaSelectedId, setAreaSelectedId] = React.useState(-1)

    React.useEffect(() => {
        setIsOpen(props.isOpen)
    }, [props.isOpen])


    const onSelectInfoItem = (id: number) => {
        if (id === areaSelectedId) {
            setAreaSelectedId(-1)
        }
        else {
            setAreaSelectedId(id)
        }
    }

    return (
        <React.Fragment>
            <Modal
                isOpen={isOpen}
                onDismiss={props.onDismiss}
            >
                <Stack styles={{ root: { padding: 20, width: "40vw" } }} tokens={{ childrenGap: 20 }}>
                    {infoList.map((infoItem, id) => {
                        return (
                            <Stack>
                                <Separator >
                                    <CommandBarButton styles={{ root: { height: 30 } }} iconProps={{ iconName: infoItem.icon }} onClick={() => onSelectInfoItem(id)} text={infoItem.title} />
                                </Separator>
                                {areaSelectedId == id && (
                                    <div style={{ paddingTop: 10 }}>{infoItem.text}</div>
                                )}
                            </Stack>
                        )
                    })}
                </Stack>
            </Modal>
        </React.Fragment>
    )
}

export default DocumentHistoryInfoModal