import { TooltipHost, ITooltipHostStyles } from '@fluentui/react/lib/Tooltip';
import { Icon } from '@fluentui/react';
import ConstructionImage from '../Images/UnderConstruction.svg';
import './helperStyles.css'

export const IconTooltip = (props: {iconName: string, tooltipContent: string, onClick: any|undefined, size: number|undefined}) => {
    const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };

    return(
        <div className='helperIcon'>
        <TooltipHost
        content={props.tooltipContent}
        calloutProps={{gapSpace: 0}}
        styles={hostStyles}
        >
            <Icon styles={{root: {fontSize: props.size}}} onClick={props.onClick? props.onClick : {}} iconName={props.iconName}/>
        </TooltipHost>
        </div>
    )
}


//format date string to readable date
export const getFormattedDate = (datestring: string) => {
    let date = Date.parse(datestring);
    let current_datetime = new Date(date);
    let formatted_date = current_datetime.toLocaleString().replace(",", "");
    return formatted_date;
  };


export const getSessionStorageItem = (itemname: string):string => {
    let sessionStorageValue = "";
    const _sessionStorageItem = sessionStorage.getItem(itemname);
    if(_sessionStorageItem !== null)
    {
        sessionStorageValue = _sessionStorageItem;
    }
    return sessionStorageValue;
}

export const setSessionStorageItem = (itemname: string, value: string):void => {
    const _sessionStorageItem = sessionStorage.setItem(itemname, value);
    return _sessionStorageItem
}


export const underConstructionPanel = (height: number, width: number) => {
    return (
        <div style={{height: height, width: width, borderRadius: 4, padding: 10}}>
            <img alt="error" style={{display: "block", marginLeft: 'auto', marginRight: "auto"}} src={ConstructionImage} height={height * 0.8} width={width * 0.8} />
            <h1>Under Construction</h1>
        </div>
    )
}

export function formatBytes(bytes : number, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

//Pass Int value and enum to return a int array.
//Eg. var enumKeys = Object.keys(enum) must be called to be passed as object
//For left bit shifted enums "<<" to Evaluate and parse to a Int Array
//This will return a parsed Int array which correlate to the flags(enums) passed
export const getEnumFlags = (value: number, flags: string[]) => {
    const storeFlags = flags.filter((k) => !isNaN(parseInt(k)));
    let tempValue = value;
    let enumArray: number[] = [];
    for (let flagIndex = storeFlags.length - 1; flagIndex >= 0; flagIndex--) {
      const _flag = parseInt(storeFlags[flagIndex]);
      if (tempValue <= 0) {
        break;
      }
      if (tempValue >= _flag) {
        enumArray.push(_flag);
        tempValue -= _flag;
        flagIndex += 1;
      } else {
      }
    }
    return enumArray;
};


interface IStatusOption {
    key: string;
    text: string;
}

export const getStatusId = (name: string) =>
{
    const statusOptions: IStatusOption[] = [
        { key: '5', text: 'ProcessingError' },
        { key: '2', text: 'InProgress' },
        { key: '3', text: 'Processed' },
        { key: '6', text: 'Moved' },
        { key: '0', text: 'Queued' },
        { key: '4', text: 'Deleted' },
        { key: '1', text: 'ContentMissing' },
        { key: '7', text: 'TimeoutExceeded' },
        { key: '8', text: 'MissingRequiredFields' },
        { key: '9', text: 'NotMoved' },
      ];

      let key = "0"
      for(let status of statusOptions)
      {
          if(name === status.text)
          {
              key = status.key
          }
      }

      return key
}
