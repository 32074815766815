import axios from 'axios';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import {DocumentQuery, ascendingDescending} from '../interfaces/documentQuery';
import {DocumentHistoryRecord, IDocumentItem} from '../interfaces/documentHistoryEntry';
import { IDropdownOption } from '@fluentui/react';
import { getFormattedDate } from '../utilities/helpers';

export interface DocumentHistoryState {
    documents: DocumentHistoryRecord[];
    documentsQuery: DocumentQuery;
    documentStoreDropdownList: IDropdownOption[];
    documentHistoryItems: IDocumentItem[];
    isRedirect: boolean;
}




export interface ClearFilter {type: 'CLEAR_FILTER'}

export interface SetDocumentQuery {type: 'SET_DOCUMENT_QUERY', documentQuery: DocumentQuery}

export interface GetData {type: "REQUEST_SEARCHRESULTS", filter: string};

export interface SetQueryPageNumber {type: "SET_QUERY_PAGENUMBER", documentQuery: DocumentQuery}

export interface SetDocuments {type: "SET_DOCUMENTS", documents: DocumentHistoryRecord[]}

export interface GetDocumentStoreDropdownList {type: "GET_DOCUMENTSTORE_DROPDOWN"}

export interface SetDocumentStoreDropdown {type: "SET_DOCUMENTSTORE_DROPDOWN", documentStoreDropdownList: IDropdownOption[]}

export interface MapDocumentHistoryItems {type: "MAP_DOCUMENTHISTORY_ITEMS", documents: DocumentHistoryRecord[]}

export interface SetDocumentHistoryItems {type: "SET_DOCUMENTHISTORY_ITEMS", documentHistoryItems: IDocumentItem[]}

export interface SetIsRedirect {type: "SET_IS_REDIRECT", isRedirect: boolean}


export type KnownAction = 
|GetData
|ClearFilter
|SetQueryPageNumber
|SetDocumentQuery
|SetDocuments
|GetDocumentStoreDropdownList
|SetDocumentStoreDropdown
|SetIsRedirect
|MapDocumentHistoryItems
|SetDocumentHistoryItems

export const actionCreatorsHistory = {
    setDocumentQuery: (DocumentQuery: DocumentQuery) => ({type: 'SET_DOCUMENT_QUERY', documentQuery: DocumentQuery} as SetDocumentQuery),
    clearFilter: () => ({ type: 'CLEAR_FILTER' } as ClearFilter),
    setQueryPageNumber: () => (documentQuery: DocumentQuery) => ({type: 'SET_QUERY_PAGENUMBER', documentQuery: documentQuery} as SetQueryPageNumber),
    setDocuments: (Documents: DocumentHistoryRecord[]) => ({type: 'SET_DOCUMENTS', documents: Documents} as SetDocuments),
    setDocumentStoreDropdown: (docStoreDropdownOptions: IDropdownOption[]) => ({type: 'SET_DOCUMENTSTORE_DROPDOWN', documentStoreDropdownList: docStoreDropdownOptions} as SetDocumentStoreDropdown),
    setIsRedirect: (isRedirect: boolean) => ({type: 'SET_IS_REDIRECT', isRedirect: isRedirect} as SetIsRedirect),
    getDocumentStoreDropdown: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState()
        if(appState && appState.documentHistory && appState.documentStore)
        {
            let docStoreDropDownListOptions : IDropdownOption[] = [];
            for (let documentStore of appState.documentStore.documentStores){
                let nOption: IDropdownOption = {
                    key: documentStore.id, text: documentStore.name
                }

                docStoreDropDownListOptions.push(nOption);
            }
       
            dispatch({type: "SET_DOCUMENTSTORE_DROPDOWN", documentStoreDropdownList: docStoreDropDownListOptions});
        }
    },
    setDocumentHistoryItems: (DocumentHistoryItems: IDocumentItem[]) => ({type: 'SET_DOCUMENTHISTORY_ITEMS', documentHistoryItems: DocumentHistoryItems} as SetDocumentHistoryItems),
    mapDocumentHistoryItems: (Documents: DocumentHistoryRecord[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let documentHistoryRecordList = []
        for (var documentItem of Documents){
        const nDocumentItem: IDocumentItem = {
              id : documentItem.id,
              name: documentItem.name,
              storeId: documentItem.storeId,
              status: documentItem.status,
              submittedDate: getFormattedDate(documentItem.submittedDate)
            }

            documentHistoryRecordList.push(nDocumentItem)
        }
        dispatch({type: 'SET_DOCUMENTHISTORY_ITEMS', documentHistoryItems: documentHistoryRecordList})

    },
    getData: (CustomerId: number, DocumentQuery: DocumentQuery): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState()
        if (appState && appState.documentHistory && appState.documentStore ) {
        let searchAPIURL = `_api/portal/customers/${CustomerId}/documents?pageNumber=${DocumentQuery.pageNumber}&pageSize=${DocumentQuery.pageSize}`
        let filterValue = `&filter=1:1`
        
        if(DocumentQuery.filters.length > 0)
        {
            for (var filter of DocumentQuery.filters){
                if(filter.condition !== "none"){
                    let newFilter = "";
                    switch(filter.condition){
                        case 'is-equal': newFilter = `; ${filter.field}:'${filter.value}'`
                        break;
                        case 'starts-with': newFilter = `; ${filter.field}:'${filter.value}*'`
                        break;
                        case 'greater-than': newFilter = `; ${filter.field}>'${filter.value}'`
                        break;
                        case 'less-than': newFilter = `; ${filter.field}<'${filter.value}'`
                    }
                    // const newFilter = filter.condition === "is-equal" ? `; ${filter.field}:'${filter.value}'` : `; ${filter.field}:'${filter.value}*'`;
                    filterValue = filterValue.concat(newFilter);
                }
            }

        }
        
        searchAPIURL += filterValue;

        if(DocumentQuery.sort.field !== "")
        {
            const sortParam = `&sort=${DocumentQuery.sort.field}:${DocumentQuery.sort.ascDesc}`
            searchAPIURL += sortParam
        }


        //defualt
        axios.get(searchAPIURL).then(response => {

            let currentPageItems: DocumentHistoryRecord[] = appState.documentHistory?.documents ? appState.documentHistory.documents : []

            if(currentPageItems?.length > 0 && DocumentQuery.pageNumber > 1 && DocumentQuery.pageSize === 18)
            {
               currentPageItems = currentPageItems.concat(response.data)
            }
            else
            {
                currentPageItems = response.data
            }
             let mappedDocItems: IDocumentItem[] = []
             for(let document of currentPageItems){
                 let nDocumentItem: IDocumentItem = {
                    id : document.id,
                    name: document.name,
                    storeId: document.storeId,
                    status: document.status,
                    submittedDate: getFormattedDate(document.submittedDate)
                 }

                 mappedDocItems.push(nDocumentItem);
             }

            if(appState.documentHistory && DocumentQuery.pageNumber < appState.documentHistory.documentsQuery.pageNumber){
            DocumentQuery.pageNumber = appState.documentHistory? appState.documentHistory.documentsQuery.pageNumber : 1;
            }
            DocumentQuery.pageSize = 18;
        
            dispatch({type: 'SET_DOCUMENT_QUERY', documentQuery: DocumentQuery});
            dispatch({type: 'SET_DOCUMENTHISTORY_ITEMS', documentHistoryItems: mappedDocItems})
            dispatch({type: 'SET_DOCUMENTS', documents: currentPageItems});
        })

        }


    }
};

const unloadedState: DocumentHistoryState = {
    documents: [], 
    documentsQuery: {
        storeId: 0,
        filters: [],
        sort: {field: "submittedDate", ascDesc: ascendingDescending.descending},
        pageSize: 18,
        pageNumber: 1,
        excludeDeleted: false
    },
    documentStoreDropdownList: [],
    documentHistoryItems: [],
    isRedirect: false
};

export const reducer: Reducer<DocumentHistoryState> = (state: DocumentHistoryState | undefined, incomingAction: Action): DocumentHistoryState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'CLEAR_FILTER':
            return{
                ...state,
                documentsQuery: unloadedState.documentsQuery
            };
        case 'SET_QUERY_PAGENUMBER':
            return{
                ...state,
                documentsQuery: action.documentQuery
            }
        case 'SET_DOCUMENT_QUERY':
            return{
                ...state,
                documentsQuery: action.documentQuery
            }
        case 'SET_DOCUMENTSTORE_DROPDOWN':
            return{
                ...state,
                documentStoreDropdownList: action.documentStoreDropdownList
            }
        case 'SET_IS_REDIRECT':
            return{
                ...state,
                isRedirect: action.isRedirect
            }
        case 'SET_DOCUMENTS':
            return {
                ...state,
                documents: action.documents
            }
        case 'SET_DOCUMENTHISTORY_ITEMS':
            return {
                ...state,
                documentHistoryItems: action.documentHistoryItems
            }
        default:
            return state;
    }
};
