import * as React from 'react';
// import DocumentHistoryTable from '../components/DocumentHistoryTable';
import {Customer} from '../interfaces/customer';
import { DocumentStore, DocumentStores } from '../interfaces/documentStore';
import { RouteComponentProps } from 'react-router-dom';
import {connect} from 'react-redux';
import * as DocumentHistoryStore from '../store/DocumentHistory';
import DocumentHistoryTable from '../components/DocumentHistory/DocumentHistoryTable';
import { useDispatch,  } from 'react-redux';
import {actionCreatorsHistory} from '../store/DocumentHistory';
import {DocumentQuery, queryCondition, ascendingDescending} from '../interfaces/documentQuery';
import { ApplicationState } from '../store';
import { throws } from 'assert';

type DocumentHistoryProps = 
ApplicationState & typeof DocumentHistoryStore.actionCreatorsHistory &
RouteComponentProps<{}>;

class DocumentHistory extends React.PureComponent<DocumentHistoryProps> {


public componentDidMount(){
    this.getInitialDocumentHistory()
}

private getInitialDocumentHistory(){
    if(this.props.documentStore && this.props.auth?.SelectedCustomer){
        if(this.props.documentHistory?.isRedirect == false)
        {
            const docQuery:DocumentQuery = {
                storeId: this.props.documentStore.documentStores[0].id,
                filters: [],
                sort: {field: "submittedDate", ascDesc: ascendingDescending.descending},
                pageSize: 18,
                pageNumber: 1,
                excludeDeleted: false
          }
        
          this.props.getDocumentStoreDropdown();
          this.props.getData(this.props.auth?.SelectedCustomer?.id, docQuery);
        }
        }
      
}

public componentDidUpdate(prevProps: ApplicationState) {
    if(prevProps.documentStore?.documentStores !== this.props.documentStore?.documentStores && prevProps.documentHistory?.isRedirect === this.props.documentHistory?.isRedirect )
    {
        this.getInitialDocumentHistory()
    }
}

public componentWillUnmount() {
    if(this.props.documentStore && this.props.auth?.SelectedCustomer){
        if(this.props.documentHistory?.isRedirect == false)
        {
            const docQuery:DocumentQuery = {
                storeId: this.props.documentStore.documentStores[0].id,
                filters: [],
                sort: {field: "submittedDate", ascDesc: ascendingDescending.descending},
                pageSize: 18,
                pageNumber: 1,
                excludeDeleted: false
          }
        
          this.props.getDocumentStoreDropdown();
          this.props.getData(this.props.auth?.SelectedCustomer?.id, docQuery);
        }
        }
}

public render(){
return(
    <React.Fragment>
        <DocumentHistoryTable />
    </React.Fragment>
)
}
};

export default connect(
    (state: ApplicationState) => state,
    DocumentHistoryStore.actionCreatorsHistory,
   )(DocumentHistory as any);
