import * as React from 'react';
import { Modal, PrimaryButton, IButtonStyles, IconButton, IIconProps, Stack, IStackTokens, TextField, Toggle, Spinner, SpinnerSize } from '@fluentui/react';
import { getDocumentStoreMetadataExclusions, resubmitDocument, updateDocumentMetadata } from '../../utilities/axiosUtility';
import { Customer } from '../../interfaces/customer'
import { getMetadataForDocumentStoreById, updateMetadataForDocumentStore, getDocumentByCustomerAndDocumentId } from '../../utilities/axiosUtility';
import { DocumentMetaData } from '../../interfaces/metadata';
import { SelectedDocumentDetails } from './DocumentHistoryTable';
import _, { update } from 'lodash';
import { toast } from 'react-toastify';

export interface documentHistoryMetadataState {

}

const RedButtonStyle: IButtonStyles = {
  root: {
    backgroundColor: "#DC1A1A",
    borderColor: "#DC1A1A"
  },
  rootPressed: {
    backgroundColor: "#DC1A1A",
    borderColor: "#DC1A1A"
  },
  rootHovered: {
    backgroundColor: "#CB1818",
    borderColor: "#CB1818"
  }
}


const cancelIcon: IIconProps = { iconName: 'Cancel' };

const containerStackTokens: IStackTokens = { childrenGap: 10, padding: 20 };
const modalInfoStackTokens: IStackTokens = { childrenGap: 10, padding: 5 };

interface disabledValid {
  disabled: boolean;
  valid: boolean
}

interface IMetadataFieldsDisabled {
  file_name: boolean;
  Title: boolean;
  TenancyRef: boolean;
  FullName: boolean;
  CommSubCode: boolean;
  CommReference: boolean;
  EDRMUATDocDescription: boolean;
  FileLeafRef: boolean;
  SubscriptionKey: boolean;
}

interface IMetadataFieldsValid {
  file_name: boolean;
  Title: boolean;
  TenancyRef: boolean;
  FullName: boolean;
  CommSubCode: boolean;
  CommReference: boolean;
  EDRMUATDocDescription: boolean;
  FileLeafRef: boolean;
  SubscriptionKey: boolean;
}

interface IMetadataDetails {
  APIVaultId: string;
  ControlTerm: string;
  Filter: string;
  ModelId: string;
  ModelInfo: IModelInfo;
  file_name: string;
}

interface IModelInfo {
  ModelId: string;
  JoinFieldReference: string;
  ProcessTables: boolean
}

//Examples
const baseMetadataDetails: DocumentMetaData = {
  file_name: "",
  Title: "",
  TenancyRef: "",
  FullName: "",
  CommSubCode: "",
  CommReference: "",
  EDRMUATDocDescription: "",
  FileLeafRef: "",
  SubscriptionKey: "",
}

const defaultDisabledValid: disabledValid = {
  disabled: false,
  valid: true
}

const baseMetadataFieldsDisabled: IMetadataFieldsDisabled = {
  file_name: false,
  Title: false,
  TenancyRef: false,
  FullName: false,
  CommSubCode: false,
  CommReference: false,
  EDRMUATDocDescription: false,
  FileLeafRef: false,
  SubscriptionKey: false,
}

const baseMetadataFieldsValid: IMetadataFieldsValid = {
  file_name: true,
  Title: true,
  TenancyRef: true,
  FullName: true,
  CommSubCode: true,
  CommReference: true,
  EDRMUATDocDescription: true,
  FileLeafRef: true,
  SubscriptionKey: true,
}


//Displays metadata fields of a history record which allows editing
const DocumentHistoryMetadataModal = (props: { isOpen: boolean, customer: Customer | null, dismissModal: any, documentDetails: SelectedDocumentDetails, documentResubmitted: any, }) => {

  const [isOpen, setIsOpen] = React.useState(false)
  const [metadataDetails, setMetadataDetails] = React.useState<any>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [fieldList, setFieldList] = React.useState<any[]>([]);

  React.useEffect(() => {
    setIsOpen(props.isOpen);
    if (props.isOpen === true) {
      if (props.documentDetails.documentId > 0)
        getCurrentDocumentDetails()
    }
    else if (props.isOpen === false) {
      setFieldList([])
      setMetadataDetails({});
      setLoading(true);
    }
  }, [props.isOpen])

  const getCurrentDocumentDetails = async () => {
    if (props.customer) {
      const documentResponse: any = await getDocumentByCustomerAndDocumentId(props.customer.id, props.documentDetails.documentId)
      if (documentResponse.status === 200) {
        const exclusionsResponse = await checkMetadataFieldExclusions()
        setUpFields(exclusionsResponse, documentResponse.data.metadata)
      }
    }
  }

  const checkMetadataFieldExclusions = async () => {
    if (props.customer) {
      const metaExclusionsResponse = await getDocumentStoreMetadataExclusions(props.customer.id, props.documentDetails.storeId)
      if (metaExclusionsResponse.status === 200) {
        return metaExclusionsResponse.data
      }
      else {
        return []
      }
    }
    else {
      return []
    }
  }

  const _onDismiss = () => {
    setIsOpen(false)
  }

  const onTextFieldChange = (nValue: string | undefined, field: string) => {
    let currentMetaValues = _.clone(metadataDetails)
    let property: keyof typeof metadataDetails;
    for (property in metadataDetails) {
      if (property === field) {
        currentMetaValues[property] = nValue;
      }
    }
    setMetadataDetails(currentMetaValues)
  }

  //validates metadata text fields
  const validateTextFields = () => {
    let valid = true;
    let tempFieldList = _.clone(fieldList)
    let property: keyof typeof metadataDetails;
    let i = 0;
    for (property in metadataDetails) {
      if (metadataDetails[property] === "") {
        tempFieldList[i].valid = false;
        valid = false;
      }
      i++
    }

    setFieldList(tempFieldList);
    return valid;
  }

  const updateMetadataFields = async () => {
    if (props.customer) {
      // if(validateTextFields()){
      const metadata: any = {
        Metadata: metadataDetails
      }
      const updateMetadataFieldsResponse = await updateDocumentMetadata(props.customer.id, props.documentDetails.documentId, metadata);
      if (updateMetadataFieldsResponse.status === 200) {
        toast.success('Updated Metadata Record', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        props.dismissModal();
        props.documentResubmitted();
      }
      else {
        toast.error('Failed Updating Metadata Record', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

      }
      // }
      // else
      // {
      //   setTimeout(() => {
      //     let tempMetadataField = _.clone(fieldList);
      //     for (let field of fieldList){
      //       field.valid = true;
      //     }
      //     setFieldList(tempMetadataField);
      //   },6000)
      // }
    }
  }

  const setUpFields = (metadataExclusionsList: any[], metadataDetailsList: any) => {
    console.log(metadataExclusionsList, metadataDetailsList);
    if (Object.keys(metadataDetailsList).length > 0) {
      let property: keyof typeof metadataDetailsList;
      let fieldList = [];
      for (property in metadataDetailsList) {
        let disabled = false;
        for (let excludedKey of metadataExclusionsList) {
          if (excludedKey.metadatakey === property && excludedKey.isActive === true || excludedKey.metadatakey === "DisableAllEditing" && excludedKey.isActive === true) {
            disabled = true;
          }
        }
        const field = {
          fieldName: property.toString(),
          field: property,
          disabled: disabled,
          value: metadataDetailsList[property],
          valid: true
        }
        fieldList.push(field)
      }
      setMetadataDetails(metadataDetailsList);
      setFieldList(fieldList);
      setLoading(false);
    }
    else {
      toast.error('No Metadata Available', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      props.dismissModal();
    }
  }

  const renderFields = () => {
    return (
      <div>
        {fieldList.map(field => {
          return <TextField onChange={(e, v) => onTextFieldChange(v, field.fieldName)} errorMessage={field.valid ? "" : "Invalid Field"} disabled={field.disabled} label={field.fieldName} value={metadataDetails[field.field]} />
        })}
      </div>
    )
  }

  if (loading === true) {
    return (
      <div>

      </div>
    )
  }
  else {
    return (
      <Modal
        isOpen={isOpen}
        onDismiss={() => props.dismissModal()}
      >
        <div style={{ width: 600 }}>
          <IconButton
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={() => props.dismissModal()}
          />
          <Stack tokens={containerStackTokens}>
            {fieldList.map(field => {
              return <TextField onChange={(e, v) => onTextFieldChange(v, field.fieldName)} errorMessage={field.valid ? "" : "Invalid Field"} disabled={field.disabled} label={field.fieldName} value={metadataDetails[field.fieldName]} />
            })}
            <Stack horizontal horizontalAlign="end" tokens={modalInfoStackTokens}>
              <PrimaryButton text="Save" onClick={() => updateMetadataFields()} />
              <PrimaryButton styles={RedButtonStyle} text="Cancel" onClick={() => props.dismissModal()} />
            </Stack>
          </Stack>
        </div>
      </Modal>
    )
  }
}

export default DocumentHistoryMetadataModal



      // for( let field in metadataDetails){
      //   if(field.length > 0)
      //   {
      //     valid = true
      //   }
      //   else
      //   {
      //     switch(field){
      //       case "file_name": tempMetadataField.file_name.valid = false;
      //       break;
      //       case "Title": tempMetadataField.Title.valid = false;
      //       break;
      //       case "TenancyRef": tempMetadataField.TenancyRef.valid = false;
      //       break;
      //       case "FullName": tempMetadataField.FullName.valid = false;
      //       break;
      //       case "CommSubCode": tempMetadataField.CommSubCode.valid = false;
      //       break;
      //       case "CommReference": tempMetadataField.CommReference.valid = false;
      //       break;
      //       case "EDRMUATDocDescription": tempMetadataField.EDRMUATDocDescription.valid = false;
      //       break;
      //       case "FileLeafRef": tempMetadataField.FileLeafRef.valid = false;
      //       break;
      //       case "SubscriptionKey": tempMetadataField.SubscriptionKey.valid = false;
      //       break;
      //     }
      //   }
      // }