import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { IButtonStyles, PrimaryButton } from "@fluentui/react";

const authButtonStyles: IButtonStyles = {
    root: {backgroundColor: "rgb(96,16,105)", borderColor: ""},
    rootHovered: {backgroundColor: "rgb(126,22,138)", borderColor: ""}
}

/**
 * Renders a sign-in button
 */
export const SignInButton = () => {
    const { instance } = useMsal();

    const handleSignIn = () => {
            window.location.href = "/"
            instance.loginRedirect(loginRequest);
    }

    
    return (
        <div style={{display: 'flex' }}>
            <PrimaryButton styles={authButtonStyles}  onClick={handleSignIn} text="Sign In" label="Sign In" />
        </div>
    )
}

/**
 * Renders a sign-out button
 */
export const SignOutButton = () => {
    const { instance } = useMsal();

    const handleSignIn = () => {
        instance.logoutRedirect({
            postLogoutRedirectUri: "/",
        });
    }

    
    return (
        <div style={{display: 'flex' }}>
            <PrimaryButton styles={authButtonStyles}  onClick={handleSignIn} text="Sign Out" label="Sign Out" />
        </div>
    )
}