import * as React from 'react';
import TopBar from './TopBar';



const Layout = (props: {children?: React.ReactNode}) => (
        <React.Fragment>
            <TopBar/>
            <div>
                {props.children}
            </div>
        </React.Fragment>
)

export default Layout