import * as React from 'react';
import {
 Route
} from 'react-router';
import ApiRequestor from './pages/ApiRequestor';
import Home from './pages/Home'
import AccessDenied from './pages/AccessDenied';
import CustomerRouter from './components/Layouts/CustomerRouter';
import './App.css';
import Layout from './components/Layouts/Layout';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { getAccessToken, getTokenExpiry, loginRequest } from './authConfig';

export default function App() {
    const { instance, accounts } = useMsal();
  
    const isAuthenticated = useIsAuthenticated();

    if(isAuthenticated === false)
    {
        instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
        .then((response) => {
          getAccessToken(response.accessToken);
          getTokenExpiry(response.expiresOn? response.expiresOn : new Date());
        }).catch((error) => {
            console.log(error);
            instance.loginRedirect(loginRequest).then(loginResponse => {
            }).catch(e => {
                console.log(e);
            });
            localStorage.setItem("Portal_Token_Recieved", "false")
        })
    }

  return (
    <div>
    <Layout>
            <Route exact path='/' component={Home} />
            <Route path='/customer' component={CustomerRouter} />
            <Route path='/accessdenied' component={AccessDenied} />
            <Route exact path='/apitest' component={ApiRequestor} />
    </Layout>
    </div>
  );


};

