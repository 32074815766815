import * as React from 'react';
import {PrimaryButton} from '@fluentui/react'
import { RouteComponentProps } from 'react-router-dom';
import {connect} from 'react-redux';
import { ApplicationState } from '../store';
import * as AuthStore from '../store/Auth';

type AuthProps = 
  AuthStore.authState &
  typeof AuthStore.actionCreators &
  RouteComponentProps<{}>;

  class Home extends React.PureComponent<AuthProps> {

   
    public render(){
      return (
          <React.Fragment>
              <div style={{padding: 30}}>
              <h1>Welcome to TSG DOC-CONNECT</h1>
              {/* <div>Current Permissions: {this.props.CurrentUserLevels.map((userLevel, id) => (<div key={id}>{userLevel}</div>))}</div> */}
              <div>
                      This portal helps you manage the document routing flows and view history and audit logs for documents that were submitted for routing to this system.
                      Please use the customer selection drop down above to select the customer you would like to work with.

              </div>
              {/* <PrimaryButton onClick={() => {this.props.setLoggedIn(!this.props.isLoggedIn)}} text="auth"/> */}
              </div>
          </React.Fragment>
      )}
  }

export default connect(
 (state: ApplicationState) => state.auth,
 AuthStore.actionCreators 
)(Home as any);