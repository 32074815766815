import * as React from 'react';
import { TextField } from '@fluentui/react/lib/TextField';
import { Toggle } from '@fluentui/react/lib/Toggle';
import { DetailsList, Selection, SelectionMode, IColumn } from '@fluentui/react/lib/DetailsList';
import { CommandBarButton, DefaultButton, DefaultEffects, IconButton, IDetailsListStyles, IListProps, Label, Modal, PrimaryButton, Stack } from '@fluentui/react';
import {createSubscription, getAllSubscriptionsByDocumentStore, updateSubscription} from '../../../utilities/axiosUtility';
import _ from 'lodash';
import { toast } from 'react-toastify';


const detailListStyle: IDetailsListStyles = {
    root: { 
        width: '45vw'
      },
    contentWrapper: {
        width: '45vw'

    },
    focusZone: {},
    headerWrapper: {
        width: '45vw'
    }
}

export interface ISubscriptionClientDetails {
    Tenant: string;
    ClientId: string;
    ClientSecret: string;
    RoutingBaseAddress: string;
    DocConnectScope: string;
  }
  
  export interface ISubscriptionGraphDetails {
    Tenant: string;
    GraphClientId: string;
    GraphClientSecret: string;
    DefaultScope: string;
    SiteCollectionUrl: string;
  }
  
  export interface ISubscriptionRegistrationData {
    AuthenticateEndPoint: boolean;
    ClientDetails: ISubscriptionClientDetails;
    GraphDetails: ISubscriptionGraphDetails;
  }
  
  export interface ISubscriptionData {
    Id: number;
    CallbackUrl: string;
    SubscriptionKey: string;
    RegistrationData: ISubscriptionRegistrationData;
  }
  

  let defaultSub: ISubscriptionData = {
    Id: 0,
    SubscriptionKey: "",
    CallbackUrl: "",
    RegistrationData: {
      AuthenticateEndPoint: false,
      ClientDetails: {
        Tenant: "",
        ClientId: "",
        ClientSecret: "",
        RoutingBaseAddress: "",
        DocConnectScope: ""
      },
      GraphDetails: {
        Tenant: "",
        GraphClientId: "",
        GraphClientSecret: "",
        DefaultScope: "",
        SiteCollectionUrl: ""
      }
    }
  }

  

export interface ISubscriptionsTabState {
    columns: IColumn[];
    currentlySelected: any|undefined;
    subscriptionList: ISubscriptionData[];
    tempSubscription: any;
    fullSubscriptionList: any[];
    newSubModalOpen: boolean;
    editSubModalOpen: boolean;
    newEdit: boolean;
    saveUpdateDisabled: boolean;
}

export interface ISubscriptionsProps {
    customerId: number;
    storeId: number;
}

export class SubscriptionsTab extends React.Component<ISubscriptionsProps, ISubscriptionsTabState> {
    private _subscriptionSelection: Selection;

    constructor(props: ISubscriptionsProps){
        super(props)

        
        const defaultColumns: IColumn[] = [
            {
                key: 'column1',
                name: 'Subscription Key',
                fieldName: 'subscriptionKey',
                minWidth: 200,
                maxWidth: 200,
                isRowHeader: true,
                isResizable: false,
                isSorted: false,
                isSortedDescending: false,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column2',
                name: 'Subscription ID',
                fieldName: 'subscriptionId',
                minWidth: 300,
                maxWidth: 300,
                isRowHeader: true,
                isResizable: false,
                isSorted: false,
                isSortedDescending: false,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column5',
                name: 'Created Date',
                fieldName: 'createdDate',
                minWidth: 150,
                maxWidth: 150,
                isRowHeader: true,
                isResizable: false,
                isSorted: false,
                isSortedDescending: false,
                data: 'string',
                isPadded: true,
                onRender: (item) => {return (convertDate(item.createdDate))}
            },
            ]

        this.state = {
            currentlySelected: undefined,
            subscriptionList: [],
            fullSubscriptionList: [],
            columns: defaultColumns,
            newSubModalOpen: false,
            editSubModalOpen: false,
            newEdit: false,
            tempSubscription: defaultSub,
            saveUpdateDisabled: true
        }

        this._subscriptionSelection = new Selection({
            onSelectionChanged: () => {
                const selected = this._subscriptionSelection.getSelection()[0]
                if(selected){
                let cSelected: any = selected;
                const cSub: ISubscriptionData = {
                    Id: cSelected.id,
                    CallbackUrl: cSelected.callbackUrl,
                    SubscriptionKey: cSelected.subscriptionKey,
                    RegistrationData: JSON.parse(cSelected.registrationData)
                }
                this.setState({
                    currentlySelected: cSub,
                });
                }
            },
          });
    }

    public componentDidMount(): void {
        this.getSubscriptions(this.props.customerId, this.props.storeId)
    }

    //gets All subscriptions for current document store and creates a subscription array which is then set in subscriptionList state.
    private getSubscriptions = async(customerId: number, storeId: number) => {
        try{
        const subscriptionResponse = await getAllSubscriptionsByDocumentStore(customerId, storeId);
        let subscriptions: any[] = [];
        if(subscriptionResponse.data.length > 0)
        {
            for(let subscription of subscriptionResponse.data)
            {
                const nSubscription: ISubscriptionData = {
                    Id: subscription.id,
                    CallbackUrl: subscription.callbackUrl,
                    SubscriptionKey: subscription.subscriptionKey,
                    RegistrationData: JSON.parse(subscription.registrationData)
                }

                subscriptions.push(nSubscription)
            }

            this.setState({fullSubscriptionList: subscriptionResponse.data, subscriptionList: subscriptions});

        }

        }
        catch
        {
            console.log("error getting subscriptions")
        }
    }

    private toggleNewSubModal = () => {
        this.setState({newSubModalOpen: !this.state.newSubModalOpen, tempSubscription: defaultSub})
    }

    private subscriptionModalActionComplete = () => {
        this.toggleNewSubModal();
        this.getSubscriptions(this.props.customerId, this.props.storeId);
        this.setState({currentlySelected: 0})
    }

    private toggleEditSubModal = () => {
        if(this.state.newSubModalOpen === true)
        {
            this.setState({tempSubscription: defaultSub})
        }
        else
        {
            this.setState({tempSubscription: this.state.currentlySelected})
        }
        this.setState({newSubModalOpen: !this.state.newSubModalOpen})
    }

    private onToggleAuthenticateEndPointFlag = (ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
        let _subDetails = _.cloneDeep(this.state.tempSubscription)
        _subDetails.RegistrationData.AuthenticateEndPoint = checked? checked: false;
        this._checkValidField(_subDetails);
    }

    private onChangeTextField(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,type: string, newValue?: string ) {
        let _subDetails = _.cloneDeep(this.state.tempSubscription)
        switch(type){
            case "SubscriptionKey": _subDetails.SubscriptionKey = newValue?  newValue: "";
            break;
            case "CallbackUrl": _subDetails.CallbackUrl = newValue? newValue: "";
            break;
            case "ClientTenant": _subDetails.RegistrationData.ClientDetails.Tenant = newValue? newValue: "";
            break;
            case "ClientId": _subDetails.RegistrationData.ClientDetails.ClientId = newValue? newValue: "";
            break;
            case "ClientSecret": _subDetails.RegistrationData.ClientDetails.ClientSecret = newValue? newValue: "";
            break;
            case "RoutingBaseAddress": _subDetails.RegistrationData.ClientDetails.RoutingBaseAddress = newValue? newValue: "";
            break;
            case "DocConnectScope": _subDetails.RegistrationData.ClientDetails.DocConnectScope = newValue? newValue: "";
            break;
            case "GraphTenant": _subDetails.RegistrationData.GraphDetails.Tenant = newValue? newValue: "";
            break;
            case "GraphClientId": _subDetails.RegistrationData.GraphDetails.GraphClientId = newValue? newValue: "";
            break;
            case "GraphClientSecret": _subDetails.RegistrationData.GraphDetails.GraphClientSecret = newValue? newValue: "";
            break;
            case "DefaultScope": _subDetails.RegistrationData.GraphDetails.DefaultScope = newValue? newValue: "";
            break;
            case "SiteCollectionUrl": _subDetails.RegistrationData.GraphDetails.SiteCollectionUrl = newValue? newValue: "";
            break;
        }

        this._checkValidField(_subDetails)
        // if(_editDetails !== editStoreDetails)
        // _checkValidFields(_editDetails)
      }

    private _checkValidField = (_subDetails:any) => {
        let fieldsVaild = true;

        if(_subDetails.SubscriptionKey.length <  1)
        {
            fieldsVaild = false;
        }
        if(_subDetails.CallbackUrl.length < 1)
        {
            fieldsVaild = false;
        }
        if(_subDetails.RegistrationData.ClientDetails.Tenant < 1)
        {
            fieldsVaild = false;
        }
        if(_subDetails.RegistrationData.ClientDetails.ClientId < 1)
        {
            fieldsVaild = false;
        }
        if(_subDetails.RegistrationData.ClientDetails.ClientSecret < 1)
        {
            fieldsVaild = false;
        }
        if(_subDetails.RegistrationData.ClientDetails.DocConnectScope < 1)
        {
            fieldsVaild = false;
        }
        if(_subDetails.RegistrationData.GraphDetails.Tenant < 1)
        {
            fieldsVaild = false;
        }
        if(_subDetails.RegistrationData.GraphDetails.GraphClientId < 1)
        {
            fieldsVaild = false;
        }
        if(_subDetails.RegistrationData.GraphDetails.GraphClientSecret < 1)
        {
            fieldsVaild = false;
        }
        if(_subDetails.RegistrationData.GraphDetails.DefaultScope < 1)
        {
            fieldsVaild = false;
        }
        if(_subDetails.RegistrationData.GraphDetails.SiteCollectionUrl < 1)
        {
            fieldsVaild = false;
        }

        this.setState({saveUpdateDisabled: !fieldsVaild, tempSubscription: _subDetails})
    }

    private renderSubscription = (currentlySelected: any) => {
        return (
            <div style={{width: '45vw', paddingTop: "5vh"}}>
            <div style={{boxShadow: DefaultEffects.elevation8}}>
                            <Stack.Item styles={{root: {textAlign: 'right'}}} align='end'>
                            <IconButton
                                iconProps={{iconName: 'Edit'}}
                                ariaLabel="Edit Subscription"
                                onClick={this.toggleEditSubModal}
                                />
                            </Stack.Item>
                <Stack tokens={{childrenGap: 20}} styles={{root: {padding: 10}}}>
                    
                            <Stack.Item>
                                <TextField label='Subscription Key' value={currentlySelected.SubscriptionKey}  disabled={true}/>
                            </Stack.Item>
                            <Stack.Item>
                                <TextField label='Callback URL' value={currentlySelected.CallbackUrl} disabled={true}/>
                            </Stack.Item>
                            <Stack horizontalAlign='space-between' horizontal>
                                <Stack styles={{root: {minWidth: "20vw"}}} tokens={{childrenGap: 10}}>
                                    <Label style={{fontSize: 18 }}>Client Details</Label>
                                    <TextField label='Tenant' value={currentlySelected.RegistrationData.ClientDetails.Tenant}  disabled={true}/>
                                    <TextField label='Client ID' value={currentlySelected.RegistrationData.ClientDetails.ClientId} disabled={true}/>
                                    <TextField label='Client Secret' value={currentlySelected.RegistrationData.ClientDetails.ClientSecret} disabled={true}/>
                                    <TextField label='Routing Base Address' value={currentlySelected.RegistrationData.ClientDetails.RoutingBaseAddress} disabled={true}/>
                                    <TextField label='Doc Connect Scope' value={currentlySelected.RegistrationData.ClientDetails.DocConnectScope} disabled={true}/>
                                </Stack>
                                <Stack styles={{root: {minWidth: "20vw"}}} tokens={{childrenGap: 10}}>
                                    <Label style={{fontSize: 18 }}>Graph Details</Label>
                                    <TextField label='Tenant' value={currentlySelected.RegistrationData.GraphDetails.Tenant} disabled={true}/>
                                    <TextField label='Graph Client ID' value={currentlySelected.RegistrationData.GraphDetails.GraphClientId} disabled={true}/>
                                    <TextField label='Graph Client Secret' value={currentlySelected.RegistrationData.GraphDetails.GraphClientSecret} disabled={true}/>
                                    <TextField label='Default Scope' value={currentlySelected.RegistrationData.GraphDetails.DefaultScope} disabled={true}/>
                                    <TextField label='Site Collection URL' value={currentlySelected.RegistrationData.GraphDetails.SiteCollectionUrl} disabled={true}/>
                                </Stack>
                            </Stack>
                    </Stack>
            </div>
        </div>
        )
    }

    private createUpdateSubscription = async() => {
        let _subDetails = _.cloneDeep(this.state.tempSubscription)
        if(_subDetails.Id && _subDetails.Id > 0)
        {
         updateSubscription(this.props.customerId, this.props.storeId, _subDetails.Id, _subDetails).then((response => {
                toast.success('Subscription Successfully updated', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
                    this.subscriptionModalActionComplete();
            })).catch(error => {
                toast.error('Error Updating Subscription', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
            })
        }
        else
        {
            const nSubDetails = {
                CallbackUrl: _subDetails.CallbackUrl,
                SubscriptionKey: _subDetails.SubscriptionKey,
                RegistrationData: _subDetails.RegistrationData
            }

            createSubscription(this.props.customerId, this.props.storeId, nSubDetails).then(response => {
                toast.success('Subscription Created', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
                    this.subscriptionModalActionComplete();
            }).catch(error => {
                toast.error('Error Creating Subscription', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
            })
        }
        this._subscriptionSelection.setItems([]);

    }


    public render(){
        const {currentlySelected, subscriptionList, columns, newSubModalOpen, fullSubscriptionList, tempSubscription, saveUpdateDisabled} = this.state;

        const detailListProps: IListProps = {
            style: {
                height: "75vh",
                overflowY: 'auto'
            }
        }
    
        return (
            <Stack tokens={{childrenGap: 20}} styles={{root: {padding: 10, maxHeight: "80vh"}}}>
                <Stack horizontal tokens={{childrenGap: 40}}>
                <Stack tokens={{childrenGap: 10}} >
                    <Stack.Item style={{height: 30}}><CommandBarButton style={{height: 40}} iconProps={{iconName: "Add"}} text="Add Subscription" onClick={() => this.toggleNewSubModal()}  /></Stack.Item>
                    {subscriptionList.length > 0 && (<DetailsList listProps={detailListProps}  styles={detailListStyle} selectionMode={SelectionMode.single} columns={columns} selection={this._subscriptionSelection} items={fullSubscriptionList} />)}
                </Stack>
                {currentlySelected && (
                    this.renderSubscription(currentlySelected)
                )}
                </Stack>
                <Modal
                isOpen={newSubModalOpen}
                onDismiss={this.toggleNewSubModal}
                >
                    <Stack tokens={{childrenGap: 20}} styles={{root: {padding: 20, minWidth: 800}}}>
                            <Stack.Item>
                                <TextField label='Subscription Key' value={tempSubscription.SubscriptionKey} onChange={(ev, value) => this.onChangeTextField(ev, "SubscriptionKey", value)}/>
                            </Stack.Item>
                            <Stack horizontal tokens={{childrenGap: 20}}>
                                <TextField styles={{root: {width: "70%"}}} label='Callback URL' value={tempSubscription.CallbackUrl} onChange={(ev, value) => this.onChangeTextField(ev, "CallbackUrl", value)} />
                                <Toggle label="Authenticate End Point" onChange={this.onToggleAuthenticateEndPointFlag} checked={tempSubscription.RegistrationData.AuthenticateEndPoint}/>
                            </Stack>
                            <Stack horizontalAlign='space-between' horizontal>
                                <Stack styles={{root: {minWidth: 360}}} tokens={{childrenGap: 10}}>
                                    <Label style={{fontSize: 18 }}>Client Details</Label>
                                    <TextField label='Tenant' value={tempSubscription.RegistrationData.ClientDetails.Tenant} onChange={(ev, value) => this.onChangeTextField(ev, "ClientTenant", value)}/>
                                    <TextField label='Client ID' value={tempSubscription.RegistrationData.ClientDetails.ClientId} onChange={(ev, value) => this.onChangeTextField(ev, "ClientId", value)}/>
                                    <TextField label='Client Secret' value={tempSubscription.RegistrationData.ClientDetails.ClientSecret} onChange={(ev, value) => this.onChangeTextField(ev, "ClientSecret", value)} />
                                    <TextField label='Routing Base Address' value={tempSubscription.RegistrationData.ClientDetails.RoutingBaseAddress} onChange={(ev, value) => this.onChangeTextField(ev, "RoutingBaseAddress", value)}/>
                                    <TextField label='Doc Connect Scope' value={tempSubscription.RegistrationData.ClientDetails.DocConnectScope} onChange={(ev, value) => this.onChangeTextField(ev, "DocConnectScope", value)}/>
                                </Stack>
                                <Stack styles={{root: {minWidth: 360}}} tokens={{childrenGap: 10}}>
                                    <Label style={{fontSize: 18 }}>Graph Details</Label>
                                    <TextField label='Tenant' value={tempSubscription.RegistrationData.GraphDetails.Tenant} onChange={(ev, value) => this.onChangeTextField(ev, "GraphTenant", value)}/>
                                    <TextField label='Graph Client ID' value={tempSubscription.RegistrationData.GraphDetails.GraphClientId} onChange={(ev, value) => this.onChangeTextField(ev, "GraphClientId", value)}/>
                                    <TextField label='Graph Client Secret' value={tempSubscription.RegistrationData.GraphDetails.GraphClientSecret} onChange={(ev, value) => this.onChangeTextField(ev, "GraphClientSecret", value)}/>
                                    <TextField label='Default Scope' value={tempSubscription.RegistrationData.GraphDetails.DefaultScope} onChange={(ev, value) => this.onChangeTextField(ev, "DefaultScope", value)}/>
                                    <TextField label='Site Collection URL' value={tempSubscription.RegistrationData.GraphDetails.SiteCollectionUrl} onChange={(ev, value) => this.onChangeTextField(ev, "SiteCollectionUrl", value)}/>
                                </Stack>
                            </Stack>
                            <Stack horizontal horizontalAlign='end' tokens={{childrenGap: 10}}>
                                <PrimaryButton disabled={saveUpdateDisabled} text="Save" onClick={this.createUpdateSubscription}/>
                                <DefaultButton text="Cancel" onClick={this.toggleNewSubModal}/>
                            </Stack>
                    </Stack>
                </Modal>
            </Stack>
        )

      }


}

export default SubscriptionsTab

const convertDate = (date: string) => {
    let cDate = new Date(date)
    let formatted_date =  cDate.getFullYear() + "-" + (cDate.getMonth() + 1) + "-" + cDate.getDate();
    return formatted_date
  }