import * as React from 'react';
import {StorageMetricList } from '../interfaces/storageMetrics';
import QuotaChart from '../components/Dashboard/QuotaChart';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { RouteComponentProps } from 'react-router-dom';
import {connect} from 'react-redux';
import { ApplicationState } from '../store';
import * as AuthStore from '../store/Auth';
import * as DocumentDetailStore from '../store/DocumentStore';
import {MotionAnimations} from '@fluentui/theme/lib/motion/FluentMotion';
import { DocumentStoreQuota } from '../interfaces/documentStore';


//Set Props to current redux state using ApplicationState/Authstore.
type DashboardProps = 
ApplicationState &
typeof AuthStore.actionCreators &
RouteComponentProps<{}>;

class Dashboard extends React.PureComponent<DashboardProps> {

    constructor(props: any){
        super(props)
    }

    //Render function to loop through documentStoresQuota array and returns QuotaChart components. 
    private renderQuotaChart() {
        if(this.props.documentStore)
            return this.props.documentStore.documentStoresQuota.map((store: DocumentStoreQuota, storeArrayId: number)  => {
                return (
                    <div key={storeArrayId} style={{padding: 10}}>
                        <QuotaChart customerId={this.props.auth?.SelectedCustomer? this.props.auth.SelectedCustomer.id : 0} store={store} currentUserLevel={this.props.auth?.CurrentUserLevels? this.props.auth.CurrentUserLevels : 3} />
                    </div>
                        )
                    })          
    }

    public render(){
    return (
        <React.Fragment>
            <div style={{ display: 'flex', overflowX: "auto", overflowY: "hidden", height: "42rem", width: "98%", paddingBottom: "10%", animation: MotionAnimations.fadeIn }}>
                {this.renderQuotaChart()}
            </div>
        </React.Fragment>)
    }
};

//Export with connect to redux state
export default connect(
    (state: ApplicationState) => state,
    DocumentDetailStore.actionCreators 
   )(Dashboard as any);