import * as React from 'react';
import DocumentStoreTable from '../components/DocumentStore/DocumentStoreTable';
import { underConstructionPanel } from '../utilities/helpers';
import { ApplicationState } from '../store';
import { RouteComponentProps } from 'react-router-dom';
import * as DocumentStore from '../store/DocumentStore';
import DocumentStoreEditView from '../components/DocumentStore/DocumentStoreEditView';
import {connect} from 'react-redux';
import { DocumentStoreQuota } from '../interfaces/documentStore';



type DocumentStoresProps = 
ApplicationState & typeof DocumentStore.actionCreators &
RouteComponentProps<{}>;

class DocumentStoresPage extends React.PureComponent<DocumentStoresProps> {

    constructor(props: DocumentStoresProps){
        super(props)
    }

    public componentDidUpdate(prevProps: DocumentStoresProps){
        if(prevProps.documentStore && this.props.documentStore){
          if(prevProps.documentStore.documentStores !== this.props.documentStore.documentStores)
          {
            this.props.setEditMode(false);
          }
        }
    }

    private enableEditView = (selectedDocumentStore: DocumentStoreQuota) => {
        this.props.setEditMode(true)
        this.props.setSelectedDocumentStore(selectedDocumentStore);
    }
    
    private disableEditView = () => {
        this.props.setEditMode(false)
    }

    public componentWillUnmount(){
            this.props.setEditMode(false);
    }

    private getDocumentStores = () => {
        this.props.getDocumentStore(this.props.auth?.SelectedCustomer?.id ? this.props.auth.SelectedCustomer.id: 0)
    }

    private updateSelectedStore = () => {
        this.props.getDocumentStore(this.props.auth?.SelectedCustomer?.id ? this.props.auth.SelectedCustomer.id: 0)
        this.props.updateSelectedDocumentStore(this.props.documentStore? this.props.documentStore.selectedDocumentStore: undefined)
    }

    public render(){
    return(
    <React.Fragment>
        {!this.props.documentStore?.editModeEnabled ? <DocumentStoreTable updateDocumentStoreList={this.getDocumentStores.bind(this)}  currentUserLevels={this.props.auth?.CurrentUserLevels} selectedCustomerId={this.props.auth?.SelectedCustomer?.id} documentStores={this.props.documentStore? this.props.documentStore.documentStoresQuota : []} enableEdit={this.enableEditView.bind(this)}/> : <DocumentStoreEditView currentUserLevels={this.props.auth?.CurrentUserLevels} updateDocumentStoreList={this.updateSelectedStore.bind(this)} customerId={this.props.auth?.SelectedCustomer?.id} disableEdit={this.disableEditView.bind(this)} documentStoreDetails={this.props.documentStore.selectedDocumentStore}/>}
    </React.Fragment>
    )
    }
};

export default connect(
    (state: ApplicationState) => state,
    DocumentStore.actionCreators,
   )(DocumentStoresPage as any);

