import * as React from 'react'
import { Spinner, TextField, ITextFieldStyles, ComboBox, IComboBoxOption, IStyleSet, Label, ILabelStyles, IPivotItemProps, IPivotProps, Pivot, PivotItem } from '@fluentui/react'
import ReactJson from 'react-json-view';

const narrowTextField: Partial<ITextFieldStyles> = { fieldGroup: { width: 100 } };

type RequestConverterProps = {
    response: string,
    spinnerVisable: boolean
}

const RequestConverter = ({response, spinnerVisable} : RequestConverterProps ) => {

    const [responseData, setResponseData] = React.useState("")
    const [responseDataJson, setReponseDataJson] = React.useState({})
    const [responseStatus, setResponseStatus] = React.useState("")
    const [currentHeader, setCurrentHeader] = React.useState("Text")

    function setResponseText(item: PivotItem | undefined) {
        if(item !== undefined){
        const HeaderValue = item.props.headerText
        const JsonReponse = JSON.parse(response)
        if (HeaderValue == "JSON") {
            setReponseDataJson(JsonReponse);
        }
        else {
            setResponseData(JSON.stringify(JsonReponse.data))
        }
        setCurrentHeader(HeaderValue || "")
        }
    }


    React.useEffect(() => {
        if (response !== "") {
            const JsonReponse = JSON.parse(response)
            if (currentHeader !== "JSON") {
                setResponseData(JSON.stringify(JsonReponse.data))
                setResponseStatus(JsonReponse.status.toString())
            }
            else {
                setReponseDataJson(JsonReponse)
                setResponseStatus(JsonReponse.status.toString())
            }
        }
    },[response])

    return (
        <React.Fragment>
            
            <div style={{display: 'flex'}}>
                <TextField styles={narrowTextField} value={responseStatus} label="Response Status Code" />
                <div style={{ paddingTop: 40, paddingLeft: 40 }}>
                    {spinnerVisable == true && (<Spinner label="Sending Request" ariaLive="assertive" labelPosition="right" />)}
                </div>
            </div>
            <Pivot onLinkClick={setResponseText} aria-label="Response">
                <PivotItem headerText="TEXT">
                    <TextField style={{ height: 450, width: 500, fontSize: 14 }} disabled={true} value={responseData} multiline resizable={false} />
                </PivotItem>
                <PivotItem headerText="HTML">
                    <div style={{ height: 450, width: 500, fontSize: 14, overflow: 'auto' }} >
                        <div dangerouslySetInnerHTML={{ __html: responseData }}>
                        </div>
                    </div>
                </PivotItem>
                <PivotItem headerText="JSON">
                    <div style={{ overflow: 'auto', height: 450, width: 500 }}>
                        <ReactJson style={{ fontSize: 15 }} src={responseDataJson} />
                    </div>
                </PivotItem>
            </Pivot>
        </React.Fragment>
        )
}

export default RequestConverter