import React from "react";
import { useMsal } from "@azure/msal-react";

const signOutTextStyle: React.CSSProperties = {
    color: 'white',
    paddingTop: 10,
    fontSize: 18,
    fontWeight: 20,
    cursor: 'pointer'
}

/**
 * Renders a sign-out button
 */
export const SignOutButton = (props:{Username: string}) => {
    const { instance } = useMsal();

    const handleLogout = () => {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/",
            });
    }

    
    return (
        <div style={{display: 'flex' }}>
            <div style={{ paddingTop: 12, fontWeight: 20, color: 'white', paddingRight: 40 }}>Welcome {props.Username} </div> 
            <div onClick={handleLogout} style={signOutTextStyle}> Sign Out </div>
        </div>
    )
}