import * as React from 'react';
import { Nav, INavLinkGroup, INavLink, INavStyles, INavButtonProps } from '@fluentui/react/lib/Nav';
import { TooltipHost, ITooltipHostStyles } from '@fluentui/react/lib/Tooltip';

import {
    NavLink, withRouter
} from 'react-router-dom';
import { Icon } from '@fluentui/react';
import { RouteComponentProps } from 'react-router-dom';
import {connect} from 'react-redux';
import { ApplicationState } from '../../store';
import * as AuthStore from '../../store/Auth';
import _ from 'lodash';
import { MsalContext } from '@azure/msal-react';


const navStyles: Partial<INavStyles> = {
  root: {
    width: 60,
    height: "95%",
    position: 'fixed',
    top: 48,
    zIndex: 1,
    backgroundColor: 'rgb(242,242,242)',
    boxSizing: 'border-box',
    borderRight: '1px solid lightgrey',
    overflowY: 'auto',
  },
};

const calloutProps = { gapSpace: 0 };

const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };

const defaultNavLinkGroup: INavLinkGroup[] = [{links:[
    { name: 'Dashboard', url: '/customer/dashboard', key: 'key1', target: '_blank', icon: 'ViewDashboard' },
    { name: 'Document Stores', url: '/customer/documentstores', key: 'key2', target: '_blank', icon: 'Document' },   
    { name: 'History', url: '/customer/documenthistory', key: 'key3', target: '_blank', icon: 'History' },
    ]}]

interface NavmenuState {
    selectedKey: string;
    customerNavLinkGroups: INavLinkGroup[]
}

type AuthProps = 
  AuthStore.authState &
  typeof AuthStore.actionCreators &
  RouteComponentProps<{}>;

class NavMenu extends React.PureComponent<AuthProps> {
    
    public state = {
        selectedKey: "key1",
        customerNavLinkGroups: defaultNavLinkGroup
    };
    
    signOut() {
    
    }

    setUpNavItems(){
        var customerLinkGroup = this.state.customerNavLinkGroups;
        const currentPath = window.location.pathname;
       if(this.props.CurrentUserLevels <= 4)
       {
           var adminRoutes = [{links:[
            { name: 'Dashboard', url: '/customer/dashboard', key: 'key1', target: '_blank', icon: 'ViewDashboard' },
            { name: 'Document Stores', url: '/customer/documentstores', key: 'key2', target: '_blank', icon: 'Document' },   
            { name: 'History', url: '/customer/documenthistory', key: 'key3', target: '_blank', icon: 'History' },
            { name: 'Administration', url: '/customer/administration', key: 'key4', target: '_blank', icon: 'Admin' },]}]

           let newSelectedKey: string = ""
           for(let linkGroup of adminRoutes){
               for(let link of linkGroup.links){
                    if (currentPath == link.url && this.state.selectedKey !== link.key) {
                        this.setState({selectedKey: link.key})
                    }
                }
            }
           this.setState({customerNavLinkGroups: adminRoutes});
       }
       else
       {
           if(currentPath.includes('administration'))
           {
                this.props.history.push('/customer/dashboard')
                this.setState({selectedKey: 'key1', customerNavLinkGroups: defaultNavLinkGroup})
           }
           else
           {
                for(let linkGroup of customerLinkGroup){
                    for(let link of linkGroup.links){
                        if (currentPath == link.url && this.state.selectedKey !== link.key) {
                            this.setState({selectedKey: link.key})
                        }
                    }
                }
                this.setState({customerNavLinkGroups: defaultNavLinkGroup});
            }
       }
    }

    componentDidMount() {
        if(this.props.CurrentUserLevels){
            this.setUpNavItems()
        }
    }

    componentDidUpdate(prevprops: any){
        if(prevprops.CurrentUserLevels !== this.props.CurrentUserLevels)
        {
            this.setUpNavItems()
        }
        this._checkLinkSelected(this.state.selectedKey, this.state.customerNavLinkGroups)
    }

    _checkLinkSelected(selectedKey: string, navLinkGroups: INavLinkGroup[]){
        let alreadySelected = false;
        for (let navGroup of navLinkGroups){
            for(let link of navGroup.links){
                if(link.key !== selectedKey)
                {
                    if(window.location.pathname === link.url)
                    {
                        this.setState({selectedKey: link.key})
                    }
                }
            }
       }
    }

    _onRenderGroupHeader(group: INavLinkGroup): JSX.Element {
        return <h3>{group.name}</h3>;
    }

    _linkClicked = (key: string) => {
        this.setState({ selectedKey: key })
    }

    _onRenderLink = (props: any) => {
        return <NavLink className={props.className} onClick={() => this._linkClicked(props.link.key)} style={{ color: 'inherit', boxSizing: 'border-box' }} to={props.href}>
                    <TooltipHost
                        content={props.link.name}
                        // This id is used on the tooltip itself, not the host
                        // (so an element with this id only exists when the tooltip is shown)
                        calloutProps={calloutProps}
                        styles={hostStyles}
                    >
                        <span style={{ display: 'flex' }}>
                            {!!props.iconProps && <Icon style={{paddingLeft: 15, fontSize: 18}} {...props.iconProps} />}
                            {/*{props.children}*/}
                        </span>
                    </TooltipHost>
                </NavLink>;
    }
  
    public render() {
        return (
                <Nav
                    ariaLabel="Nav example with custom group headers"
                    selectedKey={this.state.selectedKey}
                    groups={this.state.customerNavLinkGroups}
                    styles={navStyles}
                    linkAs={this._onRenderLink}
              />
            );
        }
    }
    
    export default withRouter(connect(
        (state: ApplicationState) => state.auth,
        AuthStore.actionCreators 
    )(NavMenu as any));