import * as React from 'react';
import {
    Router,
    Switch,
    Route,
    useParams,
    useRouteMatch,
    Redirect,
    RouteComponentProps
} from "react-router";
import LayoutCustomer from './LayoutCustomer';
import Dashboard from '../../pages/Dashboard';
import DocumentStoresPage from '../../pages/DocumentStoresPage';
import DocumentHistory from '../../pages/DocumentHistory';
import Administration from '../../pages/Administration';


 export default () => {
   let {path, url} = useRouteMatch();
   return(
    <LayoutCustomer>
            <Redirect from={`${path}`} to={`${path}/dashboard`} />
            <Route  path={`${path}/dashboard`} component={Dashboard} />
            <Route  path={`${path}/documentstores`} component={DocumentStoresPage} />
            <Route  path={`${path}/documenthistory`} component={DocumentHistory}/>
            <Route  path={`${path}/administration`} component={Administration} />
    </LayoutCustomer>   
    )
}


