import { DocumentStoreMetadataField } from "./documentStoreMetadataField";
import {ConvertedStorageMetrics} from './storageMetrics';

export enum DocumentStoreFlags {
        //Defualt Value
        Default = 0,
        
        /// Specifies that the document store can be used as a source of documents
        UseAsSource = 1 << 0,

        /// Specifies that the document store can be used as a destination of documents
        UseAsDestination = 1 << 1,

        /// Specifies that this is a System document store
        System = 1 << 2,

        /// Specifies that the documents must not be deleted after move
        KeepDocumentsAfterMove = 1 << 4
}


/**
    * Represents a Document Store
 */
 export interface DocumentStore {

    /**
        * Specifies the unique ID of the Document Store
    */
      id: number;
  
    /**
        * Specifies the ExternalId of the Document Store
    */
      externalId: string;

      flags: DocumentStoreFlags;
  
    /**
        * Specifies the name of the Document Store
    */
      name: string;
  
    /**
        * Specifies the description of the Document Store
    */
      description: string;

    /**
        * Specifies the type id of the Document Store
    */
      typeId: string;
    
    /**
        * Specifies the settings object of the Document Store
    */
      settings: object;

    /**
        * Specifies if the Document Store is active
    */
      isActive: boolean;

    /**
        * Specifies who the Document Store was created by
    */
      createdBy: string;

    /**
        * Specifies the date the Document Store was created.
    */
      createdDate: string;

    /**
        * Specifies who the Document Store was modified by.
    */
      modifiedBy: string;
  
    /**
        * Specifies the modified date of the Document Store.
    */
      modifiedDate: Date;

    /**
        * Specifies the customer id of the Document Store.
    */
      customerId: number;
      
    /**
        * Specifies the metadata of the Document Store.
    */
      metadataSettings: any[];

  }

  export interface DocumentStoreQuota {

    /**
        * Specifies the unique ID of the Document Store
    */
      id: number;
  
    /**
        * Specifies the ExternalId of the Document Store
    */
      externalId: string;
  
    /**
        * Specifies the name of the Document Store
    */
      name: string;
  
    /**
        * Specifies the description of the Document Store
    */
      description: string;

    /**
        * Specifies the type id of the Document Store
    */
      typeId: string;
    
    /**
        * Specifies the settings object of the Document Store
    */
      settings: any;

    /**
        * Specifies if the Document Store is active
    */
      isActive: boolean;

    /**
        * Specifies who the Document Store was created by
    */
      createdBy: string;

    /**
        * Specifies the date the Document Store was created.
    */
      createdDate: string;

    /**
        * Specifies who the Document Store was modified by.
    */
      modifiedBy: string;
  
    /**
        * Specifies the modified date of the Document Store.
    */
      modifiedDate: Date;

    /**
        * Specifies the customer id of the Document Store.
    */
      customerId: number;
      
    /**
        * Specifies the metadata of the Document Store.
    */
      metadataSettings: any[];

              /**
        * Specifies the max size storage of the Storage Metric
    */
      maxStorageSize: number;
  
               /**
                   * Specifies the remaining storage of the Storage Metric
               */
      remainingStorageSize: number;
             
               /**
                   * Specifies the used storage of the Storage Metric
               */
      usedStorageSize: number;

      systemGenerated: boolean;

      flags: DocumentStoreFlags;
  
  }

  export interface metadataExclusions {
    metadatakey: string;
    storeId: string;
    isActive: boolean;
  }

 export interface createUpdateDocumentStoreRequest {
    id: number;
    name: string;
    description: string;
    typeId: string;
    settings: any;
    metadataSettings: any[];
    credentials: any;
    flags: any[];
  }
  
  export declare type metadataExclusionsList = metadataExclusions[];
  export declare type DocumentStores = DocumentStore[];
  export declare type DocumentStoresQouta = DocumentStoreQuota[];

  
  