import * as React from 'react';
import { Image, IImageProps, Stack, IStackStyles } from '@fluentui/react'
import { Dropdown, IDropdownStyles, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import logo from '../../Images/tsglogo.jpg'
import { getCurrentUserPermissions } from '../../utilities/axiosUtility';
import {Customer } from '../../interfaces/customer';
import { User } from '../../interfaces/user';
import { RouteComponentProps, withRouter } from "react-router";
import InitialLoadingMenu from '../InitialLoadingMenu';
import {connect} from 'react-redux';
import * as AuthStore from '../../store/Auth';
import * as DocumentDetailsStore from '../../store/DocumentStore';
import { ApplicationState } from '../../store';
import { SignOutButton } from './SignOutButton';
import axios from 'axios';
import { getAccessToken } from '../../authConfig';


const topNavBarStackStyle: IStackStyles = {
    root: {
        paddingLeft: 0,
        paddingRight: 10
    }
}

const imageProps: Partial<IImageProps> = {
    src: logo,
    styles: props => ({ root: {paddingLeft: 60 } }),
};

const topNavBarStyle: React.CSSProperties = {
    height: 48,
    borderBottom: '1px solid #eee',
    width: '100%',
    zIndex: 2,
    position: 'fixed',
    backgroundColor: 'rgb(96,16,105)',
}

const dropDownContainerStyle: React.CSSProperties = {
    float: 'right',
    paddingTop: 10,
    paddingRight: 20
}

const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 175 },
};

const textStyle: React.CSSProperties = {
    color: 'white',
    paddingTop: 15,
    paddingLeft: 25,
    fontSize: 15,
    fontWeight: 600,
}

export interface topBarState {
    user: User;
    selectedRequestType: IDropdownOption;
    customerDropdownList: IDropdownOption[];
    customersLoaded: boolean;
    timer: number;
    accessDenied: boolean;
}

type AuthProps = 
  ApplicationState &
  typeof AuthStore.actionCreators & typeof DocumentDetailsStore.actionCreators &
  RouteComponentProps<{}>;


  class TopBar extends React.PureComponent<AuthProps, topBarState> {

    constructor(props: any){
        super(props);
        this.state = {
            user: {
                id: "",
                loginName: "",
                name: "",
                emailAddress: "",
            },
            selectedRequestType: {key: '', text: ''},
            customerDropdownList: [],
            customersLoaded: false,
            timer: 6000000,
            accessDenied: false
        }
    }

    private onDropDownChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined): void => {
        if (item?.key) {
            if(this.props.auth && this.props.auth.Customers)
            for(let customer of this.props.auth.Customers){
                if (customer.id === parseInt(item.key.toString())) {
                    localStorage.setItem('customerIdSelected', item.key.toString());
                    this.setUpCustomer(customer);
                    this.setState({selectedRequestType: item})
                }
            }
        }
    }
    
    private setUpCustomer(customer: Customer){
        getCurrentUserPermissions(customer.id).then((response:any) => {
            // if(response.data.isActive === false)
            // {
            //     this.props.history.push('/_account/signout');
            // }
            const userLevel = response.data.level;
            this.props.updateUserLevels(userLevel);
            this.props.getDocumentStore(customer.id);
            this.props.setSelectedCustomer(customer);
            if(!window.location.pathname.includes('customer')){
                if(window.location.pathname.includes('apitest')){
                    
                }
                else
                {
                    this.props.history.push('/customer');
                }
           }
           this.setState({customersLoaded: true});
        }).catch(error => {
            console.log(error)
        })
    }



    private _mapCustomerList(customers: Customer[] | null) {
        if(customers){
            if(customers.length === 1){
                this.setUpCustomer(customers[0]);
            }
            else if (customers.length > 1)
            {

                var customerId = localStorage.getItem('customerIdSelected')
                if(this.props.auth)
                {
                    if(this.props.auth.DefaultCustomerId !== "0")
                    {
                        customerId = this.props.auth.DefaultCustomerId
                    }
                }
                
                var dropDownList: IDropdownOption[] = [];

                for(let customer of customers)
                {
                    var dropDownItem: IDropdownOption = {
                        key: customer.id.toString(),
                        text: customer.name
                    }
    
                    if(customerId !== null && customerId === customer.id.toString()){
                        if(window.location.pathname !== '/apitest')
                        {
                            this.setState({selectedRequestType: dropDownItem})
                            this.setUpCustomer(customer);
                        }
                    }

                    dropDownList.push(dropDownItem);
                }

                if(customerId === null)
                {
                    this.setState({customersLoaded: true});
                }
 
                this.setState({customerDropdownList: dropDownList})
            } 
        }
    }

    public componentDidUpdate(prevProps: AuthProps){
        if(this.props.auth?.Customers !== prevProps.auth?.Customers || this.props.auth?.DefaultCustomerId !== prevProps.auth?.DefaultCustomerId)
        {
            this._mapCustomerList(this.props.auth?.Customers? this.props.auth.Customers : null)
        }
    }

    public componentWillUnmount() {
    }

    public componentDidMount(){
        this.props.setTimedOutAmount(0);
        const tokenCheck = setInterval(() => {
        const timedOutAmount = this.props.auth? this.props.auth.TimedOutAmount : 0;
        if(this.props.auth?.isLoggedIn)
        {
            this.setState({accessDenied: false});
            this.props.setTimedOutAmount(0);
            clearInterval(tokenCheck)
        }
        else
        {
            if(timedOutAmount > 3)
            {
                this.props.setTimedOutAmount(0);
                this.setState({accessDenied: true});
                this.props.history.push('/accessdenied');
                clearInterval(tokenCheck) 
            }
            else
            {
                if(getAccessToken().length > 0){
                    this.props.checkLoggedIn();
                }
            }
        }
        },6000)

        return () => {clearInterval(tokenCheck) 
            this.props.setTimedOutAmount(0);
        };

    }

    public getCurrentTab() {
        let currentLocation = window.location.pathname;
        let slicedLocation = currentLocation.slice(10);
        switch(slicedLocation){
            case "dashboard": return "Dashboard";
            case "documentstores": return "Document Stores";
            case "documenthistory": return "Document History";
            case "administration": return "Administration";
            default: return "";
        }
    }

    public render(){
    const {accessDenied, customersLoaded, customerDropdownList, selectedRequestType} = this.state;
    return (
        <div style={topNavBarStyle}>
            {accessDenied ? 
             <Stack horizontal horizontalAlign="space-between" styles={topNavBarStackStyle} >
                <div style={{display: 'flex'}}>
                    <Image
                    {...imageProps}
                    width={140}
                    height={48}
                        />
                    <div style={textStyle}>DOC-CONNECT</div>
                </div>
            </Stack>
            : 
            customersLoaded === false ? <InitialLoadingMenu /> :
            <Stack horizontal horizontalAlign="space-between" styles={topNavBarStackStyle} >
                <div style={{display: 'flex'}}>
                    <Image
                    {...imageProps}
                    width={140}
                    height={48}
                        />
                    <div style={textStyle}>DOC-CONNECT</div>
                </div>
                <div style={textStyle}>{this.getCurrentTab()}</div>
                <div style={{ display: 'flex' }}>
                    <div style={dropDownContainerStyle}>
                        {this.props.auth?.Customers? this.props.auth.Customers.length === 1 ?
                            <div style={{ paddingTop: 2, fontWeight: 20, color: 'white', paddingRight: 30 }}>{this.props.auth.Customers? this.props.auth.Customers[0].name : ""}</div>
                                :
                            <Dropdown
                                placeholder="Select Customer"
                                onChange={(e, a) => this.onDropDownChange(e, a)}
                                options={customerDropdownList || []}
                                selectedKey={selectedRequestType ? selectedRequestType.key : undefined}
                                styles={dropdownStyles}
                            />
                        :
                            <div></div>
                        }
                    </div>
                    <SignOutButton Username={this.props.auth? this.props.auth.User.name : ""}/>
                </div>
            </Stack>}
        </div>
    )}

}

const mapDispatchToProps = ({
    ...AuthStore.actionCreators,
    ...DocumentDetailsStore.actionCreators
});

export default withRouter(connect(
    (state: ApplicationState) => state,
    mapDispatchToProps,
   )(TopBar as any));



