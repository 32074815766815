import React from 'react';
import './index.css';
import ReactDOM from 'react-dom';
import App from './App';
import { mergeStyles } from '@fluentui/react';
import reportWebVitals from './reportWebVitals';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import registerServiceWorker from './registerServiceWorker';
import configureStore from './store/configureStore';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { ToastContainer} from 'react-toastify';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import 'react-toastify/dist/ReactToastify.css';


// Create browser history to use in the Redux store
const history = createBrowserHistory();

//Creating new Msal Instance
const msalInstance = new PublicClientApplication(msalConfig);

const store = configureStore(history);

// Inject some global styles
mergeStyles({
  ':global(body,html,#root)': {
    margin: 0,
    padding: 0,
    height: '100vh',
  },
});

ReactDOM.render(
<MsalProvider instance={msalInstance}>
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ToastContainer />
      <App />
    </ConnectedRouter>
  </Provider>
</MsalProvider>
, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

//Register redux service worker
registerServiceWorker();

//Initialize fluent ui icons
initializeIcons();