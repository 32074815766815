import { StringLiteralLike } from "typescript";

/**
    * Represents a Document Query
*/
export interface DocumentQuery {

/**
    * Specify the store id of the document query
*/
    storeId: number;

/**
    * Specify the filter criteria of the document query
*/    
    filters: QueryFilter[];

/**
    * Specify the sort criteria of the document query
*/    
    sort: SortFilter;

/**
    * Specify page size of the document query
*/    
    pageSize: number;

/**
    * Specify the page number of the document query
*/    
    pageNumber: number;

/**
    * Specify to include/exclude deleted documents
*/    
    excludeDeleted: boolean;

}

export enum queryCondition {
    isEqual = "is-equal",
    startsWith = "starts-with",
    greaterThan = "greater-than",
    lessThan = "less-than",
    none = "none"
}

export enum ascendingDescending {
    ascending = "asc",
    descending = "desc"
}


export interface SortFilter {
    field: string;
    ascDesc: ascendingDescending;
}

export interface QueryFilter {
    field: string;
    condition: queryCondition;
    value: string;
}