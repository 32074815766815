import * as React from 'react';
import { Dropdown, IDropdownOption, IDropdownStyles, NormalPeoplePicker, PrimaryButton, Stack, IPersonaProps, TextField, IStackTokens, ITextFieldStyles, ValidationState, Modal } from '@fluentui/react'
import { getAllCustomerAdministrators, getAllVendorsByCustomerId, getVendorSubscriptionKey, createUpdateCustomerAdministrator } from '../utilities/axiosUtility';
import { CreateCustomerAdministrator, CustomerAdministrator } from '../interfaces/customerAdministrator';
import { Vendor } from '../interfaces/vendor';
import { ApplicationState } from '../store';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { MotionAnimations } from '@fluentui/theme/lib/motion/FluentMotion';

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: 300 },
};

const textFieldStyle: Partial<ITextFieldStyles> = {
  root: { width: "45vw" }
}

const adminStackContainer: IStackTokens = { childrenGap: 20, padding: 5 };

const subkeyToken: IStackTokens = { childrenGap: 20, padding: 5 };

interface INewVendor {
  vendorName: string;
  subscriptionKey: string;
}

interface AdministratorState {
  customerAdministrators: CustomerAdministrator[];
  tsgAdministratorChanges: CreateCustomerAdministrator[];
  tsgSupportChanges: CreateCustomerAdministrator[];
  customerAdministratorChanges: CreateCustomerAdministrator[];
  customerSupport: CustomerAdministrator[];
  customerSupportChanges: CreateCustomerAdministrator[];
  vendorList: IDropdownOption[];
  currentKey: string;
  selectedTSGAdminList: IPersonaProps[];
  selectedTSGSupportList: IPersonaProps[];
  selectedAdminList: IPersonaProps[];
  selectedSupportList: IPersonaProps[];
  selectedItem: IDropdownOption;
  tsgAdminChangesMade: boolean;
  tsgSupportChangesMade: boolean;
  changesMade: boolean;
  supportChangesMade: boolean;
  isTsg: boolean;
  newVendorModalOpen: boolean;
  newVendorDetails: INewVendor;
}

type AdministrationProps =
  ApplicationState &
  RouteComponentProps<{}>;

class Administration extends React.PureComponent<AdministrationProps, AdministratorState> {

  constructor(props: AdministrationProps) {
    super(props)

    this.state = {
      customerAdministrators: [],
      customerAdministratorChanges: [],
      tsgAdministratorChanges: [],
      tsgSupportChanges: [],
      vendorList: [],
      currentKey: "",
      selectedAdminList: [],
      selectedTSGAdminList: [],
      selectedTSGSupportList: [],
      selectedItem: { key: "", text: "" },
      changesMade: false,
      tsgAdminChangesMade: false,
      tsgSupportChangesMade: false,
      customerSupport: [],
      selectedSupportList: [],
      customerSupportChanges: [],
      supportChangesMade: false,
      isTsg: false,
      newVendorModalOpen: false,
      newVendorDetails: {
        vendorName: "",
        subscriptionKey: ""
      }
    }
  }


  public componentDidMount() {
    if (this.props.auth){
    if(this.props.auth.CurrentUserLevels <= 2)
    {
      this.setState({ isTsg: true })
    }

    this.getAdministrationDetails()
  }
  }

  public componentDidUpdate(prevProps: AdministrationProps) {
    if(this.props.auth)
      if (this.props.auth?.SelectedCustomer?.id !== prevProps.auth?.SelectedCustomer?.id) {
        if (this.props.auth.CurrentUserLevels <= 2) {
          this.setState({ isTsg: true })
        }
        else {
          this.setState({ isTsg: false })
        }
        this.getAdministrationDetails()
    }
  }

  private isTsgAdmin(): boolean{
    let isTsgAdmin = false;
    if(this.props.auth)
    {
      if(this.props.auth.CurrentUserLevels === 1)
      {
        isTsgAdmin = true;
      }
    }
    return isTsgAdmin
  }


  private getAdministrationDetails = async () => {
    const admins = await this.getCurrentAdministrators()
    const vendors = await this.getCurrentVendors()

    this.setState({ vendorList: vendors, customerAdministrators: admins, selectedItem: { key: "", text: "" }, currentKey: "" })
  }

  private getCurrentAdministrators = async () => {
    const response = await getAllCustomerAdministrators(this.props.auth?.SelectedCustomer ? this.props.auth.SelectedCustomer.id : 0);
    if (response.status === 200) {
      this.mapAdministratorsSupportToPersona(response.data)
      return response.data
    }
  }

  private mapAdministratorsSupportToPersona = (adminsSupport: CustomerAdministrator[]) => {
    if (adminsSupport.length > 0) {
      let supportList: CustomerAdministrator[] = [];
      let adminList: CustomerAdministrator[] = [];
      let tsgAdminList: CustomerAdministrator[] = [];
      let tsgSupportList: CustomerAdministrator[] = [];

      for (let supportAdmin of adminsSupport) {
        switch (supportAdmin.level) {
          case 1: tsgAdminList.push(supportAdmin);
            break;
          case 2: tsgSupportList.push(supportAdmin);
            break;
          case 4: adminList.push(supportAdmin);
            break
          case 8: supportList.push(supportAdmin)
        }

      }

      let mappedTsgAdmins: IPersonaProps[] = [];
      let mappedTsgSupports: IPersonaProps[] = [];
      let mappedAdmins: IPersonaProps[] = [];
      let mappedSupports: IPersonaProps[] = [];

      for (let tsgAdmin of tsgAdminList) {
        if (tsgAdmin.isActive === true) {
          let newTsgAdmin: IPersonaProps = {
            text: tsgAdmin.loginName,
            showUnknownPersonaCoin: true,
            id: tsgAdmin.id.toString()
          }

          mappedTsgAdmins.push(newTsgAdmin);
        }
      }

      for (let tsgSupport of tsgSupportList) {
        if (tsgSupport.isActive === true) {
          let newTsgSupport: IPersonaProps = {
            text: tsgSupport.loginName,
            showUnknownPersonaCoin: true,
            id: tsgSupport.id.toString()
          }

          mappedTsgSupports.push(newTsgSupport);
        }
      }

      for (let admin of adminList) {
        if (admin.isActive === true) {
          let newAdmin: IPersonaProps = {
            text: admin.loginName,
            showUnknownPersonaCoin: true,
            id: admin.id.toString()
          }

          mappedAdmins.push(newAdmin);
        }
      }
      for (let support of supportList) {
        if (support.isActive === true) {
          let newSupport: IPersonaProps = {
            text: support.loginName,
            showUnknownPersonaCoin: true,
            id: support.id.toString()
          }

          mappedSupports.push(newSupport);
        }
      }
      this.setState({ selectedAdminList: mappedAdmins, selectedSupportList: mappedSupports, selectedTSGAdminList: mappedTsgAdmins, selectedTSGSupportList: mappedTsgSupports })
    }
  }

  private getCurrentVendors = async () => {
    const vendorResponse = await getAllVendorsByCustomerId(this.props.auth?.SelectedCustomer ? this.props.auth.SelectedCustomer.id : 0)
    if (vendorResponse.status === 200) {
      let vendorDropdownItems: IDropdownOption[] = []
      for (let vendor of vendorResponse.data) {
        const vendorDropdownItem: IDropdownOption = {
          key: vendor.id,
          text: vendor.name
        }

        vendorDropdownItems.push(vendorDropdownItem);
      }

      return vendorDropdownItems

    }
    else {
      return []
    }
  }

  private validateInput(input: string): ValidationState {
    if (input.indexOf('@') !== -1) {
      return ValidationState.valid;
    } else if (input.length > 1) {
      return ValidationState.warning;
    } else {
      return ValidationState.invalid;
    }
  }

  private onInputChange(input: string): string {
    const outlookRegEx = /<.*>/g;
    const emailAddress = outlookRegEx.exec(input);

    if (emailAddress && emailAddress[0]) {
      return emailAddress[0].substring(1, emailAddress[0].length - 1);
    }

    return input;
  }

  private onFilterChanged = (
    filterText: string,
    currentPersonas: IPersonaProps[] | undefined,
    limitResults?: number,
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText && currentPersonas) {
      let filteredPersonas: IPersonaProps[] = this.filterPersonasByText(filterText);

      filteredPersonas = this.removeDuplicates(filteredPersonas, currentPersonas);
      filteredPersonas = limitResults ? filteredPersonas.slice(0, limitResults) : filteredPersonas;
      return filteredPersonas;
    } else {
      return [];
    }
  };

  private removeDuplicates(personas: IPersonaProps[], possibleDupes: IPersonaProps[]) {
    return personas.filter(persona => !this.listContainsPersona(persona, possibleDupes));
  }

  private listContainsPersona(persona: IPersonaProps, personas: IPersonaProps[]) {
    if (!personas || !personas.length || personas.length === 0) {
      return false;
    }
    return personas.filter(item => item.text === persona.text).length > 0;
  }

  private filterPersonasByText = (filterText: string): IPersonaProps[] => {
    return this.state.selectedAdminList.filter(item => this.doesTextStartWith(item.text as string, filterText));
  };

  private doesTextStartWith(text: string, filterText: string): boolean {
    return text.toLowerCase().indexOf(filterText.toLowerCase()) === 0;
  }

  private getCurrentSubKey = (vendorId: number) => {
    getVendorSubscriptionKey(this.props.auth?.SelectedCustomer ? this.props.auth.SelectedCustomer.id : 0, vendorId).then((response: any) => {
      if (response.status === 200)
        this.setState({ currentKey: response.data })
    }).catch((error) => {
      toast.error(error, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
  }

  private onDropDownChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined): void => {
    if (item) {
      const vendorKey = parseInt(item.key.toString())
      this.setState({ selectedItem: item })
      this.getCurrentSubKey(vendorKey);
    }
  }

  private ValidateEmail(email: string | undefined) {
    if (email) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return (true)
      }
      toast.error('Please enter valid email address', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return (false)
    }
    else {
      return (false)
    }
  }

  private onSaveNewTsgAdminChanges = async () => {
    if (this.props.auth?.SelectedCustomer) {
      const customerId = this.props.auth.SelectedCustomer ? this.props.auth.SelectedCustomer.id : 0;
      let tsgAdminChanges = _.clone(this.state.tsgAdministratorChanges);
      if (tsgAdminChanges.length > 0) {
        let adminChangesResponse = await createUpdateCustomerAdministrator(customerId, this.state.tsgAdministratorChanges)

        if (adminChangesResponse.status === 200) {
          toast.success('Updated Administrators', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          this.setState({ tsgAdministratorChanges: [], tsgAdminChangesMade: false })
        }
        else {
          toast.error('Error Updating Administrators', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    }
  }

  private onSaveNewTsgSupportChanges = async () => {
    if (this.props.auth?.SelectedCustomer) {
      const customerId = this.props.auth.SelectedCustomer ? this.props.auth.SelectedCustomer.id : 0;
      let tsgSupportChanges = _.clone(this.state.tsgSupportChanges);
      if (tsgSupportChanges.length > 0) {
        let tsgSupportChangesResponse = await createUpdateCustomerAdministrator(customerId, this.state.tsgSupportChanges)

        if (tsgSupportChangesResponse.status === 200) {
          toast.success('Updated Administrators', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          this.setState({ tsgSupportChanges: [], tsgSupportChangesMade: false })
        }
        else {
          toast.error('Error Updating Administrators', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    }
  }

  private userExists = (item: IPersonaProps, currentUserEmail: string = "") => {
    let sSupportList = _.clone(this.state.selectedSupportList);
    let sAdmin = _.clone(this.state.selectedAdminList);
    let sTsgSupportList = _.clone(this.state.selectedTSGSupportList);
    let sTsgAdminList = _.clone(this.state.selectedTSGAdminList);
    let sEAdminList = _.clone(this.state.customerAdministrators);
    let exists: IPersonaProps[] = [];
    if (item.text !== currentUserEmail) {
      for (let cSupport of sSupportList) {
        if (cSupport.text === item.primaryText || cSupport.text === currentUserEmail) {
          exists.push(cSupport)
        }
      }
      for (let cAdmin of sAdmin)
      {
        if (cAdmin.text === item.primaryText || cAdmin.text === currentUserEmail) {
          exists.push(cAdmin)
        }
      }
      for (let cTsgSupport of sTsgSupportList)
      {
        if (cTsgSupport.text === item.primaryText || cTsgSupport.text === currentUserEmail) {
          exists.push(cTsgSupport)
        }
      }
      for (let cTsgAdmin of sAdmin)
      {
        if (cTsgAdmin.text === item.primaryText || cTsgAdmin.text === currentUserEmail) {
          exists.push(cTsgAdmin)
        }
      }

    }
    return exists

  }

  private onUserTagListChange = (items: IPersonaProps[] | undefined, tagpickerType: string): void => {
    console.log(this.state);
    const customerId = this.props.auth?.SelectedCustomer ? this.props.auth.SelectedCustomer.id : 0;
    const _userEmail = this.props.auth? this.props.auth.User.emailAddress : "";

    let _persona: IPersonaProps = {
      text: "",
      showUnknownPersonaCoin: false,
      id: "0"
    }

    let _userChange: CreateCustomerAdministrator = {
      customerId: customerId,
      id: 0,
      loginName: "",
      isActive: false,
      isModified: false,
      isPrimary: false,
      isInternal: false,
      permissions: []
    }

    if (items)
      switch (tagpickerType) {
        case "CustomerSupport": {
          let nSelectedSupportList = _.clone(this.state.selectedSupportList);
          let nChangesSupportList = _.clone(this.state.customerSupportChanges);
          _userChange.permissions.push("CustomerSupport");

          if (items.length > 0) {
            const userExists = this.userExists(items[items.length - 1], _userEmail)
              if (items.length > nSelectedSupportList.length) {
                  if (this.ValidateEmail(items[items.length - 1].primaryText)) {
                    if(userExists.length === 0)
                    {
                      let alreadyExists = this.state.customerAdministrators.filter(support => support.loginName === items[items.length - 1].primaryText || support.loginName === _userEmail);
                      if (alreadyExists.length === 1) {
                        _persona.text = items[items.length - 1].primaryText;
                        _persona.showUnknownPersonaCoin = true;
                        _persona.id = alreadyExists[0].id.toString()

                        _userChange.id = alreadyExists[0].id;
                        _userChange.loginName = items[items.length - 1].primaryText;
                        _userChange.isActive = true;
                        _userChange.isModified = true;

                        nChangesSupportList.push(_userChange);
                        nSelectedSupportList.push(_persona);
                      }
                      else {
                        _persona.text = items[items.length - 1].primaryText;
                        _persona.showUnknownPersonaCoin = true;
                        _persona.id = "-1";

                        _userChange.id = -1;
                        _userChange.loginName = items[items.length - 1].primaryText;
                        _userChange.isActive = true;
                        _userChange.isModified = true;

                        nChangesSupportList.push(_userChange);
                        nSelectedSupportList.push(_persona);

                      }

                      this.setState({ selectedSupportList: nSelectedSupportList, customerSupportChanges: nChangesSupportList, supportChangesMade: true })
                    }
                  }
              }
              else {
                for (let cSupports of nSelectedSupportList) {
                  if (items.indexOf(cSupports) === -1) {
                    _userChange.id = parseInt(cSupports.id ? cSupports.id : "-1");
                    _userChange.loginName = cSupports.text;
                    _userChange.isModified = true;

                    nChangesSupportList.push(_userChange);
                    nSelectedSupportList = items;
                  }
                }
                this.setState({ selectedSupportList: nSelectedSupportList, customerSupportChanges: nChangesSupportList, supportChangesMade: true })
              }
            
          }
          else {
            for (let support of nSelectedSupportList) {
              _userChange.id = parseInt(support.id ? support.id : "-1");
              _userChange.loginName = support.text;
              _userChange.isActive = false;
              _userChange.isModified = true;

              nChangesSupportList.push(_userChange)
            }

            this.setState({ selectedSupportList: [], customerSupportChanges: nChangesSupportList, supportChangesMade: true });
          }
        };
          break;
        case "Administrator": {
          let nSelectedAdmin = _.clone(this.state.selectedAdminList);
          let nChangesAdminList = _.clone(this.state.customerAdministratorChanges);
          _userChange.permissions.push("Administrator");

          if (items.length > 0) {
            const userExists = this.userExists(items[items.length - 1], _userEmail)
              if (items.length > nSelectedAdmin.length) {
                  if (this.ValidateEmail(items[items.length - 1].primaryText) && nSelectedAdmin.indexOf(items[items.length - 1]) === -1) {
                    if(userExists.length === 0)
                    {
                      let alreadyExists = this.state.customerAdministrators.filter(admin => admin.loginName === items[items.length - 1].primaryText || admin.loginName === this.props.auth?.User.emailAddress);
                      if (alreadyExists.length === 1) {
                        _persona.text = items[items.length - 1].primaryText;
                        _persona.showUnknownPersonaCoin = true;
                        _persona.id = alreadyExists[0].id.toString();
                
                        _userChange.id = alreadyExists[0].id;
                        _userChange.loginName = alreadyExists[0].loginName;
                        _userChange.isActive = true;
                        _userChange.isModified = true;
                        _userChange.isPrimary = true;

                        nChangesAdminList.push(_userChange);
                        nSelectedAdmin.push(_persona);
                        this.setState({ selectedAdminList: nSelectedAdmin, customerAdministratorChanges: nChangesAdminList, changesMade: true })
                      }
                      else {

                        _persona.text = items[items.length - 1].primaryText;
                        _persona.showUnknownPersonaCoin = true;
                        _persona.id = "-1"

                        _userChange.id = -1;
                        _userChange.loginName = items[items.length - 1].primaryText;
                        _userChange.isActive = true;
                        _userChange.isModified = true;
                        _userChange.isPrimary = true;

                        nChangesAdminList.push(_userChange);
                        nSelectedAdmin.push(_persona);
                        this.setState({ selectedAdminList: nSelectedAdmin, customerAdministratorChanges: nChangesAdminList, changesMade: true })
                      }
                    }
                }
              }
              else {
                for (let cAdmins of nSelectedAdmin) {
                  if (items.indexOf(cAdmins) === -1) {

                    _userChange.id = parseInt(cAdmins.id ? cAdmins.id : "-1");
                    _userChange.loginName = cAdmins.text;
                    _userChange.isActive = false;
                    _userChange.isModified = true;
                    _userChange.isPrimary = true;
  
                    nChangesAdminList.push(_userChange);
                    nSelectedAdmin = items;
                  }
                }
                this.setState({ selectedAdminList: nSelectedAdmin, customerAdministratorChanges: nChangesAdminList, changesMade: true })
              }
          }
          else {
            for (let admin of nSelectedAdmin) {
              
              _userChange.id = parseInt(admin.id ? admin.id : "-1");
              _userChange.loginName = admin.text;
              _userChange.isActive = false;
              _userChange.isModified = true;
              _userChange.isPrimary = true;

              nChangesAdminList.push(_userChange);
            }
            this.setState({ selectedAdminList: [], customerAdministratorChanges: nChangesAdminList, changesMade: true });
          }
        };
          break;
        case "TSGSupport": {
          let nSelectedTsgSupportList = _.clone(this.state.selectedTSGSupportList);
          let nChangesTsgSupportList = _.clone(this.state.tsgSupportChanges);
          _userChange.permissions.push("TSGSupport");


          if (items.length > 0) {
            const userExists = this.userExists(items[items.length - 1], _userEmail)
              if (items.length > nSelectedTsgSupportList.length) {
                  if (this.ValidateEmail(items[items.length - 1].primaryText) && nSelectedTsgSupportList.indexOf(items[items.length - 1]) === -1) {
                    if(userExists.length === 0)
                    {
                    let alreadyExists = this.state.customerAdministrators.filter(support => support.loginName === items[items.length - 1].primaryText || support.loginName === this.props.auth?.User.emailAddress);
                    console.log(alreadyExists);
                    if (alreadyExists.length > 0) {
                      
                      _persona.text = items[items.length - 1].primaryText;
                      _persona.showUnknownPersonaCoin = true;
                      _persona.id = alreadyExists[0].id.toString();

                      _userChange.id = alreadyExists[0].id;
                      _userChange.loginName = alreadyExists[0].loginName;
                      _userChange.isActive = true;
                      _userChange.isModified = true;
                      _userChange.isInternal = true;
        
                      console.log("Greater", _userChange, _persona);
                      nChangesTsgSupportList.push(_userChange);
                      nSelectedTsgSupportList.push(_persona);
                      this.setState({ selectedTSGSupportList: nSelectedTsgSupportList, tsgSupportChanges: nChangesTsgSupportList, tsgSupportChangesMade: true })

                    }
                    else {

                      _persona.text = items[items.length - 1].primaryText;
                      _persona.showUnknownPersonaCoin = true;
                      _persona.id = "-1";

                      _userChange.id = -1;
                      _userChange.loginName = items[items.length - 1].primaryText;
                      _userChange.isActive = true;
                      _userChange.isModified = true;
                      _userChange.isInternal = true;
        
                      console.log("Else", _userChange, _persona);

                      nChangesTsgSupportList.push(_userChange);
                      nSelectedTsgSupportList.push(_persona);
                      this.setState({ selectedTSGSupportList: nSelectedTsgSupportList, tsgSupportChanges: nChangesTsgSupportList, tsgSupportChangesMade: true })
                    }
                    }
                  }
              }
              else {
                for (let cAdmins of nSelectedTsgSupportList) {
                  if (items.indexOf(cAdmins) === -1) {

                    _userChange.id = parseInt(cAdmins.id ? cAdmins.id : "-1");
                    _userChange.loginName = cAdmins.text;
                    _userChange.isModified = true;
                    _userChange.isInternal = true;

                    nChangesTsgSupportList.push(_userChange);
                    nSelectedTsgSupportList = items;
                    this.setState({ selectedTSGSupportList: nSelectedTsgSupportList, tsgSupportChanges: nChangesTsgSupportList, tsgSupportChangesMade: true })

                  }
                }
              }
          }
          else {
            for (let support of nSelectedTsgSupportList) {
              console.log(support)
              _userChange.id = parseInt(support.id ? support.id : "-1");
              _userChange.loginName = support.text;
              _userChange.isModified = true;
              _userChange.isInternal = true;

              nChangesTsgSupportList.push(_userChange);
            }

            console.log(nChangesTsgSupportList);
            this.setState({ selectedTSGSupportList: [], tsgSupportChanges: nChangesTsgSupportList, tsgSupportChangesMade: true });
          }
        };
          break;
        case "TSGAdmin": {
          let nSelectedTsgAdminList = _.clone(this.state.selectedTSGAdminList);
          let nChangesTsgAdminList = _.clone(this.state.tsgAdministratorChanges);
          _userChange.permissions.push("TSGAdmin");
          if (items.length > 0) {
            const userExists = this.userExists(items[items.length - 1], _userEmail)
            console.log(items, nSelectedTsgAdminList);
              if (items.length > nSelectedTsgAdminList.length) {
                  if (this.ValidateEmail(items[items.length - 1].primaryText) && nSelectedTsgAdminList.indexOf(items[items.length - 1]) === -1) {
                    if(userExists.length === 0)
                    {
                      let alreadyExists = this.state.customerAdministrators.filter(admin => admin.loginName === items[items.length - 1].primaryText);
                      if (alreadyExists.length > 0) {

                        _persona.text = items[items.length - 1].primaryText;
                        _persona.showUnknownPersonaCoin = true;
                        _persona.id = alreadyExists[0].id.toString();

                        _userChange.id = alreadyExists[0].id;
                        _userChange.loginName = alreadyExists[0].loginName;
                        _userChange.isActive = true;
                        _userChange.isModified = true;
                        _userChange.isInternal = true;
                        _userChange.isPrimary = true;

                        nChangesTsgAdminList.push(_userChange);
                        nSelectedTsgAdminList.push(_persona);
                        this.setState({ selectedTSGAdminList: nSelectedTsgAdminList, tsgAdministratorChanges: nChangesTsgAdminList, tsgAdminChangesMade: true })
                      }
                      else {

                        _persona.text = items[items.length - 1].primaryText;
                        _persona.showUnknownPersonaCoin = true;
                        _persona.id = "-1";

                        _userChange.id = -1;
                        _userChange.loginName = items[items.length - 1].primaryText;
                        _userChange.isActive = true;
                        _userChange.isModified = true;
                        _userChange.isInternal = true;
                        _userChange.isPrimary = true;

                        nChangesTsgAdminList.push(_userChange);
                        nSelectedTsgAdminList.push(_persona);
                        this.setState({ selectedTSGAdminList: nSelectedTsgAdminList, tsgAdministratorChanges: nChangesTsgAdminList, tsgAdminChangesMade: true })
                      }
                    }}
                  }
                else {
                  for (let cAdmins of nSelectedTsgAdminList) {

                      _userChange.id = parseInt(cAdmins.id ? cAdmins.id : "-1");
                      _userChange.loginName = cAdmins.text;
                      _userChange.isActive = false;
                      _userChange.isModified = true;
                      _userChange.isInternal = true;
                      _userChange.isPrimary = true;

                      nChangesTsgAdminList.push(_userChange);
                      nSelectedTsgAdminList = items;
                      this.setState({ selectedTSGAdminList: nSelectedTsgAdminList, tsgAdministratorChanges: nChangesTsgAdminList, tsgAdminChangesMade: true })

                    }
                }
              }
          else {
            for (let admins of nSelectedTsgAdminList) {

              _userChange.id = parseInt(admins.id ? admins.id : "-1");
              _userChange.loginName = admins.text;
              _userChange.isActive = false;
              _userChange.isModified = true;
              _userChange.isInternal = true;
              _userChange.isPrimary = true;

              nChangesTsgAdminList.push(_userChange);
              nSelectedTsgAdminList = items;
            }
            this.setState({ selectedTSGAdminList: [], tsgAdministratorChanges: nChangesTsgAdminList, tsgAdminChangesMade: true });
          }
        };
        break;
        default: {}
      }
  }

  private onSaveNewAdministratorChanges = async () => {
    if (this.props.auth?.SelectedCustomer) {
      const customerId = this.props.auth.SelectedCustomer ? this.props.auth.SelectedCustomer.id : 0;
      let adminChanges = _.clone(this.state.customerAdministratorChanges);
      if (adminChanges.length > 0) {


        let adminChangesResponse = await createUpdateCustomerAdministrator(customerId, this.state.customerAdministratorChanges)

        if (adminChangesResponse.status === 200) {
          toast.success('Updated Administrators', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          this.setState({ customerAdministratorChanges: [], changesMade: false })
        }
        else {
          toast.error('Error Updating Administrators', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    }
  }

  private onSaveNewSupportChanges = async () => {
    if (this.props.auth?.SelectedCustomer) {
      const customerId = this.props.auth.SelectedCustomer?.id
      let supportChanges = _.clone(this.state.customerSupportChanges);
      if (supportChanges.length > 0) {

        let adminChangesResponse = await createUpdateCustomerAdministrator(customerId, supportChanges)

        if (adminChangesResponse.status === 200) {
          toast.success('Updated Support Users', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          this.setState({ customerSupportChanges: [], supportChangesMade: false })
        }
        else {
          toast.error('Error Updating Support Users', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    }
  }

  private toggleCreateKeyModal = () => {
    this.setState({ newVendorModalOpen: !this.state.newVendorModalOpen })
  }

  private onChangeNewVendorDetails = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, type: string, newValue?: string) => {
    let tempVendorEditDetails = _.clone(this.state.newVendorDetails);
    switch (type) {
      case "vendor": tempVendorEditDetails.vendorName = newValue ? newValue : "";
        break
      case "key": tempVendorEditDetails.subscriptionKey = newValue ? newValue : "";
    }

    this.setState({ newVendorDetails: tempVendorEditDetails })
  }

  public render() {
    const { newVendorModalOpen, newVendorDetails, isTsg, vendorList, currentKey, selectedItem, selectedAdminList, changesMade, customerSupport, selectedSupportList, supportChangesMade, tsgAdminChangesMade, tsgSupportChangesMade, selectedTSGAdminList, selectedTSGSupportList } = this.state
    return (
      <React.Fragment>
        <Stack styles={{ root: { paddingBottom: 20, animation: MotionAnimations.fadeIn } }} tokens={adminStackContainer}>
          <Stack style={{ width: "80vw" }}>
            <h2>Subscription Keys</h2>
            View and copy subscription keys for vendors associated with your organisation. The subscription keys are required if a vendor wants to integrate with DOC-CONNECT APIs
            <Stack horizontal tokens={subkeyToken}>
              <Dropdown
                placeholder="Select an vendor"
                label="Vendor"
                onChange={this.onDropDownChange}
                selectedKey={selectedItem ? selectedItem.key : undefined}
                options={vendorList}
                styles={dropdownStyles}
              />
              <TextField label="Key" value={currentKey} styles={textFieldStyle} />
              {/* New Vendor Render */}
              {/* {isTsg && (<Stack style={{ paddingTop: 30 }}>
                <PrimaryButton text="New Key" onClick={() => this.toggleCreateKeyModal()} />
                <Modal
                  isOpen={newVendorModalOpen}
                  onDismiss={() => this.toggleCreateKeyModal()}
                >
                  <Stack tokens={{ childrenGap: 10 }} style={{ padding: 10 }}>
                    <TextField label="Vendor Name" onChange={(ev, value) => this.onChangeNewVendorDetails(ev, "vendor", value)} value={newVendorDetails.vendorName} />
                    <TextField label="Subscription Key" onChange={(ev, value) => this.onChangeNewVendorDetails(ev, "key", value)} value={newVendorDetails.subscriptionKey} />
                    <Stack tokens={{ childrenGap: 10 }} horizontal>
                      <PrimaryButton text="Save" onClick={() => { alert("saving") }} />
                      <PrimaryButton text="Cancel" onClick={() => this.toggleCreateKeyModal()} />
                    </Stack>
                  </Stack>
                </Modal>
              </Stack>)} */}
            </Stack>
          </Stack>
          <Stack tokens={{ childrenGap: 20 }}>
            {isTsg && (
            <Stack tokens={{ childrenGap: 20 }}>
              {this.isTsgAdmin() && (<Stack.Item style={{ animation: MotionAnimations.fadeIn }}>
              <h2>TSG Administrators</h2>
              <div style={{ paddingBottom: 10 }}>Manage TSG Administrators</div>
              <NormalPeoplePicker
                onResolveSuggestions={(f: string, items: IPersonaProps[] | undefined) => this.onFilterChanged(f, items)}
                getTextFromItem={getTextFromItem}
                className={'ms-PeoplePicker'}
                key={'controlled'}
                removeButtonAriaLabel={'Remove'}
                selectedItems={selectedTSGAdminList}
                onValidateInput={(input: string) => this.validateInput(input)}
                onChange={(i) => this.onUserTagListChange(i, "TSGAdmin")}
                inputProps={{
                  style: { padding: 5 },
                  'aria-label': 'TSG Admins',
                }}
                resolveDelay={300}
              />
              <Stack.Item style={{ paddingTop: 20 }}>
                <PrimaryButton disabled={!tsgAdminChangesMade} text="Save" onClick={this.onSaveNewTsgAdminChanges} />
              </Stack.Item>
            </Stack.Item>)}
              <Stack.Item style={{ animation: MotionAnimations.fadeIn }}>
                <h2>TSG Support</h2>
                <div style={{ paddingBottom: 10 }}>Manage TSG Supports</div>
                <NormalPeoplePicker
                  onResolveSuggestions={(f: string, items: IPersonaProps[] | undefined) => this.onFilterChanged(f, items)}
                  getTextFromItem={getTextFromItem}
                  className={'ms-PeoplePicker'}
                  key={'controlled'}
                  removeButtonAriaLabel={'Remove'}
                  selectedItems={selectedTSGSupportList}
                  onValidateInput={(input: string) => this.validateInput(input)}
                  onChange={(i) => this.onUserTagListChange(i, "TSGSupport")}
                  inputProps={{
                    style: { padding: 5 },
                    'aria-label': 'TSG Supports',
                  }}
                  resolveDelay={300}
                />
                <Stack.Item style={{ paddingTop: 20 }}>
                  <PrimaryButton disabled={!tsgSupportChangesMade} text="Save" onClick={this.onSaveNewTsgSupportChanges} />
                </Stack.Item>
              </Stack.Item></Stack>)}
            <Stack.Item style={{ animation: MotionAnimations.fadeIn }}>
              <h2>Administrators</h2>
              <div style={{ paddingBottom: 10 }}>Manage the list of users who can administer the DOC-CONNECT configuration on the organisation's behalf. Please note that the users added here will not be able to manage this list.</div>
              <NormalPeoplePicker
                onResolveSuggestions={(f: string, items: IPersonaProps[] | undefined) => this.onFilterChanged(f, items)}
                getTextFromItem={getTextFromItem}
                className={'ms-PeoplePicker'}
                key={'controlled'}
                removeButtonAriaLabel={'Remove'}
                selectedItems={selectedAdminList}
                onValidateInput={(input: string) => this.validateInput(input)}
                onChange={(i) => this.onUserTagListChange(i, "Administrator")}
                inputProps={{
                  style: { padding: 5 },
                  'aria-label': 'Customer Admins',
                }}
                resolveDelay={300}
              />
              <Stack.Item style={{ paddingTop: 20 }}>
                <PrimaryButton disabled={!changesMade} text="Save" onClick={this.onSaveNewAdministratorChanges} />
              </Stack.Item>
            </Stack.Item>
            <Stack.Item style={{ animation: MotionAnimations.fadeIn }}>
              <h2>Support Users</h2>
              <div style={{ paddingBottom: 10 }}>Manage the list of users who can support the DOC-CONNECT configuration on the organisation's behalf. Please note that the users added here will not be able to manage this list.</div>
              <NormalPeoplePicker
                onResolveSuggestions={(f: string, items: IPersonaProps[] | undefined) => this.onFilterChanged(f, items)}
                getTextFromItem={getTextFromItem}
                className={'ms-PeoplePicker'}
                key={'controlled'}
                removeButtonAriaLabel={'Remove'}
                selectedItems={selectedSupportList}
                onValidateInput={(input: string) => this.validateInput(input)}
                onChange={(i) => this.onUserTagListChange(i, "CustomerSupport")}
                inputProps={{
                  style: { padding: 5 },
                  'aria-label': 'Customer Supports',
                }}
                resolveDelay={300}
              />
              <Stack.Item style={{ paddingTop: 20 }}>
                <PrimaryButton disabled={!supportChangesMade} text="Save" onClick={() => this.onSaveNewSupportChanges()} />
              </Stack.Item>
            </Stack.Item>
          </Stack>
        </Stack>
      </React.Fragment>
    )
  }
}


export default connect(
  (state: ApplicationState) => state,
)(Administration as any);


function getTextFromItem(persona: IPersonaProps): string {
  return persona.text as string;
}

