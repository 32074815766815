import { IColumn, Icon, IStackTokens, Stack } from '@fluentui/react';
import * as React from 'react';
import { getStoreTotalStats } from '../../utilities/axiosUtility';
import { useSelector, useDispatch } from 'react-redux';
import './documentStoreStyles.css'
import { toast } from 'react-toastify';
import { redirectToSelectedStatus } from '../../dispatchers/documentHistoryDispatcher';
import { IStoreDocumentStatistic, IStoreStatistic, StoreStatus } from '../../interfaces/storeStatistics';


const modalInfoStackTokens: IStackTokens = { childrenGap: 15, padding: 10 };

const RenderDropStats = (props: { children?: React.ReactNode, customerId: number, storeId: number, columns: IColumn[]}) => {
    const [isOpen, setIsOpen] = React.useState(false)
    const [storeStats, setStoreStats] = React.useState<IStoreDocumentStatistic[]>([])
    const dispatch = useDispatch();

    React.useEffect(() => {
        setIsOpen(false);
    },[props.customerId])

    const getStoreStats = async () => {
        const storeStatsResponse = await getStoreTotalStats(props.customerId, props.storeId)
        if (storeStatsResponse.status === 200) {
            if (storeStatsResponse.data.length > 0) {
                setStoreStats(storeStatsResponse.data)
                setIsOpen(true)
            }
            else {
                toast.error('Store currently has no statisics', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
    }

    if (isOpen) {
        return (
            <Stack onClick={() => setIsOpen(false)}>
                {props.children}
                <Stack horizontal tokens={modalInfoStackTokens} styles={{ root: {} }}>
                    {storeStats.map((stat, id) => {
                        return (
                            <div style={{ cursor: 'pointer' }} onClick={() => redirectToSelectedStatus(stat, props.storeId, props.customerId, dispatch)} key={id}>{StoreStatus[stat.status]}: {stat.count}</div>
                        )
                    })}
                </Stack>
                <div className='statusDrop' onClick={() => setIsOpen(false)}><Icon styles={{ root: { fontSize: 18 } }} iconName="ChevronUp" /></div>
            </Stack>
        )
    }
    else {
        return (
            <div>
                {props.children}
                <div className='statusDrop' onClick={() => getStoreStats()}><Icon styles={{ root: { fontSize: 18 } }} iconName="ChevronDown" /></div>
            </div>
        )
    }
}
export default RenderDropStats
