import * as React from 'react';
import { IStyleSet, Label, ILabelStyles, Pivot, PivotItem, Modal, TextField, Stack, IStackTokens, PrimaryButton, DetailsList, Spinner, IColumn, Dialog, DialogFooter, DefaultButton, DialogType, Toggle, IDropdownStyles, Dropdown, IDropdownOption, CommandBarButton, IconButton, ISpinnerStyles } from '@fluentui/react';
import {DocumentStoreFlags, DocumentStoreQuota } from '../../interfaces/documentStore';
import {ApiKey} from '../../interfaces/apiKey';
import { executeStoreQuery, getAllApiKeysByCustomerAndDocumentId, getMetadataForDocumentStoreById, getStoreTypeByTypeId, generateApiKey, deleteApiKeyById, updateDocumentStore, updateMetadataForDocumentStore, executeStoreTypeQuery} from '../../utilities/axiosUtility';
import { getEnumFlags, IconTooltip } from '../../utilities/helpers';
import _ from 'lodash';
import { toast, ToastOptions } from 'react-toastify';
import SubscriptionsTab from './EditTabs/SubscriptionTab';
import { UserPermissionLevels } from '../../interfaces/customerAdministrator';

interface IMetadataSettingsItem {
  name: string;
  displayName: string;
  type: string;
  group: string;
  required: boolean;
}

interface INewApiKeyDetails {
  name: string;
  description: string;
}

interface IEditStoreFields {
  type: string;
  name: string;
  description: string;
  siteUrl: string;
  documentLibrary: string;
  username: string;
  password: string;
  sourceSelected: boolean;
  keepDoc: boolean;
  useDest: boolean;
  useSource: boolean;
}

interface ILibraryUserInfo {
  SPUserName: string;
  SPPassword: string;
}

interface ILibrarySettings {
  SiteUrl: string;
}


interface IConnectLibraryDetails {
  Credentials: ILibraryUserInfo;
  Query: string;
  Settings: ILibrarySettings;
}

interface IStoreTypeDetails {
  id: number;
  name: string;
  description: string;
  typeId: string;
  flags: DocumentStoreFlags
}

interface IStoreQueryParams {
  ListId: string;
}

interface IPostStoreQuery {
  parameters: IStoreQueryParams;
  query: string;
}

const labelStyles: Partial<IStyleSet<ILabelStyles>> = {
  root: { padding: 10 },
};

const modalInfoStackTokens: IStackTokens = { childrenGap: 15, padding: 10 };
const saveCancelStackTokens: IStackTokens = { childrenGap: 10 };
const toggleStackTokens: IStackTokens = { childrenGap: 30, padding: 5 };


const defaultStoreTypeDetails: IStoreTypeDetails = {
  id: 0,
  name: "",
  description: "",
  typeId: "",
  flags: 0
}

const defaultApiKeyDetails: INewApiKeyDetails = {
  name: "",
  description: ""
}

const spinnerStyle: ISpinnerStyles = {
  circle: 
  {
    width: 72, 
    height: 72
  },
  root:{
    paddingTop: "2rem"
  }
}



const DocumentStoreEditView = (props: { currentUserLevels: UserPermissionLevels | undefined, documentStoreDetails: DocumentStoreQuota | undefined, disableEdit: any, customerId: number | undefined, updateDocumentStoreList: any }) => {

  const defaultApiColumns: IColumn[] = [
    {
      key: 'column1',
      name: 'Name',
      fieldName: 'name',
      minWidth: 100,
      maxWidth: 100,
      isRowHeader: true,
      isResizable: false,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      // onColumnClick: this._onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column2',
      name: 'Description',
      fieldName: 'description',
      minWidth: 100,
      maxWidth: 100,
      isRowHeader: true,
      isResizable: false,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      // onColumnClick: this._onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column3',
      name: 'Created By',
      fieldName: 'createdBy',
      minWidth: 100,
      maxWidth: 100,
      isRowHeader: true,
      isResizable: false,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      // onColumnClick: this._onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column4',
      name: 'Created Date',
      fieldName: 'createdDate',
      minWidth: 100,
      maxWidth: 100,
      isRowHeader: true,
      isResizable: false,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      // onColumnClick: this._onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column5',
      name: 'Public Key',
      fieldName: 'publicKey',
      minWidth: 100,
      maxWidth: 100,
      isRowHeader: true,
      isResizable: false,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      // onColumnClick: this._onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column6',
      name: 'Action',
      fieldName: 'action',
      minWidth: 40,
      maxWidth: 40,
      isRowHeader: true,
      isResizable: false,
      onRender: (item, index) => renderActionButtons(item, "API", index),
    },
  ]

  const defaultMetadataColumns: IColumn[] = [
    {
      key: 'column1',
      name: 'Name',
      fieldName: 'name',
      minWidth: 100,
      maxWidth: 100,
      isRowHeader: true,
      isResizable: false,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      // onColumnClick: this._onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column2',
      name: 'Display Name',
      fieldName: 'displayName',
      minWidth: 100,
      maxWidth: 100,
      isRowHeader: true,
      isResizable: false,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      // onColumnClick: this._onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column3',
      name: 'Type',
      fieldName: 'type',
      minWidth: 100,
      maxWidth: 100,
      isRowHeader: true,
      isResizable: false,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      // onColumnClick: this._onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column4',
      name: 'Group',
      fieldName: 'group',
      minWidth: 100,
      maxWidth: 100,
      isRowHeader: true,
      isResizable: false,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      // onColumnClick: this._onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column5',
      name: 'Required',
      fieldName: 'required',
      minWidth: 100,
      maxWidth: 100,
      isRowHeader: true,
      isResizable: false,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      // onColumnClick: this._onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column6',
      name: 'Action',
      fieldName: 'action',
      minWidth: 50,
      maxWidth: 50,
      isRowHeader: true,
      isResizable: false,
      onRender: (item, index) => renderActionButtons(item, "META", index),
    },
  ]

  const defaultStoreFields: IEditStoreFields = {
    type: props.documentStoreDetails ? props.documentStoreDetails.typeId : "",
    name: props.documentStoreDetails ? props.documentStoreDetails.name : "",
    description: props.documentStoreDetails ? props.documentStoreDetails.description : "",
    siteUrl: props.documentStoreDetails?.settings.SiteUrl,
    documentLibrary: "",
    username: "",
    password: "",
    sourceSelected: false,
    keepDoc: false,
    useDest: false,
    useSource: false,
  }

  const defaultMetadataField: IMetadataSettingsItem = {
    name: "",
    displayName: "",
    type: "",
    group: "Global",
    required: false
  }

  const [createApiKeyModal, setCreateApiKeyModal] = React.useState(false);
  const [storeApiKeys, setStoreApiKeys] = React.useState<ApiKey[]>([]);
  const [storeMetadata, setStoreMetadata] = React.useState<IMetadataSettingsItem[]>([]);
  const [metaDataReady, setMetaDataReady] = React.useState(false);
  const [editStoreModalOpen, setEditStoreModalOpen] = React.useState(false);
  const [editStoreDetails, setEditStoreDetails] = React.useState<IEditStoreFields>(defaultStoreFields);
  const [tempEditStoreDetails, setTempEditStoreDetails] = React.useState<IEditStoreFields>(defaultStoreFields);
  const [storeTypeDetails, setStoreTypeDetails] = React.useState<IStoreTypeDetails>(defaultStoreTypeDetails);
  const [newApiKeyDetails, setNewApiKeyDetails] = React.useState<INewApiKeyDetails>(defaultApiKeyDetails);
  const [apiKeyGenerated, setApiKeyGenerated] = React.useState(false);
  const [generatedApiKey, setGeneratedApiKey] = React.useState("");
  const [systemManaged, setSystemManaged] = React.useState(false);
  const [isDeleteApiDialogOpen, setDeleteApiDialogOpen] = React.useState(false);
  const [selectedDeleteApiKeyId, setSelectedDeleteApiKeyId] = React.useState(0);
  const [deleteMetadataArrayId, setDeleteMetadataArrayId] = React.useState(0);
  const [deleteMetadataDialogOpen, setDeleteMetadataDialogOpen] = React.useState(false);
  const [editSaveDisabled, SeteditSaveDisabled] = React.useState(true);
  const [selectedMetafieldType, setSelectedMetafieldType] = React.useState<IDropdownOption>();
  const [editMetadataModalOpen, setEditMetadataModalOpen] = React.useState(false);
  const [selectedEditMetadataRecord, setSelectedEditMetadataRecord] = React.useState<IMetadataSettingsItem>(defaultMetadataField);
  const [selectedEditMetadataArrayId, setSelectedEditMetaArrayId] = React.useState(0);
  const [isCreateMetadata, setIsCreateMetadata] = React.useState(false);
  const [detailsVisible, setDetailsVisible] = React.useState(false);
  const [apiVisible, setApiVisible] = React.useState(false);
  const [selectedLibraryItem, setSelectedLibrary] = React.useState<IDropdownOption>();
  const [libraryOptions, setLibraryOptions] = React.useState<IDropdownOption[]>([]);
  const [editMetadataSaveDisabled, setEditMetadataSaveDisabled] = React.useState(true);
  const [generateApiKeyDisabled, setGenerateApiKeyDisabled] = React.useState(true);
  const [isSource, setIsSource] = React.useState(false);

  React.useEffect(() => {
    if (props.documentStoreDetails !== undefined) {
      const storeId = props.documentStoreDetails.id;
      const customerId = props.documentStoreDetails.customerId;
      getStoreApiKeys(customerId, storeId);
      if (props.documentStoreDetails) {
        let _isSource = getEnumFlags(props.documentStoreDetails.flags, Object.keys(DocumentStoreFlags)).filter(f => f === 1).length > 0;
        setIsSource(_isSource);
        getStoreMetadata(customerId, storeId);
        getStoreTypeDetails(customerId, props.documentStoreDetails.typeId);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])


  //gets/sets document store api keys.
  const getStoreApiKeys = async (customerId: number, storeId: number) => {
    const apiResponse = await getAllApiKeysByCustomerAndDocumentId(customerId, storeId);
    if (apiResponse.status === 200) {
      setStoreApiKeys(apiResponse.data);
      setApiVisible(true);
    }
    else {
      return []
    }
  }

  //gets/sets document store details to current component and performs check for store flags/type and sets current state.  
  const getStoreTypeDetails = async (customerId: number, typeId: string) => {
    const storeTypeResponse = await getStoreTypeByTypeId(customerId, typeId)
    if(storeTypeResponse.status === 200)
    {
      if(storeTypeResponse.data.flags)
      {
          const storeFlag: DocumentStoreFlags = storeTypeResponse.data.flags
          const isSystem = getEnumFlags(storeFlag, Object.keys(DocumentStoreFlags)).filter(f => f === 4).length > 0;
          if(isSystem)
          {
            setSystemManaged(true);
          }  
      }
      if (props.documentStoreDetails) {
      const docStoreFlags = getEnumFlags(props.documentStoreDetails.flags, Object.keys(DocumentStoreFlags))
      const storeDetails = _.clone(editStoreDetails);
      storeDetails.name = props.documentStoreDetails.name;
      storeDetails.description = props.documentStoreDetails.description;
      storeDetails.siteUrl = props.documentStoreDetails.settings.SiteUrl;
      storeDetails.username = "";
      storeDetails.password = "";
      storeDetails.useSource = docStoreFlags.filter(f => f === 1).length > 0;
      storeDetails.useDest = docStoreFlags.filter(f => f === 2).length > 0;
      storeDetails.keepDoc = docStoreFlags.filter(f => f === 16).length > 0;

      if (!storeTypeResponse.data.typeId.includes("a")) {
        let queryPostData: IPostStoreQuery = {
          parameters: { ListId: props.documentStoreDetails.settings.ListId },
          query: "sp.web.getlistbyid"
        }

        try {
          const libraryQueryResponse = await executeStoreQuery(customerId, props.documentStoreDetails.id, queryPostData)
          if (libraryQueryResponse.status === 200) {
            storeDetails.documentLibrary = libraryQueryResponse.data.title;
          }
        }
        catch {
          toastNotify(true, "Error Retrieving Document Library'");
        }
      }
      setEditStoreDetails(storeDetails);
      setTempEditStoreDetails(storeDetails);
    }
    setStoreTypeDetails(storeTypeResponse.data);
  }
  setDetailsVisible(true)
}

  //gets/sets document store metadata and sets the view to ready
  const getStoreMetadata = async (customerId: number, storeId: number) => {
    try {
      const metaDataResponse = await getMetadataForDocumentStoreById(customerId, storeId);
      if (metaDataResponse.status === 200) {
        setStoreMetadata(metaDataResponse.data);
        setMetaDataReady(true);
      }
      else {
      }
    }
    catch {
      setStoreMetadata([]);
    }
  }

  //Renders Action column field with related viewtype
  const renderActionButtons = (item: any, viewType: string, index: number | undefined) => {
    if (viewType === "API") {
      return (
        <Stack styles={{ root: { cursor: 'pointer' } }} data-selection-disabled={true} horizontal horizontalAlign="space-between">
          <IconTooltip size={20} onClick={() => toggleApiDeleteDialog(item.id)} iconName="Delete" tooltipContent="Delete" />
        </Stack>
      )
    }
    else {
      if (props.documentStoreDetails)
        if (props.documentStoreDetails.systemGenerated)
          return (
            <Stack styles={{ root: { cursor: 'pointer' } }} data-selection-disabled={true} horizontal >
              <IconTooltip size={20} onClick={() => setEditMetadata(item, index)} iconName="Edit" tooltipContent="Edit Metadata" />
              <div style={{ padding: 5 }}></div>
              <IconTooltip size={20} onClick={() => toggleMetadataRecordDeleteDialog(item, index)} iconName="Delete" tooltipContent="Delete" />
            </Stack>
          )
    }
  }

  //sets selectedMetadataRecord and metadataFieldType from passed metadataRecord and opens the edit metadata modal.
  const setEditMetadata = (metadataRecord: IMetadataSettingsItem, index: number | undefined) => {
    setSelectedEditMetadataRecord(metadataRecord);
    for (let dropdown of metaFieldTypes) {

      if (metadataRecord.type === dropdown.key.toString()) {
        setSelectedMetafieldType(dropdown);
      }
    }
    setEditMetadataModalOpen(true);
    setSelectedEditMetaArrayId(index ? index : 0)
    setIsCreateMetadata(false);
  }

  //sets apiKeyDetails state on specific type and passed new value
  function onChangeApiDetailsTextField(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, type: string, newValue?: string) {
    let _apiKeyDetails = { ...newApiKeyDetails };
    switch (type) {
      case "name": _apiKeyDetails.name = newValue ? newValue : "";
        break;
      case "description": _apiKeyDetails.description = newValue ? newValue : "";
        break
    }

    if (_apiKeyDetails.name.length > 0) {
      setGenerateApiKeyDisabled(false);
    }
    else {
      setGenerateApiKeyDisabled(true);
    }

    setNewApiKeyDetails(_apiKeyDetails);
  }

  //sets editDetails state on specific type and passed new value
  function onChangeTextField(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, type: string, newValue?: string) {
    let _editDetails = _.cloneDeep(editStoreDetails)
    switch (type) {
      case "name": _editDetails.name = newValue ? newValue : "";
        break;
      case "description": _editDetails.description = newValue ? newValue : "";
        break;
      case "siteUrl": _editDetails.siteUrl = newValue ? newValue : "";
        break;
      case "username": _editDetails.username = newValue ? newValue : "";
        break;
      case "password": _editDetails.password = newValue ? newValue : "";
        break;
    }
    if (_editDetails !== editStoreDetails)
      checkValidFields(_editDetails)

  }

  const onChangeMetafields = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, type: string, newValue?: string) => {
    let _editMetadetails = _.clone(selectedEditMetadataRecord);
    switch (type) {
      case "name": _editMetadetails.name = newValue ? newValue : "";
        break
      case "displayName": _editMetadetails.displayName = newValue ? newValue : "";
    }

    validateEditMetaFields(_editMetadetails)
  }

  const validateEditMetaFields = (editMetadata: IMetadataSettingsItem) => {
    let canSave = true;
    if (editMetadata.name.length > 0 && editMetadata.displayName.length > 0) {
      canSave = false;
    }
    setSelectedEditMetadataRecord(editMetadata);
    setEditMetadataSaveDisabled(canSave);
  }

  function checkValidFields(editDetails: IEditStoreFields, _selectedLibraryItem?:IDropdownOption|undefined) {
    let saveDisabled = true;
    let key: keyof typeof editDetails;
    const basicFields = ["name", "description", "siteUrl"];
    const toggleFields = ["keepDoc", "useDest", "useSource"];
    for (key in editDetails) {
      if (basicFields.filter(field => field == key.toString()).length > 0) {
        if (editDetails[key] !== tempEditStoreDetails[key] && editDetails[key].toString().length > 0) {
          saveDisabled = false;
        }
      }

      if (toggleFields.filter(field => field == key.toString()).length > 0) {
        if (editDetails[key] !== tempEditStoreDetails[key]) {
          saveDisabled = false;
        }
      }
    }

    if (isSource && _selectedLibraryItem) {
      setSelectedLibrary(_selectedLibraryItem);
      saveDisabled = false;
    }

    setEditStoreDetails(editDetails);
    SeteditSaveDisabled(saveDisabled);
  }

  const onUpdateDocumentStore = async () => {

    let flags:DocumentStoreFlags = 0
    if(editStoreDetails.useSource){
      flags += 1;
    }
    if(editStoreDetails.useDest){
      flags += 2
    }
    if (editStoreDetails.keepDoc) {
      flags += 16
    }

    let credentials = {};
    if (selectedLibraryItem) {
      credentials = {
        SPUserName: editStoreDetails.username,
        SPPassword: editStoreDetails.password
      };
    }

    const listId = editStoreDetails.useSource ? selectedLibraryItem ? selectedLibraryItem.key : props.documentStoreDetails?.settings.ListId : props.documentStoreDetails?.settings.SiteUrl

    const cSettings = {
      SiteUrl: editStoreDetails.siteUrl,
      ListId: listId
    };

    let tempDocstore = {
      name: editStoreDetails.name,
      description: editStoreDetails.description,
      typeId: editStoreDetails.type,
      settings: cSettings,
      metadataSettings: props.documentStoreDetails?.metadataSettings ? props.documentStoreDetails.metadataSettings : [],
      credentials: credentials,
      flags: flags
    };

    const customerId = props.documentStoreDetails ? props.documentStoreDetails.customerId : 0;
    const storeId = props.documentStoreDetails ? props.documentStoreDetails.id : 0;

    updateDocumentStore(tempDocstore, customerId, storeId).then(response => {
      toastNotify(true, "Document Store Successfully Updated");
      dissmissEditModal();
      props.updateDocumentStoreList();
    }).catch(error => {
      toastNotify(false, "Error Updating Document Store");
    })
  }

  const createUpdateMetadata = async () => {
    if (isCreateMetadata) {
      if (props.documentStoreDetails) {
        let cMetadataFields = _.clone(storeMetadata);
        cMetadataFields.push(selectedEditMetadataRecord);
        try {
          let createResponse = await updateMetadataForDocumentStore(cMetadataFields, props.documentStoreDetails.customerId, props.documentStoreDetails.id);
          dissmissMetaModal()
          if (props.documentStoreDetails)
          getStoreMetadata(props.documentStoreDetails.customerId, props.documentStoreDetails.id)
          toastNotify(false, "Metadata Record Successfully Added");
        }
        catch
        {
          toastNotify(false, "Error Creating Metadata Record");
        }
      }
    }
    else {
      if (props.documentStoreDetails) {
        let cMetadataFields = _.clone(storeMetadata);
        cMetadataFields[selectedEditMetadataArrayId] = selectedEditMetadataRecord;
        try {
          let createResponse = await updateMetadataForDocumentStore(cMetadataFields, props.documentStoreDetails.customerId, props.documentStoreDetails.id)
          dissmissMetaModal()
          if (props.documentStoreDetails)
            getStoreMetadata(props.documentStoreDetails.customerId, props.documentStoreDetails.id)
          toastNotify(true, "Metadata Record Successfully Updated");
        }
        catch
        {
          toastNotify(false, "Error Updating Metadata Record");
        }
      }
    }


  }

  const generateNewApiKey = async () => {
    let apiKeyPostData = {
      name: newApiKeyDetails.name,
      description: newApiKeyDetails.description
    };

    const customerId = props.documentStoreDetails ? props.documentStoreDetails.customerId : 0;
    const storeId = props.documentStoreDetails ? props.documentStoreDetails.id : 0;

    let newApiKeyResponse = await generateApiKey(customerId, storeId, apiKeyPostData);
    if (newApiKeyResponse.status === 200) {
      setGeneratedApiKey(newApiKeyResponse.data);
      setApiKeyGenerated(true);
    }
  }

  const deleteSelectedKey = async () => {
    let customerId = props.documentStoreDetails ? props.documentStoreDetails.customerId : 0;
    let storeId = props.documentStoreDetails ? props.documentStoreDetails.id : 0;
    let deleteKeyResponse = await deleteApiKeyById(customerId, storeId, selectedDeleteApiKeyId);

    if (deleteKeyResponse.status === 204) {
      toggleApiDeleteDialog();
      getStoreApiKeys(customerId, storeId);
      setSelectedDeleteApiKeyId(0);
      toastNotify(true, "ApiKey Successfully Deleted");
    }
    else {
      toastNotify(false, "Error Deleting ApiKey");
    }
  }

  const dismissApiCreateModal = () => {
    if (apiKeyGenerated === true) {
      let customerId = props.documentStoreDetails ? props.documentStoreDetails.customerId : 0;
      let storeId = props.documentStoreDetails ? props.documentStoreDetails.id : 0;
      getStoreApiKeys(customerId, storeId);
      setApiKeyGenerated(false);
      setGeneratedApiKey("");
      setCreateApiKeyModal(false);
      setGenerateApiKeyDisabled(true);
    }
    setGenerateApiKeyDisabled(true);
    setApiKeyGenerated(false);
    setGeneratedApiKey("");
    setCreateApiKeyModal(false);
  }

  const dimissEditView = () => {
    setMetaDataReady(false);
    setDetailsVisible(false);
    setApiVisible(false);
    props.disableEdit();
  }

  const toggleApiDeleteDialog = (apiId: number = 0) => {
    if (apiId !== 0) {
      setSelectedDeleteApiKeyId(apiId);
      setDeleteApiDialogOpen(!isDeleteApiDialogOpen);
    }
    {
      setDeleteApiDialogOpen(!isDeleteApiDialogOpen);
    }
  }

  const deleteSelectedMetadataRecord = async () => {
    if (props.documentStoreDetails) {
      let cMetaRecordList = _.clone(storeMetadata);
      cMetaRecordList.splice(deleteMetadataArrayId, 1);
      let deleteMetaResponse = await updateMetadataForDocumentStore(cMetaRecordList, props.documentStoreDetails.customerId, props.documentStoreDetails.id);
      if (deleteMetaResponse.status === 200) {
        setDeleteMetadataDialogOpen(false);
        getStoreMetadata(props.documentStoreDetails.customerId, props.documentStoreDetails.id);
        toastNotify(true, 'Deleted Metadata Record');
      }
      else {
        toastNotify(false, 'Error Deleting Metadata Record');
      }
    }
  }

  const toggleMetadataRecordDeleteDialog = (selectedRecord: IMetadataSettingsItem, index: number | undefined) => {
    setDeleteMetadataDialogOpen(true);
    setDeleteMetadataArrayId(index ? index : 0);
  }


  const resetEditDocumentStoreDetails = () => {
    let documentStoreDetails = _.clone(editStoreDetails);
    documentStoreDetails.username = "";
    documentStoreDetails.password = "";
    setEditStoreDetails(documentStoreDetails);
    setEditStoreModalOpen(false);
  }

  const dialogContentProps = {
    type: DialogType.normal,
    closeButtonAriaLabel: 'Close',
    subText: 'API keys once deleted cannot be recovered. Please confirm by clicking "Delete" button below to continue',
  };


  const dialogMetadataProps = {
    type: DialogType.normal,
    closeButtonAriaLabel: 'Close',
    subText: 'Metadata records once deleted cannot be recovered. Please confirm by clicking "Delete" button below to continue',
  };

  function onStoreToggleChange(ev: React.MouseEvent<HTMLElement>, checked?: boolean) {
    let _storeDetails = _.clone(editStoreDetails);
    const toggleType = ev.currentTarget.id;
    switch (toggleType) {
      case "keepDoc": _storeDetails.keepDoc = !_storeDetails.keepDoc;
        break;
      case "useDest": _storeDetails.useDest = !_storeDetails.useDest;
        break;
      case "useSource": _storeDetails.useSource = !_storeDetails.useSource;
        break;
    }

    if (_storeDetails.useSource === true && _storeDetails.useDest === true && toggleType === "useDest") {
      _storeDetails.keepDoc = false;
      _storeDetails.useSource = false;
    }
    if (_storeDetails.useSource === true && _storeDetails.useDest === true && toggleType === "useSource") {
      _storeDetails.useDest = false;
    }

    checkValidFields(_storeDetails);
  }

  const dissmissEditModal = () => {
    setEditStoreDetails(editStoreDetails)
    setEditStoreModalOpen(false);
    SeteditSaveDisabled(true);
    setSelectedLibrary(undefined);
    setLibraryOptions([]);
    if(props.documentStoreDetails)
    getStoreTypeDetails(props.documentStoreDetails.customerId, props.documentStoreDetails.typeId);
  }

  const onMetafieldtypeChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined): void => {
    let cEditMetadataFields = _.clone(selectedEditMetadataRecord);
    cEditMetadataFields.type = item ? item.key.toString() : "";
    setSelectedMetafieldType(item);
    validateEditMetaFields(cEditMetadataFields)
  };

  function onChangeMetaRequiredCheck(ev: React.MouseEvent<HTMLElement>, checked?: boolean) {
    let cSelectedMetaRecord = _.clone(selectedEditMetadataRecord)
    cSelectedMetaRecord.required = checked ? checked : false;
    validateEditMetaFields(cSelectedMetaRecord)
  }

  const dissmissMetaModal = () => {
    setEditMetadataModalOpen(false);
    setSelectedEditMetadataRecord(defaultMetadataField);
    setSelectedMetafieldType(undefined);
    setEditMetadataSaveDisabled(true);
  }

  const connectToDocumentStore = async (storeTypeId: string) => {
    let _storeDetails = _.clone(editStoreDetails);
    let connectPostData: IConnectLibraryDetails = {
      Credentials: { SPUserName: _storeDetails.username, SPPassword: _storeDetails.password },
      Query: "sp.web.lists.documentLibraries",
      Settings: { SiteUrl: editStoreDetails.siteUrl }
    }

    const customerId = props.customerId ? props.customerId : 0;
    try {
      const queryResponse = await executeStoreTypeQuery(customerId, storeTypeId, connectPostData)
      let libraryDropdownArray: IDropdownOption[] = []
      for (let library of queryResponse.data) {
        const dropdownItem: IDropdownOption = {
          key: library.id,
          text: library.title
        }

        libraryDropdownArray.push(dropdownItem);
      }

      setLibraryOptions(libraryDropdownArray);
    }
    catch (error) {
      toastNotify(false, 'Error connecting to library');
    }
  }

  const isTsgAdmin = () => {
    let isTsgAdmin = false
    if (props.currentUserLevels) {
        if (props.currentUserLevels === 1) {
          isTsgAdmin = true;
        }
    }
    return isTsgAdmin
  }


  const onChangeLibraryDropdown = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined,): void => {
    let _editDetails = _.cloneDeep(editStoreDetails)
    // _editDetails.documentLibrary = item?.text ? item.text : "";
    checkValidFields(_editDetails, item);
  };

  const documentStoreEditModalView = () => {
    let connectDisabled = editStoreDetails.username.length > 0 && editStoreDetails.password.length > 0 ? false : true
    return (
      <Stack styles={{ root: { width: 600 } }} tokens={modalInfoStackTokens}>
        <TextField label="Type" disabled={true} value={storeTypeDetails.name} onChange={(ev, value) => onChangeTextField(ev, "type", value)} description="The type of this document store" />
        <TextField label="Name" value={editStoreDetails.name} onChange={(ev, value) => onChangeTextField(ev, "name", value)} description="The name of this document store" />
        <TextField multiline resizable={false} label="Description" value={editStoreDetails.description} onChange={(ev, value) => onChangeTextField(ev, "description", value)} description="The description of this document store" />
        <TextField label="Site URL" value={editStoreDetails.siteUrl} onChange={(ev, value) => onChangeTextField(ev, "siteUrl", value)} description="The URL to the SharePoint online site" />
        {isSource && (
          <Stack.Item>
            <TextField label="Current Document Library" disabled={true} value={editStoreDetails.documentLibrary} />
            <TextField label="Username" value={editStoreDetails.username} onChange={(ev, value) => onChangeTextField(ev, "username", value)} description="Username to access current site for document libraries" />
            <TextField label="Password" value={editStoreDetails.password} onChange={(ev, value) => onChangeTextField(ev, "password", value)} description="Password to access current site for document libraries" type="password" canRevealPassword  />
            <PrimaryButton disabled={connectDisabled} onClick={() => connectToDocumentStore(storeTypeDetails.typeId)} text="Connect" />
          </Stack.Item>
        )}
        {libraryOptions.length > 0 && (<Dropdown label="Selected Library" onChange={onChangeLibraryDropdown} selectedKey={selectedLibraryItem ? selectedLibraryItem.key : undefined} options={libraryOptions} />)}
        <Stack tokens={toggleStackTokens} horizontal>
          {isSource && (<Toggle id="useSource" label="Use as source" onChange={onStoreToggleChange} checked={editStoreDetails.useSource} />)}
          <Toggle id="useDest" label="Use as destination" onChange={onStoreToggleChange} checked={editStoreDetails.useDest} />
          {editStoreDetails.useSource && (<Toggle id="keepDoc" label="Keep documents after successful move" onChange={onStoreToggleChange} checked={editStoreDetails.keepDoc} />)}
        </Stack>
        <Stack tokens={saveCancelStackTokens} horizontal>
          <PrimaryButton text="Save" disabled={editSaveDisabled} onClick={() => onUpdateDocumentStore()} />
          <PrimaryButton text="Cancel" onClick={() => dissmissEditModal()} />
        </Stack>
      </Stack>
    )
  }

  return (
    <div style={{ minWidth: 1000, height: 500, display: 'flex' }}>
      <Pivot styles={{ root: { minWidth: 900 } }} aria-label="Store Details">
        <PivotItem
          headerText="Details"
          headerButtonProps={{
            'data-order': 1,
            'data-title': 'Store Details',
          }}>
          {detailsVisible ?
            <Stack tokens={modalInfoStackTokens}>
              <TextField label="Type" value={storeTypeDetails.name} description="The type of this document store" />
              <TextField label="Name" value={props.documentStoreDetails?.name} description="The name of this document store" />
              <TextField multiline resizable={false} label="Description" value={props.documentStoreDetails?.description} description="The description of this document store" />
              {!systemManaged && (<Stack tokens={{ childrenGap: 10 }}><TextField label="Site URL" value={props.documentStoreDetails?.settings.SiteUrl} description="The URL to the SharePoint online site" />
                {isSource && (<TextField label="Document Library" value={editStoreDetails.documentLibrary} description="The document library that contains documents to be moved, or copied into" />)}
                <PrimaryButton text="Edit Settings" onClick={() => setEditStoreModalOpen(true)} />
              </Stack>)}
            </Stack> :
            <Spinner styles={spinnerStyle} />}
          {/* Start edit document store modal */}
          <Modal
            isOpen={editStoreModalOpen}
            onDismiss={() => dissmissEditModal()}>
            {documentStoreEditModalView()}
          </Modal>
          {/* end edit document store modal */}
        </PivotItem>
        <PivotItem headerText="API Keys">
          {apiVisible ? <Stack styles={{ root: { paddingTop: 20 } }} tokens={modalInfoStackTokens}>
            <CommandBarButton style={{ height: 30, width: 100 }} iconProps={{ iconName: "Add" }} text="Create" onClick={() => setCreateApiKeyModal(true)} />
            <DetailsList styles={{ root: { marginTop: -15 } }} items={storeApiKeys} columns={defaultApiColumns} />
          </Stack> :
            <Spinner styles={spinnerStyle}/>}
          {/* Start delete API key dialog */}
          <Dialog
            hidden={!isDeleteApiDialogOpen}
            onDismiss={() => toggleApiDeleteDialog()}
            dialogContentProps={dialogContentProps}
          >
            <DialogFooter>
              <PrimaryButton onClick={() => deleteSelectedKey()} text="Delete" />
              <DefaultButton onClick={() => toggleApiDeleteDialog()} text="Cancel" />
            </DialogFooter>
          </Dialog>
          {/* End delete API key dialog */}
          {/* Start create API key modal */}
          <Modal isOpen={createApiKeyModal} onDismiss={() => dismissApiCreateModal()}>
            <Label styles={labelStyles}>Generate API Key</Label>
            <Stack tokens={modalInfoStackTokens}>
              <TextField label="Name *" disabled={apiKeyGenerated} onChange={(ev, value) => onChangeApiDetailsTextField(ev, "name", value)} description="This is a required field" />
              <TextField label="Description" disabled={apiKeyGenerated} onChange={(ev, value) => onChangeApiDetailsTextField(ev, "description", value)} description="Enter description for this API Key" />
              {apiKeyGenerated && (<TextField value={generatedApiKey} description="Copy this API key since once this dialog is closed, you will not be able to copy the generated key later" />)}
              <Stack horizontalAlign="space-between" horizontal>
                {!apiKeyGenerated && (<PrimaryButton disabled={generateApiKeyDisabled} text="Generate" onClick={generateNewApiKey} />)}
                <DefaultButton text="Close" onClick={() => dismissApiCreateModal()} />
              </Stack>
            </Stack>
          </Modal>
          {/* End create API key modal */}
        </PivotItem>
        <PivotItem headerText="Metadata Settings">
          <Stack tokens={modalInfoStackTokens}>
            {metaDataReady ? <Stack>{props.documentStoreDetails?.systemGenerated && (
              <div><CommandBarButton style={{ height: 40 }} iconProps={{ iconName: "Add" }} onClick={() => { setEditMetadataModalOpen(true); setIsCreateMetadata(true); }} text="New" /></div>
            )}<DetailsList items={storeMetadata} columns={defaultMetadataColumns} /></Stack> : <Spinner styles={spinnerStyle} />
            }
          </Stack>
          {/* Start edit metadata modal */}
          <Modal isOpen={editMetadataModalOpen} onDismiss={() => dissmissMetaModal()}>
            <Label styles={labelStyles}>{isCreateMetadata ? "New Metadata Field" : "Edit Metadata Field"}</Label>
            <Stack tokens={modalInfoStackTokens}>
              <TextField label="Name" value={selectedEditMetadataRecord.name} onChange={(ev, value) => onChangeMetafields(ev, "name", value)} />
              <TextField label="Display Name" value={selectedEditMetadataRecord.displayName} onChange={(ev, value) => onChangeMetafields(ev, "displayName", value)} />
              <Dropdown
                label="Type"
                selectedKey={selectedMetafieldType ? selectedMetafieldType.key : undefined}
                // eslint-disable-next-line react/jsx-no-bind
                onChange={onMetafieldtypeChange}
                placeholder="Select an option"
                options={metaFieldTypes}
                styles={dropdownStyles}
              />
              <Toggle label="Required" checked={selectedEditMetadataRecord.required} onChange={onChangeMetaRequiredCheck} />
              <Stack horizontal horizontalAlign='end' tokens={{ childrenGap: 10 }}>
                <PrimaryButton text="Save" disabled={editMetadataSaveDisabled} onClick={createUpdateMetadata} />
                <DefaultButton text="Cancel" onClick={() => dissmissMetaModal()} />
              </Stack>
            </Stack>
          </Modal>
          {/* End edit metadata modal */}
          {/* Start Delete metadata dialog */}
          <Dialog
            hidden={!deleteMetadataDialogOpen}
            onDismiss={() => setDeleteMetadataDialogOpen(false)}
            dialogContentProps={dialogMetadataProps}
          >
            <DialogFooter>
              <PrimaryButton onClick={deleteSelectedMetadataRecord} text="Delete" />
              <DefaultButton onClick={() => setDeleteMetadataDialogOpen(false)} text="Cancel" />
            </DialogFooter>
          </Dialog>
          {/* End Delete metadata dialog */}
        </PivotItem>
        {isTsgAdmin() && (<PivotItem headerText='Subscriptions'>
          <SubscriptionsTab customerId={props.documentStoreDetails ? props.documentStoreDetails.customerId : 0} storeId={props.documentStoreDetails ? props.documentStoreDetails.id : 0} />
        </PivotItem>)}
      </Pivot>
      <div style={{ textAlign: 'right', fontSize: 20, paddingTop: 8 }}>
        <IconButton iconProps={{ iconName: 'Cancel', style: { fontSize: 20 } }} onClick={() => dimissEditView()} />
      </div>
    </div>
  )
}

export default DocumentStoreEditView;

const metaFieldTypes = [
  { key: "text", text: "Text" },
  { key: "longText", text: "Long Text" },
  { key: "number", text: "Number" },
  { key: "date", text: "Date" },
  { key: "person", text: "person" }
]

const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 300 } };

const toastNotify = (success: boolean, message: string) => {

  const toastOptions: ToastOptions = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  }

  if (success) {
    toast.success(message, toastOptions);
  }
  else {
    toast.error(message, toastOptions);
  }
}