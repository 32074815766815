import { StoreStatus } from "./storeStatistics";

export enum HistoryEntryLevel {
    Verbose,
    Information,
    Warning,
    Error
}

export interface DocumentHistoryRecord {
  externalId: string;

  id: number;

  isActive: boolean;

  metadata: object;

  name: string;

  status: StoreStatus;

  storeId: number;

  submittedDate: string;

  url: string;
}

export interface IDocumentItem {
  id: number;
  name: string;
  storeId: number;
  status: StoreStatus;
  submittedDate: string;

}

/**
    * Represents a Document History Entry
 */
 export interface DocumentHistoryEntry {

    /**
        * Specifies the id of the Document History Entry
    */
      id: number;
  
    /**
        * Specifies the store id of the Document History Entry
    */
      storeId: number;
  
    /**
        * Specifies the document id of the Document History Entry
    */
      documentId: number;
  
    /**
        * Specifies the date of the Document History Entry
    */
      date: string;

    /**
        * Specifies the level of the Document History Entry
    */
      level: HistoryEntryLevel;
    
    /**the message number of the Document History Entry
        * Specifies the message number of the Document History Entry
    */
      messageNumber: number;

    /**
        * Specifies the message of the Document History Entry
    */
      message: string;

  
  }

  export declare type DocumentHistoryEntryList = DocumentHistoryEntry[];
  
  
  