import * as React from 'react';
import {DefaultEffects, Stack} from '@fluentui/react'
import { RouteComponentProps } from 'react-router-dom';
import {connect} from 'react-redux';
import { ApplicationState } from '../store';
import * as AuthStore from '../store/Auth';
import { SignInButton, SignOutButton } from '../components/Layouts/AuthButtons';
import { MsalContext } from '@azure/msal-react';

type AuthProps = 
  AuthStore.authState &
  typeof AuthStore.actionCreators &
  RouteComponentProps<{}>;

  class AccessDenied extends React.PureComponent<AuthProps> {
    static contextType = MsalContext;

    private getAccessDeniedText(){
      let deniedText = "";
      if(this.props.Customers.length === 0 && this.props.User.emailAddress.length > 0)
      {
        deniedText = "Your Microsoft account is not assigned, please request access or sign in to use a different account";
      }
      else
      {
        deniedText = "Your Microsoft account does not have access to this application, click sign in or out to use a different account."
      }
      return deniedText;
    }

    public render(){
      const isAuthenticated = this.context.accounts.length > 0;
      return (
          <React.Fragment>
          <Stack >
            <Stack tokens={{childrenGap: 30}} style={{boxShadow: DefaultEffects.elevation64, width: "20vw", height: 200, padding: 20, position: "fixed", top: "40%", left: "40%"}}>
                <Stack.Item style={{fontSize: 18}}>{this.getAccessDeniedText()}</Stack.Item>
                <Stack horizontal horizontalAlign='end' tokens={{childrenGap: 20}}>
                  {isAuthenticated && (<Stack.Item>
                    <SignOutButton />
                  </Stack.Item>)}
                  <Stack.Item>
                    <SignInButton />
                  </Stack.Item>
                </Stack>
            </Stack>
          </Stack>
          </React.Fragment>
      )}
  }

export default connect(
 (state: ApplicationState) => state.auth,
 AuthStore.actionCreators 
)(AccessDenied as any);




