import * as React from 'react';
import './initialLoadingMenu.css';
import dcLogo from '../Images/dclogo.png';

const InitalLoadingMenu = () => (
        <div className="iconContainer">
            <div className="iconloading">
                <img width={256} height={256} src={dcLogo}></img>
            </div>
        </div>
    )

export default InitalLoadingMenu;