
export enum StoreStatus {
    Queued = 0,
    ContentMissing = 1,
    InProgress = 2,
    Processed = 3,
    Deleted = 4,
    ProcessingError = 5,
    Moved = 6,
    TimeoutExceeded = 7,
    MissingRequiredFields = 8,
    NotMoved = 9,
    AwaitingResponse = 10,
    Resolved = 11,
}

export interface IStoreStatistic {
    completed: number;
    inError: number;
    inProgress: number;
}

export interface IStoreDocumentStatistic {
    status: StoreStatus;
    count: number;
}

