import * as React from 'react';
import {IIconProps, IconButton, Modal, Spinner, SpinnerSize} from '@fluentui/react';
import { Dropdown, DropdownMenuItemType, IDropdownStyles, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import './documentHistoryTimeline.css';
import {getDocumentHistoryByDocumentId} from '../../utilities/axiosUtility';
import { DocumentHistoryEntry, DocumentHistoryEntryList, HistoryEntryLevel} from '../../interfaces/documentHistoryEntry';
import { toast } from 'react-toastify';
import { getFormattedDate } from '../../utilities/helpers';

export interface IDocumentHistoryModalProps {
 isOpen: boolean;
 documentId: number;
 customerId: number;
}

const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 275 },
  };

export interface IDocumentHistoryModalState{

}

 const DocumentHistoryModal = (props: {isOpen: boolean, customerId: number, documentId: number, dismissModal: any}) => {

    const levelFilter = process.env.REACT_APP_DOC_HISTORY_LEVEL_FILTER
    const [isOpen, setIsOpen] = React.useState(false)
    const [documentHistoryList, setDocumentHistoryList] = React.useState<DocumentHistoryEntryList>([])
    const [fullDocumentHistoryList, setFullDocumentHistoryList] = React.useState<DocumentHistoryEntryList>([]);
    const [selectedKeys, setSelectedKeys] = React.useState<string[]>(levelFilter? levelFilter.split(",") : ['Information', 'Warning', 'Error']);
    const [selectedMoreId, setSelectedMoreId] = React.useState<number>(-1)

    const options: IDropdownOption[] = [
        { key: 'Verbose', text: 'Verbose' },
        { key: 'Information', text: 'Information' },
        { key: 'Warning', text: 'Warning' },
        { key: 'Error', text: 'Error' },
      ];

    React.useEffect(() => {
        setIsOpen(props.isOpen)
        if(props.isOpen == true){
            getDocumentHistoryByDocumentId(props.customerId, props.documentId).then((response:any) => {
                setFullDocumentHistoryList(response.data);
                searchDocumentHistory(selectedKeys, response.data);
            }).catch(error => {
                toast.error("Error Retrieving Document History. Please Retry")
                dismissCurrentModal();
            })
        }
    },[props.isOpen])


    function renderDate(datestring: string){
        var cDate = Date.parse(datestring);
        let current_datetime = new Date(cDate)
        let formatted_date = current_datetime.getFullYear() + "-" + (current_datetime.getMonth() + 1) + "-" + current_datetime.getDate() + " " + current_datetime.getHours() + ":" + current_datetime.getMinutes() + ":" + current_datetime.getSeconds() 
        return formatted_date;
    }

    const onDropDownChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined): void => {
        if (item) {
            var cSelectedKeys =  item.selected ? [...selectedKeys, item.key as string] : selectedKeys.filter(key => key !== item.key)
            setSelectedKeys(
                cSelectedKeys,
            );

            searchDocumentHistory(cSelectedKeys)
          }
    }

    const searchDocumentHistory = (cSelectedKeys: string[], documentHistoryList: DocumentHistoryEntryList = fullDocumentHistoryList) => {
            var matchedDocumentList:DocumentHistoryEntryList = documentHistoryList.filter(function (el) {
                return cSelectedKeys.indexOf(HistoryEntryLevel[el.level]) >= 0;
            })
        setDocumentHistoryList(matchedDocumentList)
    }

    const cancelIcon: IIconProps = { iconName: 'Cancel' };

    const barColour = (level: string) => {
        switch(level){
            case "Verbose": return "#a0aeb2";
            case "Information": return "#00ad56";
            case "Warning": return "#ffaa44";
            case "Error": return "#d13438";
        }
    }


    const documenHistoryMessageRender = (documentHistoryMessage: string, documentIndex: number) => {
        if(documentHistoryMessage.length < 100)
        {   
            return (
                documentHistoryMessage
            )
        }
        else
        {
            const reducedDocumentHistoryMessage = documentHistoryMessage.substring(0, 100);
            if(documentIndex === selectedMoreId)
            {
                return (
                    <div>{documentHistoryMessage} <a style={{textDecoration: 'underline', color: 'blue', cursor: 'pointer'}} onClick={() => {setSelectedMoreId(-1)}}> Less</a></div> 
                    
                )
            }
            else
            {
            return (
                <div>{reducedDocumentHistoryMessage} <a style={{textDecoration: 'underline', color: 'blue', cursor: 'pointer'}} onClick={() => {setSelectedMoreId(documentIndex)}}> More</a></div> 
            )
            }
        }
    }

    const dismissCurrentModal = () => {
        setSelectedMoreId(-1);
        props.dismissModal()
    }

    return(
        <Modal 
        isOpen={isOpen}
        onDismiss={() => dismissCurrentModal()}
        > 
        <div style={{display: 'flex', padding: 5, width: 600}}>
            <IconButton
                iconProps={cancelIcon}
                ariaLabel="Close popup modal"
                onClick={() => dismissCurrentModal()}
            />
            <div style={{marginLeft: "34vw"}}>
                <Dropdown
                placeholder="Select options"
                defaultSelectedKeys={selectedKeys}
                multiSelect
                options={options}
                onChange={(e, a) => onDropDownChange(e, a)}
                styles={dropdownStyles}
                />
            </div>
        </div>
        <div style={{height: 600, overflowY: 'scroll', width: "52vw"}}>
            {documentHistoryList.map((documentHistory, id) => (
                <div key={id} className="timeline-container">
                    <div className="timeline-date">
                        {getFormattedDate(documentHistory.date)}
                    </div>
                        <div className="timeline-line">
                        </div>
                        <div className="timeline-lineh">
                        </div>
                    <div style={{borderColor: barColour(HistoryEntryLevel[documentHistory.level])}} className="timeline-details">
                        <div className="timeline-level">
                            {HistoryEntryLevel[documentHistory.level]}
                        </div>
                        <div className="timeline-info">
                            {documenHistoryMessageRender(documentHistory.message, id)}
                        </div>
                    </div>
                </div>
            ))}
        </div>
        </Modal>
    )
}

export default DocumentHistoryModal;

