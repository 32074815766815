import * as React from 'react';
import { TextField, ComboBox, IComboBoxOption, IStyleSet, Label, ILabelStyles, Pivot, PivotItem, MessageBar, MessageBarType} from '@fluentui/react'
import { Stack, IStackProps, IStackStyles } from '@fluentui/react/lib/Stack';
import { Dropdown, DropdownMenuItemType, IDropdownStyles, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { axiosRouter } from '../utilities/axiosUtility';
import RequestConverter from '../utilities/RequestConverter'


const options: IDropdownOption[] = [
    { key: 'get', text: 'Get' },
    { key: 'post', text: 'Post' },
    { key: 'patch', text: 'Patch' },
    { key: 'put', text: 'Put' },
    { key: 'delete', text: 'Delete' },
]

const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 150},
};

const stackStyles: Partial<IStackStyles> = { root: { width: 1080, padding: 50 } };

const stackTokens = { childrenGap: 25 };

const columnProps: Partial<IStackProps> = {
    tokens: { childrenGap: 15 },
    styles: { root: { width: 500, paddingLeft: 100 } },
};



export default class ApiRequestor extends React.Component {

    public state = {
        url: '',
        selectedRequestType: "",
        requestData: '',
        resultData: '',
        errorNotificationVisable: false,
        errorNoticationText: '',
        successNotificationVisable: false,
        successNotificationText: '',
        spinnerVisable: false,
    }


    onTextFieldChangeUrl = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void => {
        this.setState({ url: newValue || '' })
    }

    onTextFieldChangeRequestData = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined ): void => {
        this.setState({ requestData: newValue || '' })
    }

    onDropDownChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined): void => {
        this.setState({selectedRequestType: item?.text})
    }

    checkVaildRequest() {
        if (this.state.selectedRequestType == "") {
            this.setState({ errorNoticationText: "Please select a request type", errorNotificationVisable: true })
            this.clearnNotifications()
            return false
        }
        if (this.state.selectedRequestType == "Post" && this.state.requestData == ""  || this.state.selectedRequestType == "Patch" && this.state.requestData == "" || this.state.selectedRequestType == "Put" && this.state.requestData === "") {
            this.setState({ errorNoticationText: 'Please enter Request Data for Post, Put and Patch Requests', errorNotificationVisable: true })
            this.clearnNotifications()
            return false
        }
        else {
            if (this.state.requestData !== "") {
                try {
                    JSON.parse(this.state.requestData)
                } catch (e) {
                    this.setState({ errorNoticationText: 'Invalid Json In Request Data', errorNotificationVisable: true })
                    this.clearnNotifications()
                    return false
                }
                }
            return true
        }
    }



    toggleSuccessNotification() {
        this.setState({ successNotificationVisable: !this.state.successNotificationVisable})
    }

    toggleErrorNotification() {
        this.setState({ errorNotificationVisable: !this.state.errorNotificationVisable})
    }

    clearnNotifications() {
        setTimeout(() => {
            this.setState({ errorNotificationVisable: false, successNotificationVisable: false})
        },3000)
    }

    sendRequest() {
        if (this.checkVaildRequest() == true) {
            this.setState({ spinnerVisable: true})
            axiosRouter(this.state.url, this.state.selectedRequestType, this.state.requestData).then((response:any) => {
                if (response.status >= 200 && response.status <= 240) {
                    var responseString = JSON.stringify(response)    
                    this.setState({
                        spinnerVisable: false,
                        resultData: responseString,
                        successNotificationText: 'Response Success with code: ' + response.status.toString(),
                        successNotificationVisable: true
                    })
                }
                else {
                    var responseString = JSON.stringify(response.response)    
                    this.setState({
                        spinnerVisable: false,
                        resultData: responseString,
                        errorNoticationText: 'Response Error with code: ' + response.response.status.toString(),
                        errorNotificationVisable: true
                    })
                }
                this.clearnNotifications()

            }).catch(error => {
                this.setState({resultData: error})
            })
        }
    }

    public render() {
        return (
            <React.Fragment>
                <Stack horizontal tokens={stackTokens} styles={stackStyles}>
                    <Stack {...columnProps}>
                        <TextField prefix={window.location.origin} name="url" onChange={this.onTextFieldChangeUrl} value={this.state.url} label="URL" />
                        <Dropdown
                          placeholder="Select A Request"
                            label="Request Type"
                            onChange={(e,a) => this.onDropDownChange(e,a)}
                          options={options}
                          styles={dropdownStyles}
                        />
                        <TextField label="Request Data" name="requestData" onChange={this.onTextFieldChangeRequestData} value={this.state.requestData} multiline autoAdjustHeight />
                        <PrimaryButton onClick={() => this.sendRequest()} text="Send Request" />
                        {this.state.errorNotificationVisable == true && (< MessageBar messageBarType={MessageBarType.error}
                            isMultiline={false}
                            onDismiss={() => this.toggleErrorNotification()}
                            dismissButtonAriaLabel="Close">
                            {this.state.errorNoticationText}
                        </MessageBar>)}
                        {this.state.successNotificationVisable == true && (<MessageBar messageBarType={MessageBarType.success}
                            isMultiline={false}
                            onDismiss={() => this.toggleSuccessNotification()}
                            dismissButtonAriaLabel="Close">
                            {this.state.successNotificationText}
                        </MessageBar>)}
                    </Stack>
                    <Stack {...columnProps}>
                        <RequestConverter response={this.state.resultData} spinnerVisable={this.state.spinnerVisable} />
                    </Stack>
                </Stack>
            </React.Fragment>
        );
    }
};
