import { useDispatch } from 'react-redux';
import { actionCreatorsHistory } from '../store/DocumentHistory';
import { push } from 'connected-react-router'
import { QueryFilter, queryCondition, DocumentQuery, ascendingDescending } from '../interfaces/documentQuery';
import { getStatusId } from '../utilities/helpers';
import DocumentHistory from '../pages/DocumentHistory';
import { Dispatch } from 'react';
import { StoreStatus } from '../interfaces/storeStatistics';


//creates new query using query filters based on selected status and what condition was selected to. 
//this is then dispatched to the document history store to process the query, user is then redirected to the document history page.
export const redirectToSelectedStatus = (selectedStatus: any, storeId: number, customerId: number, dispatch: Dispatch<any>) => {


    let newQueryStoreFilter: QueryFilter = {
        field: "storeId",
        condition: queryCondition.isEqual,
        value: storeId.toString(),
    }
    let newQueryStatusFilter: QueryFilter = {
        field: "status",
        condition: queryCondition.isEqual,
        value: selectedStatus.status
    }


    let newQuery: DocumentQuery = {
        storeId: 0,
        filters: [newQueryStoreFilter, newQueryStatusFilter],
        sort: { field: "submittedDate", ascDesc: ascendingDescending.descending },
        pageSize: 18,
        pageNumber: 1,
        excludeDeleted: false
    }
    dispatch(actionCreatorsHistory.getDocumentStoreDropdown());
    dispatch(actionCreatorsHistory.getData(customerId, newQuery));
    dispatch(actionCreatorsHistory.setIsRedirect(true));
    dispatch(push('/customer/documenthistory'))
}

