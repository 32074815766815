import * as React from 'react';
import { DonutChart, IChartProps, IChartDataPoint, HorizontalBarChart } from '@uifabric/charting';
import { StorageMetrics } from '../../interfaces/storageMetrics';
import { DocumentStoreQuota } from '../../interfaces/documentStore';
import { getStorageMetricsByCustomerIdAndStoreId, getStoreTotalStats } from '../../utilities/axiosUtility';
import { useDispatch } from 'react-redux';
import { actionCreators } from '../../store/DocumentStore';
import { MotionAnimations } from '@fluentui/theme/lib/motion/FluentMotion';
import { push } from 'connected-react-router'
import { DefaultEffects, SpinnerSize, Spinner, TooltipHost } from '@fluentui/react';
import "./CSS/quotachart.css"
import { IconTooltip } from '../../utilities/helpers';
import { redirectToSelectedStatus } from '../../dispatchers/documentHistoryDispatcher';
import { UserPermissionLevels } from '../../interfaces/customerAdministrator';
import { StoreStatus } from '../../interfaces/storeStatistics';
import { toast, ToastOptions } from 'react-toastify';

//Converts num, to its size view value based on the decimals num passed
function formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

//Converts num, to its size view value based on the decimals num passed
function formatBytesNumber(maxBytes: number, bytes: number, decimals = 2) {
    if (bytes === 0) return 0;
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const maxStorageI = Math.floor(Math.log(maxBytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, maxStorageI)).toFixed(dm))
}


const QuotaChart = (props: { customerId: number, store: DocumentStoreQuota, currentUserLevel: UserPermissionLevels }) => {

    //access to redux store to access functions
    const dispatch = useDispatch();
    const hideRatio: boolean[] = [true, false];

    const [quotaData, setQuotaData] = React.useState<StorageMetrics>({ maxStorageSize: 0, remainingStorageSize: 0, usedStorageSize: 0 })
    const [statusData, setStatusData] = React.useState<IChartProps[]>([]);
    const [initializing, setInitializing] = React.useState<boolean>(true);

    const points: IChartDataPoint[] = [
        { legend: formatBytes(quotaData.remainingStorageSize) + ' Remaining', data: formatBytesNumber(quotaData.maxStorageSize, quotaData.remainingStorageSize, 4), color: '#0078D4', xAxisCalloutData: 'Remaining Storage' },
        { legend: formatBytes(quotaData.usedStorageSize) + ' Used', data: formatBytesNumber(quotaData.maxStorageSize, quotaData.usedStorageSize, 4), color: '#eb4034', xAxisCalloutData: 'Used Storage' },
    ];

    const chartTitle = 'Quota Data';

    React.useEffect(() => {
        setInitializing(true);
        setStatusData([]);
        getStorageMetricsByCustomerIdAndStoreId(props.customerId, props.store.id).then(response => {
            setQuotaData(response.data)
            getStoreStats()
        }).catch(error => {
            toastNotify(false, error)
        })
    // eslint-disable-next-line @typescript-eslint/no-use-before-define, react-hooks/exhaustive-deps
    }, [props.store])


    const data: IChartProps = {
        chartTitle: chartTitle,
        chartData: points,
    };

    //creates a list of chart props if stats exist
    const getStoreStats = async () => {
        getStoreTotalStats(props.customerId, props.store.id).then(storeStatsResponse => {
        let statusArray: IChartProps[] = []
        if (storeStatsResponse.status === 200) {
            if (storeStatsResponse.data.length > 0) {
                for (var stats of storeStatsResponse.data) {
                    const _stat = stats;
                    const statusRecord: IChartProps = {
                        chartTitle: StoreStatus[stats.status],
                        chartData: [{
                            legend: StoreStatus[stats.status],
                            horizontalBarChartdata: { x: stats.count, y: 100000 },
                            onClick: () => { redirectToSelectedStatus(_stat, props.store.id, props.customerId, dispatch) },
                        }],
                    }
                    statusArray.push(statusRecord);
                }
                setStatusData(statusArray);
                setInitializing(false);
            }
            else
            {
                setInitializing(false);
            }
        }
        }).catch((error) => {
            setInitializing(false);
            toastNotify(false, `Error Getting Store Stats For ${props.store.name}`)
        })
    }

    //connects to DocumentStore actionCreators, directs user to route
    const onClickManageDocumentStore = () => {
        dispatch(actionCreators.setEditMode(true))
        dispatch(actionCreators.setSelectedDocumentStore(props.store))
        dispatch(push('/customer/documentstores'))
    }

    const sourceDes = (store: DocumentStoreQuota) => {
        let srcdes = ""
        if(store.flags % 2 === 0)
        {
            srcdes = "Destination";
        }
        else
        {
            srcdes = "Source"
        }

        return srcdes
    }

    const maxStorageSizeStyled = formatBytes(quotaData.maxStorageSize, 0);

    return (
        <div style={{ minHeight: "26rem", width: "20rem", padding: 10, boxShadow: DefaultEffects.elevation8, animation: MotionAnimations.fadeIn }}>
            {initializing ? 
            <div style={{paddingTop: "45%"}}>
                <Spinner styles={{ circle: { width: 96, height: 96 } }} size={SpinnerSize.large} />
            </div> 
            : 
            <div>
                <div style={{ padding: 5, paddingBottom: 10, borderBottom: "2px solid lightgrey" }}>
                    <TooltipHost
                        id="quotaname"
                        content={props.store.name}
                    >
                        <div style={{ display: 'flex', width: "120%" }}>
                            <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: "80%" }}>
                                {props.store.name}
                            </div>
                            <div><IconTooltip size={12} onClick iconName={sourceDes(props.store) === "Source" ? "Source" : "GroupedDescending"} tooltipContent={sourceDes(props.store)} /></div>
                        </div>
                    </TooltipHost>
                    <div style={{ paddingTop: 10 }}></div>
                    <TooltipHost
                        id="quotades"
                        content={props.store.description}
                    >
                        <div style={{ color: 'lightgrey', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{props.store.description}</div>
                    </TooltipHost>
                </div>
                <div style={{ borderBottom: "2px solid lightgrey", paddingTop: 5, width: "90%", margin: "10px 0px 0px 10px" }}>
                    <DonutChart
                        chartLabel={chartTitle}
                        data={data}
                        innerRadius={55}
                        href={''}
                        height={220}
                        width={300}
                        valueInsideDonut={maxStorageSizeStyled}
                        legendProps={{
                            overflowProps: {
                                focusZoneProps: {
                                    'aria-label': 'Legends container',
                                },
                            },
                            allowFocusOnLegends: true,
                        }}
                    />

                </div>
                <div style={{ padding: 10 }}>
                    <HorizontalBarChart styles={{ chart: { cursor: "pointer" } }} data={statusData} hideRatio={hideRatio} width={300} />
                </div>
                <div style={{ textAlign: 'center' }}>
                    {props.currentUserLevel < 3 && (<div onClick={() => onClickManageDocumentStore()} className="manage">Manage</div>)}
                </div>
                </div>
                }
        </div>
    )
}

export default QuotaChart

const toastNotify = (success: boolean, message: string) => {

    const toastOptions: ToastOptions = {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    }
  
    if (success) {
      toast.success(message, toastOptions);
    }
    else {
      toast.error(message, toastOptions);
    }
  }