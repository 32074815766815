import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { getAllCustomers, getAllDocumentStoresByCustomerId, getUserContext, getStorageMetricsByCustomerIdAndStoreId, getDocumentStoreById } from '../utilities/axiosUtility';
import {Customer} from '../interfaces/customer';
import { act } from 'react-dom/test-utils';
import { User } from '../interfaces/user';
import { DocumentStore, DocumentStoreFlags, DocumentStoreQuota, DocumentStores } from '../interfaces/documentStore';
import { getEnumFlags } from '../utilities/helpers';


export interface DocumentStoreState {
    documentStores: DocumentStore[]
    documentStoresQuota: DocumentStoreQuota[]
    selectedDocumentStore: DocumentStoreQuota|undefined
    editModeEnabled: boolean
}

export interface GetDocumentStores {type: 'GET_DOCUMENT_STORES', customerId: number};

export interface SetDocumentStores {type: 'SET_DOCUMENT_STORES', documentStores: DocumentStore[]}

export interface SetDocumentStoresQuota {type: 'SET_DOCUMENT_STORES_QUOTA', documentStoresQuota: DocumentStoreQuota[]}

export interface SetSelectedDocumentStore {type: 'SET_SELECTED_DOCUMENT_STORE', selectedDocumentStore: DocumentStoreQuota}

export interface SetEditModeEnabled {type: 'SET_EDIT_MODE_ENABLED', editModeEnabled: boolean}

export interface UpdateSelectedDocumentStore {type: 'UPDATE_SELECTED_STORE', selectedDocumentStore: DocumentStoreQuota}

export type KnownAction = GetDocumentStores|SetDocumentStores|SetDocumentStoresQuota|SetSelectedDocumentStore|SetEditModeEnabled|UpdateSelectedDocumentStore

const getDocumentStoreQuotas = async(storeList: DocumentStore[], customerId: number) => {
   return await Promise.all(storeList.map(async(store: DocumentStore, storeId: number)  => {
    const storageMetricResponse = await getStorageMetricsByCustomerIdAndStoreId(customerId, store.id);
    let storageMetric = undefined
    if(storageMetricResponse)
    {
        storageMetric = storageMetricResponse.data
    }
    const isSystemGenerated = getEnumFlags(store.flags, Object.keys(DocumentStoreFlags)).filter(f => f === 4).length > 0
    const newDocstoreQuota:DocumentStoreQuota = {
        id: store.id,
        externalId: store.externalId,
        name: store.name,
        description: store.description,
        typeId: store.typeId,
        settings: store.settings,
        isActive: store.isActive,
        createdBy: store.createdBy,
        createdDate: store.createdDate,
        modifiedBy: store.modifiedBy,
        modifiedDate: store.modifiedDate,
        customerId: customerId,
        metadataSettings: store.metadataSettings,
        maxStorageSize: storageMetric ? storageMetric.maxStorageSize : 0,
        remainingStorageSize: storageMetric ? storageMetric.remainingStorageSize : 0,
        usedStorageSize: storageMetric ? storageMetric.usedStorageSize : 0,
        systemGenerated: isSystemGenerated,
        flags: store.flags
    }
    return newDocstoreQuota;
    }))
}

export const actionCreators = {
    setDocumentStore: (DocumentStores: DocumentStore[]) => ({type: 'SET_DOCUMENT_STORES', documentStores: DocumentStores} as SetDocumentStores),
    setSelectedDocumentStore: (SelectedDocumentStore: DocumentStoreQuota) => ({type: 'SET_SELECTED_DOCUMENT_STORE', selectedDocumentStore: SelectedDocumentStore} as SetSelectedDocumentStore),
    updateSelectedDocumentStore: (SelectedDocumentStore: DocumentStoreQuota|undefined): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if(appState && appState.auth && SelectedDocumentStore)
        {
            try{
            const cDocStoreResponse = await getDocumentStoreById(appState.auth.SelectedCustomer? appState.auth.SelectedCustomer.id : 0, SelectedDocumentStore? SelectedDocumentStore.id : 0)
            const docStoreWithQuota = await getDocumentStoreQuotas([cDocStoreResponse.data], appState.auth.SelectedCustomer? appState.auth.SelectedCustomer.id : 0)
            dispatch({type: 'SET_SELECTED_DOCUMENT_STORE', selectedDocumentStore: docStoreWithQuota[0]})

            }
            catch
            {
                console.log("error updating selected document store")
            }

        }
    },
    setEditMode: (EditModeEnabled: boolean) => ({type: 'SET_EDIT_MODE_ENABLED', editModeEnabled: EditModeEnabled} as SetEditModeEnabled),
    getDocumentStore: (customerId: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if(appState && appState.auth)
        {
   
                const docStoreResponse = await getAllDocumentStoresByCustomerId(customerId)
                if(docStoreResponse.status === 200)
                {
                const docStoreWithQuota = await getDocumentStoreQuotas(docStoreResponse.data, customerId)
                dispatch({type: 'SET_DOCUMENT_STORES_QUOTA', documentStoresQuota: docStoreWithQuota})
                dispatch({type: 'SET_DOCUMENT_STORES', documentStores: docStoreResponse.data})
                }
        }
    }
}


const unloadedState: DocumentStoreState = {
    documentStores: [],
    documentStoresQuota: [],
    selectedDocumentStore: undefined,
    editModeEnabled: false,
}

export const reducer: Reducer<DocumentStoreState> = (state: DocumentStoreState | undefined, incomingAction: Action): DocumentStoreState => {
    if (state === undefined) {
        return unloadedState
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SET_DOCUMENT_STORES_QUOTA':
            return{ ...state,
                documentStoresQuota: action.documentStoresQuota};
        case 'SET_DOCUMENT_STORES':
            return { ...state,
                documentStores: action.documentStores};
        case 'UPDATE_SELECTED_STORE':
            return { ...state,
            };
        case 'SET_SELECTED_DOCUMENT_STORE':
            return { ...state,
                selectedDocumentStore: action.selectedDocumentStore};
        case 'SET_EDIT_MODE_ENABLED':
            return {...state,
                editModeEnabled: action.editModeEnabled};
        case 'GET_DOCUMENT_STORES':
            return {
                ...state,
                };
        default:
            return state;
    }
};