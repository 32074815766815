import * as React from 'react';
import { Modal, PrimaryButton, IButtonStyles, PeoplePickerItemBase, List, Icon, mergeStyleSets, ITheme, getTheme, getFocusStyle, IconButton, IIconProps, Stack, IStackTokens, TextField, Toggle, IChoiceGroupOption, ChoiceGroup, Dropdown, IDropdownOption, TooltipHost, DefaultButton, Spinner, SpinnerSize } from '@fluentui/react';
import { Customer } from '../../interfaces/customer'
import sharepointLogo from '../../Images/sharepoint.svg';
import fileShare from '../../Images/fileshare.svg';
import { Image, IImageProps } from '@fluentui/react/lib/Image';
import { addDocumentStore, executeStoreQuery, executeStoreTypeQuery, getStoreTypeByCustomerId } from '../../utilities/axiosUtility';
import { create, drop } from 'lodash';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { createUpdateDocumentStoreRequest } from '../../interfaces/documentStore';

export interface documentHistoryMetadataState {

}

const RedButtonStyle: IButtonStyles = {
    root: {
        backgroundColor: "#DC1A1A",
        borderColor: "#DC1A1A"
    },
    rootPressed: {
        backgroundColor: "#DC1A1A",
        borderColor: "#DC1A1A"
    },
    rootHovered: {
        backgroundColor: "#CB1818",
        borderColor: "#CB1818"
    }
}


const cancelIcon: IIconProps = { iconName: 'Cancel' };

const containerStackTokens: IStackTokens = { childrenGap: 10, padding: 20 };
const toggleStackTokens: IStackTokens = { childrenGap: 30, padding: 5 };
const modalInfoStackTokens: IStackTokens = { childrenGap: 30, padding: 5 };
const storeDetailsStackTokens: IStackTokens = { childrenGap: 20, padding: 5 };
const createCancelStackTokens: IStackTokens = { childrenGap: 20 };


interface IMetadataDetails {
    APIVaultId: string;
    ControlTerm: string;
    Filter: string;
    ModelId: string;
    ModelInfo: IModelInfo;
    file_name: string;
}

interface IModelInfo {
    ModelId: string;
    JoinFieldReference: string;
    ProcessTables: boolean
}

interface ILibraryUserInfo {
    SPUserName: string;
    SPPassword: string;
}

interface ILibrarySettings {
    SiteUrl: string;
}

interface IConnectLibraryDetails {
    Credentials: ILibraryUserInfo;
    Query: string;
    Settings: ILibrarySettings;
}

interface newDocumentStoreFields {
    name: string;
    description: string;
    siteUrl: string;
    username: string;
    password: string;
    sourceSelected: boolean;
    keepDoc: boolean;
    useDest: boolean;
    useSource: boolean;
}

interface storeType {
    description: string;
    flags: string[];
    id: number;
    name: string;
    typeId: string;
}

const defaultStoreFields: newDocumentStoreFields = {
    name: "",
    description: "",
    siteUrl: "",
    username: "",
    password: "",
    sourceSelected: false,
    keepDoc: false,
    useDest: false,
    useSource: false,
}

enum createStoreTypes {
    library = "SharePoint Document Library",
    site = "SharePoint Site",
    fileShare = "Azure File Share"
}

const baseMetadataDetails: IMetadataDetails = {
    APIVaultId: '',
    ControlTerm: '',
    Filter: '',
    ModelId: '',
    ModelInfo: { ModelId: '', JoinFieldReference: '', ProcessTables: false },
    file_name: ''
}

const storeTypes = ["00000000-0000-0000-0000-00000000000a  - SP Site", "00000000-0000-0000-0000-00000000000e - SP Site Preview", "00000000-0000-0000-0000-00000000000D - Azure File Share"];


const NewDocumentStoreModal = (props: { updateDocumentStoreList: any, isOpen: boolean, dismissModal: any, customerId: number | undefined }) => {

    const [isOpen, setIsOpen] = React.useState(false)
    const [isPhaseOne, setPhaseOne] = React.useState(true)
    const [createDisabled, setCreateDisabled] = React.useState(true);
    const [connectDisabled, setConnectDisabled] = React.useState(true);
    const [AccessOrAccount, setAccessOrAccount] = React.useState(true);
    const [selectedStoreType, setSelectedStoreType] = React.useState<storeType | undefined>(undefined);
    const [storeDetails, setStoreDetails] = React.useState<newDocumentStoreFields>(defaultStoreFields);
    const [selectedLibraryItem, setSelectedLibrary] = React.useState<IDropdownOption>();
    const [libraryOptions, setLibraryOptions] = React.useState<IDropdownOption[]>([]);
    const [storeTypes, setStoreTypes] = React.useState<storeType[]>([]);
    const [creatingStore, setCreatingStore] = React.useState(false);
    const [validCredentials, setValidCredentials] = React.useState(false);


    React.useEffect(() => {
        setIsOpen(props.isOpen);
        setLibraryOptions([]);
        setSelectedLibrary(undefined);
        setCreatingStore(false);
        getCustomerStoreTypes();
    }, [props.isOpen])


    function _onChange(ev: React.MouseEvent<HTMLElement>, checked?: boolean) {
        let _storeDetails = _.clone(storeDetails);
        switch (ev.currentTarget.id) {
            case "keepDoc": _storeDetails.keepDoc = !storeDetails.keepDoc;
                break;
            case "useDest": _storeDetails.useDest = !storeDetails.useDest;
                break;
            case "useSource": _storeDetails.useSource = !storeDetails.useSource;
                break;
        }

        if(_storeDetails.useSource === true && _storeDetails.useDest === true && ev.currentTarget.id === "useDest")
        {
          _storeDetails.keepDoc = false;
          _storeDetails.useSource = false;
        }
        if(_storeDetails.useSource === true && _storeDetails.useDest === true && ev.currentTarget.id === "useSource")
        {
          _storeDetails.useDest = false;
        }

        _checkVaildFields(_storeDetails, selectedLibraryItem)
        setStoreDetails(_storeDetails)
    }

    const getCustomerStoreTypes = async () => {
        try {
            const customerStoreTypes = await getStoreTypeByCustomerId(props.customerId ? props.customerId : 0);
            if(customerStoreTypes.status === 200){
            setStoreTypes(customerStoreTypes.data)
            }
        }
        catch (error) {
            //add toast error
        }
    }

    const connectToDocumentStore = async (storeType: storeType | undefined) => {
   
        if (storeType) {
            const isSite = storeType.typeId === "00000000-0000-0000-0000-00000000000e" ? true : false
            let _storeDetails = _.clone(storeDetails);
            let connectPostData: IConnectLibraryDetails = {
                Credentials: { SPUserName: _storeDetails.username, SPPassword: _storeDetails.password },
                Query: !isSite ? "sp.web.lists.documentLibraries" : "sp.web.get",
                Settings: { SiteUrl: _storeDetails.siteUrl }
            }

            const customerId = props.customerId ? props.customerId : 0;
            try {
                const queryResponse = await executeStoreTypeQuery(customerId, storeType.typeId, connectPostData)
                if(queryResponse.status === 500)
                {
                    setValidCredentials(false);
                    _checkVaildFields(_storeDetails, selectedLibraryItem, false);
                    toast.error('Error connecting to Site', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                else
                {
                    if(isSite){
                        setValidCredentials(true);
                        _checkVaildFields(_storeDetails, selectedLibraryItem, true);
                        toast.success('Successfully Connected To Site', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                    else
                    {
                    let libraryDropdownArray: IDropdownOption[] = []
                    for (let library of queryResponse.data) {
                        const dropdownItem: IDropdownOption = {
                            key: library.id,
                            text: library.title
                        }

                        libraryDropdownArray.push(dropdownItem);
                        
                    }
                    setLibraryOptions(libraryDropdownArray);
                    }
                }
            }
            catch (error) {
                toast.error('Error connecting to Site', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
    }

    const goBack = () => {
        setPhaseOne(true);
        setValidCredentials(false);
        setSelectedStoreType(undefined);
        setStoreDetails(defaultStoreFields);
        setSelectedLibrary(undefined);
        setConnectDisabled(true);
        setLibraryOptions([]);
        setCreateDisabled(true);
    }

    const dismissCurrentModal = () => {
        if(!creatingStore){
        props.dismissModal();
        setSelectedStoreType(undefined);
        setPhaseOne(true);
        setValidCredentials(false);
        setStoreDetails(defaultStoreFields);
        setSelectedLibrary(undefined);
        setConnectDisabled(true);
        setLibraryOptions([]);
        setCreateDisabled(true);
        }
    }

    function onChangeTextField(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, type: string, newValue?: string) {
        let _storeDetails = { ...storeDetails };
        if(type === "siteUrl")
        {
            if(_storeDetails.siteUrl !== newValue)
            {
                setValidCredentials(false);
                setCreateDisabled(true);
                setSelectedLibrary(undefined);
                setLibraryOptions([]);
                _storeDetails.siteUrl = newValue ? newValue : "";
            }
        }
        else
        {
        switch (type) {
            case "name": _storeDetails.name = newValue ? newValue : "";
                break;
            case "description": _storeDetails.description = newValue ? newValue : "";
                break;
            case "siteUrl": _storeDetails.siteUrl = newValue ? newValue : "";
                break;
            case "username": _storeDetails.username = newValue ? newValue : "";
                break;
            case "password": _storeDetails.password = newValue ? newValue : "";
                break;
        }
        _checkVaildFields(_storeDetails, selectedLibraryItem)
        }
        setStoreDetails(_storeDetails);
    }


    function _checkVaildFields(_storeDetails: newDocumentStoreFields, libraryItem?: IDropdownOption, _validCredentials?: boolean) {
        const isSite = selectedStoreType ? selectedStoreType.typeId === "00000000-0000-0000-0000-00000000000e" ? true : false : false;
        const cValidCredentials = _validCredentials === undefined ? validCredentials : _validCredentials;
        const lcSiteUrl = _storeDetails.siteUrl.toLowerCase()
        let isCreateDisabled = true;
        let isDisabled = true;
        if (_storeDetails.name.length > 0) {
            if (lcSiteUrl.includes("sharepoint.com")) {
                if (_storeDetails.username.length > 0 && _storeDetails.password.length > 0) {
                    if (!isSite && libraryItem || isSite && cValidCredentials) {
                        if(_storeDetails.useSource || _storeDetails.useDest)
                        {
                            isCreateDisabled = false;
                        }
                    }
                }
            }
        }

        if (lcSiteUrl.includes("sharepoint.com")) {
            if (_storeDetails.username.length > 0 && _storeDetails.password.length > 0) {
                    isDisabled = false;
            }
        }

     

        setConnectDisabled(isDisabled);
        setCreateDisabled(isCreateDisabled)
    }

    //Select type of document to create
    //Sharepoint Document Library
    //Sharepoint Site (Preview)
    //Azure File Share

    const renderSelectDocumentStoreType = () => {

        return (
            <Stack horizontal tokens={modalInfoStackTokens}>
                {storeTypes.map((store, storeArrayId) => {
                    const azure = store.name.includes('File') ? true : false
                    const storeImage = store.name.includes('Library') ? fileShare : sharepointLogo

                    if (azure === false) {
                        return (
                            <Stack.Item align='center' onClick={() => { setPhaseOne(false); setSelectedStoreType(store) }} style={{ textAlign: 'center', cursor: 'pointer', minWidth: 150 }}>
                                <TooltipHost content={store.description}>
                                    <div>
                                        <img alt="error" style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '3rem', paddingBottom: 15 }} src={sharepointLogo} />
                                        {store.name}
                                    </div>
                                </TooltipHost>
                            </Stack.Item>
                        )
                    }
                })}
            </Stack>
        )
    }

    const createDocumentStore = async () => {
        setCreatingStore(true)
        let flags = 0
        if (storeDetails.useSource) {
            flags += 1
        }
        if (storeDetails.useDest) {
            flags += 2
        }
        if (storeDetails.keepDoc) {
            flags += 4
        }

        const cSettings = {
            SiteUrl: storeDetails.siteUrl,
            ListId: selectedLibraryItem?.key
        }

        const cCredentials = {
            SPUserName: storeDetails.username,
            SPPassword: storeDetails.password
        }

        try {
            let createDocStoreRequest = {
                name: storeDetails.name,
                description: storeDetails.description,
                typeId: selectedStoreType?.typeId,
                settings: cSettings,
                metadataSettings: [],
                credentials: cCredentials,
                flags: flags
            }

            const createDocStore = await addDocumentStore(createDocStoreRequest, props.customerId ? props.customerId : 0)


            dismissCurrentModal();
            props.updateDocumentStoreList();
            toast.success('Document Store Successfully Added', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        catch (error) {
            toast.error('Error Creating Document Store', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            console.log(error);
        }

    }



    const fileShareOptions: IChoiceGroupOption[] = [
        { key: 'A', text: 'Access Key' },
        { key: 'B', text: 'Shared Access Signature' }
    ]


    const onChangeLibraryDropdown = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined,): void => {
        setValidCredentials(true)
        setSelectedLibrary(item);
        _checkVaildFields(storeDetails, item);
    };

    const renderDocumentStoreDetails = (storeType: storeType) => {
        const isLibrary = storeType.name.includes("Library");

        return (
            <Stack styles={{ root: { width: 400 } }} tokens={storeDetailsStackTokens}>
                <TextField onChange={(ev, value) => onChangeTextField(ev, "name", value)} label="Name *" value={storeDetails.name} />
                <TextField onChange={(ev, value) => onChangeTextField(ev, "description", value)} label="Description" value={storeDetails.description} />
                <TextField onChange={(ev, value) => onChangeTextField(ev, "siteUrl", value)} label="Site URL *" value={storeDetails.siteUrl} />
                <TextField onChange={(ev, value) => onChangeTextField(ev, "username", value)} label="Username *" value={storeDetails.username} />
                <TextField onChange={(ev, value) => onChangeTextField(ev, "password", value)} label="Password *" value={storeDetails.password} type="password" canRevealPassword />
                <PrimaryButton disabled={connectDisabled} onClick={() => connectToDocumentStore(storeType)} text="Connect" />
                {libraryOptions.length > 0 && (<Dropdown label="Selected Library" onChange={onChangeLibraryDropdown} selectedKey={selectedLibraryItem ? selectedLibraryItem.key : undefined} options={libraryOptions} />)}
                <Stack tokens={toggleStackTokens} horizontal>
                    {isLibrary && (<Toggle id="useSource" label="Use as source" onChange={_onChange} checked={storeDetails.useSource} />)}
                    <Toggle id="useDest" label="Use as destination" onChange={_onChange} checked={storeDetails.useDest} />
                    {storeDetails.useSource && (<Toggle id="keepDoc" label="Keep documents after successful move" onChange={_onChange} checked={storeDetails.keepDoc} />)}
                </Stack>
                <Stack tokens={createCancelStackTokens} horizontal>
                    {/*createDisabled*/}
                    <PrimaryButton disabled={createDisabled} onClick={() => createDocumentStore()} text="Create" />
                    <DefaultButton text="Back" onClick={() => goBack()} />
                </Stack>
            </Stack>
        )
    }

    const azurefileshare = () => {
        return (
            <Stack tokens={storeDetailsStackTokens}>
                <TextField label="Name *" />
                <TextField label="Description" />
                <ChoiceGroup defaultSelectedKey="A" options={fileShareOptions} />
                {AccessOrAccount ? <TextField label="Access Key" /> :
                    <TextField label="Account Name" />}
                <TextField label="Shared Access Signature" />
                <PrimaryButton text="Connect" onClick={() => connectToDocumentStore(selectedStoreType)} />
                <Toggle label="Use as source" />
                <Stack tokens={createCancelStackTokens} horizontal>
                    <PrimaryButton text="Create" />
                    <PrimaryButton text="Cancel" />
                </Stack>
            </Stack>
        )
    }

    return (
        <Modal
            isOpen={isOpen}
            onDismiss={dismissCurrentModal}
        >
        {creatingStore ? (
                    <Stack styles={{ root: { padding: 35 } }} tokens={{ childrenGap: 20 }}>
                        <Stack.Item styles={{ root: { fontSize: 23, fontWeight: 600 } }} align='center'>
                            Creating Store
                        </Stack.Item>
                        <Stack.Item>
                            <Spinner styles={{ circle: { width: 48, height: 48 } }} size={SpinnerSize.large} />
                        </Stack.Item>
                    </Stack>
                ) : ( <div >
                <IconButton
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={dismissCurrentModal}
                />
 
                    <div>
                        <h3 style={{ textAlign: 'center' }}>{selectedStoreType?.name}</h3>
                        {selectedStoreType ? (

                            <Stack tokens={containerStackTokens}>
                                {renderDocumentStoreDetails(selectedStoreType)}
                            </Stack>)
                            :
                            (<Stack tokens={containerStackTokens}>
                                {renderSelectDocumentStoreType()}
                            </Stack>
                            )}
                    </div>
                
            </div>)}
        </Modal>
    )
}

export default NewDocumentStoreModal