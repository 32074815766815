import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { getAllCustomers, getUserContext, getCurrentUserPermissions } from '../utilities/axiosUtility';
import {Customer} from '../interfaces/customer';
import { User } from '../interfaces/user';
import { UserPermissionLevels } from '../interfaces/customerAdministrator';


export interface authState  {
    isLoggedIn: boolean;
    Customers: Customer[];
    SelectedCustomer: Customer | null;
    SelectedCustomerId: number;
    CurrentUserLevels: UserPermissionLevels;
    User: User;
    DefaultCustomerId: string;
    Authtoken: string;
    TimedOutAmount: number;
}

export interface SetAuthToken {type: 'SET_AUTH_TOKEN', Authtoken: string};

export interface CheckLoggedIn {type: 'CHECK_LOGGED_IN', bearer: string;};

export interface SetLoggedIn {type: 'SET_LOGGED_IN', payload: boolean};

export interface SetTimedOutAmount {type: 'SET_TIMED_OUT_AMOUNT', amount: number}

export interface UpdateUser {type: 'UPDATE_USER', user: User};

export interface UpdateError {type: 'UPDATE_ERROR', payload: authState};

export interface UpdateUserLevels {type: 'UPDATE_USER_LEVELS', userLevels: UserPermissionLevels}

export interface SetCustomers {type: 'SET_CUSTOMERS', Customers: Customer[]}

export interface SetSelectedCustomer {type: 'SET_SELECTED_CUSTOMER', Customer: Customer}

export interface SetDefaultCustomerId {type: 'SET_DEFAULT_CUSTOMER_ID', DefaultCustomerId: string}



export type KnownAction = SetAuthToken | CheckLoggedIn | SetLoggedIn | SetTimedOutAmount | UpdateUser | UpdateError | SetCustomers | SetSelectedCustomer | UpdateUserLevels | SetDefaultCustomerId 

export const actionCreators = {
    setLoggedIn: (payload: boolean) => ({type: 'SET_LOGGED_IN', payload: payload} as SetLoggedIn),
    setTimedOutAmount: (amount: number) => ({type: 'SET_TIMED_OUT_AMOUNT', amount: amount} as SetTimedOutAmount),
    setCustomers: (customers: Customer[]) => ({type: 'SET_CUSTOMERS', Customers: customers} as SetCustomers),
    setSelectedCustomer: (customer: Customer) => ({type: 'SET_SELECTED_CUSTOMER', Customer: customer} as SetSelectedCustomer),
    SetDefaultCustomerId: (defaultCustomerId: string) => ({type: 'SET_DEFAULT_CUSTOMER_ID', DefaultCustomerId: defaultCustomerId} as SetDefaultCustomerId),
    updateUser: (user: User) => ({type: 'UPDATE_USER', user: user} as UpdateUser),
    updateUserLevels: (userLevels: UserPermissionLevels) => ({type: 'UPDATE_USER_LEVELS', userLevels: userLevels} as UpdateUserLevels),
    updateError: (payload: authState) => ({type: 'UPDATE_ERROR', payload: payload} as UpdateError),
    setAuthToken: (authToken: string) => ({type: 'SET_AUTH_TOKEN', Authtoken: authToken} as SetAuthToken),
    checkLoggedIn: (): AppThunkAction<KnownAction> =>(dispatch, getState) => {
        const appState = getState();
        let timedOutAmount = appState.auth? appState.auth?.TimedOutAmount : 0
        if(appState && appState.auth)
        {
            getUserContext().then((response: any) => {
            if(response.status === 200)
                {
                    dispatch({type: 'UPDATE_USER', user: response.data});
                    getAllCustomers().then((response : any) => {
                        console.log(response);
                        if(response.status === 200){
                        dispatch({type: 'SET_LOGGED_IN', payload : true});
                        for(let customer of response.data){
                                getCurrentUserPermissions(customer.id).then((response: any) => {
                                    if(response.data.isDefaultCustomer === true)
                                    {
                                        dispatch({type: 'SET_DEFAULT_CUSTOMER_ID', DefaultCustomerId: response.data.customerId.toString()});
                                    }
                                })
                            }
                            dispatch({type: 'SET_CUSTOMERS', Customers: response.data});
                        }
                        else if(response.status === 401)
                        {
                            dispatch({type: 'SET_TIMED_OUT_AMOUNT', amount: 4});
                        }
                        }).catch(error => {
                            console.log(error)
                        })
                    }
                }).catch(error => {
                    console.log(error)
                })
                dispatch({type: 'SET_TIMED_OUT_AMOUNT', amount: timedOutAmount += 1});
            }
    }
}

const unloadedState: authState = {
    isLoggedIn: false,
    Customers: [],
    SelectedCustomer: null,
    SelectedCustomerId: 0,
    CurrentUserLevels: 3,
    User: {
        id: "",
        loginName: "",
        name: "",
        emailAddress: "",
    },
    DefaultCustomerId: "0",
    Authtoken: "",
    TimedOutAmount: 0,
}

export const reducer: Reducer<authState> = (state: authState | undefined, incomingAction: Action): authState => {
    if (state === undefined) {
        return unloadedState
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SET_AUTH_TOKEN':
            return { ...state,
                    Authtoken: action.Authtoken
            };
        case 'SET_LOGGED_IN':
            return { ...state,
                    isLoggedIn: action.payload};
        case 'SET_TIMED_OUT_AMOUNT':
            return { ...state,
                    TimedOutAmount: action.amount
            };
        case 'CHECK_LOGGED_IN':
            return {
                ...state,
                };
        case 'SET_CUSTOMERS':
            return {
                ...state,
                Customers: action.Customers
            };
        case 'SET_SELECTED_CUSTOMER':
            return {
                ...state,
                SelectedCustomer: action.Customer
            };
        case 'SET_DEFAULT_CUSTOMER_ID':
            return {
                ...state,
                DefaultCustomerId: action.DefaultCustomerId
            }
        case 'UPDATE_USER':
            return {
                    ...state,
                    User: action.user
                };
        case 'UPDATE_USER_LEVELS':
            return {
                ...state,
                CurrentUserLevels: action.userLevels
            };
        case 'UPDATE_ERROR':
            return state;
        default:
            return state;
    }
};